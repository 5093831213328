import cn from "classnames";
import { observer } from "mobx-react-lite";
import { PropsWithChildren, ReactElement } from "react";

const DefaultPageBodyLayout = (
  props: PropsWithChildren
): React.ReactElement => {
  const { children } = props;

  const [header, body] = children as ReactElement[];

  if (!header || !body) return <div>Header or Body not found</div>;

  return (
    <div className={cn("flex flex-col h-full")}>
      <div className={cn("p-4 border-b", "border-gray-300")}>{header}</div>
      <div className={cn("flex-1 min-h-0")}>{body}</div>
    </div>
  );
};

export default observer(DefaultPageBodyLayout);
