import { Ticket } from "../../graphql/graphqlTypes";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { GetTicketsInAccountQuery as Query } from "./query.generatedTypes";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => Ticket[];
}

export const useResponseHandler = (): ReturnType => {
  const handleResponse = (args: ArgsType): Ticket[] => {
    const response = args.data.getTicketsInAccount;

    switch (response.__typename) {
      case "GetTicketsInAccountResult": {
        args.onSuccess?.();
        const tickets: Ticket[] = [];
        response.tickets?.forEach((item) => {
          if (item) tickets.push(item as Ticket);
        });

        return tickets;
      }
      case "CompanyNotFound":
      case "InvalidLimit":
      case "InvalidOffset":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
    return [];
  };

  return {
    handleResponse,
  };
};
