import { useState } from "react";

export const useUserState = () => {
  const [emailId, setEmailId] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);

  return {
    emailId,
    phoneNumber,
    name,
    setEmailId,
    setPhoneNumber,
    setName,
  };
};
