import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { useGetAccountUsers } from "../../apis/GetAccountUsers/useGetAccountUsers";
import AddUserModal from "../../components/AddUserModal/AddUserModal";
import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import Loader from "../../components/Loader/Loader";
import UsersTable from "../../components/UsersTable/UsersTable";
import { useUsersStore } from "../../contexts/UsersStoreContext";

const UsersController = (): React.ReactElement => {
  const getAccountUsersAPI = useGetAccountUsers();

  const usersStore = useUsersStore();

  const getAccountUsers = (): void => {
    getAccountUsersAPI.triggerAPI({});
  };

  useEffect(() => {
    getAccountUsers();
  }, []);

  const renderHeader = (): React.ReactElement => (
    <div className="flex items-center w-full">
      <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>Users</p>

      <AddUserModal />
    </div>
  );

  return (
    <DefaultPageBodyLayout>
      {renderHeader()}

      <div className="flex flex-col bg-gray-50 w-full h-full">
        {getAccountUsersAPI.isLoading ? (
          <Loader />
        ) : (
          <div className="flex-1 p-4">
            <UsersTable users={usersStore.users} />
          </div>
        )}
      </div>
    </DefaultPageBodyLayout>
  );
};

export default observer(UsersController);
