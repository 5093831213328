import { useState } from "react";

export const useCompanyState = () => {
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [managerName, setManagerName] = useState<string | null>(null);
  const [employeeEmail, setEmployeeEmail] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [companyLogo, setCompanyLogo] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);

  return {
    companyName,
    managerName,
    employeeEmail,
    phoneNumber,
    companyLogo,
    address,
    setCompanyName,
    setManagerName,
    setEmployeeEmail,
    setPhoneNumber,
    setCompanyLogo,
    setAddress,
  };
};
