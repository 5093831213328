import { ActionMenu, Icon, Item, Key, Text } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { IoExitOutline } from "react-icons/io5";

import UpdateUserModal from "../UpdateUserModal/UpdateUserModal";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";

import LogOutModal from "../LogOutModal/LogOutModal";

enum UserAction {
  UserSettings = "UserSettings",
  LogOut = "LogOut",
}

const AccountUserActionMenu = (): React.ReactElement => {
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false);

  const closeLogOutModal = () => setIsLogOutModalOpen(false);

  const { user } = useUserAppConfigStore();

  const onAction = (action: Key) => {
    switch (action as UserAction) {
      case UserAction.UserSettings:
        setIsUpdateUserModalOpen(true);
        break;
      case UserAction.LogOut: {
        setIsLogOutModalOpen(true);
        break;
      }
    }
  };

  return (
    <div>
      <ActionMenu onAction={onAction} isQuiet>
        <Item key={UserAction.UserSettings}>
          <Icon width={28} height={28}>
            <BsPersonCircle size={20} />
          </Icon>
          <Text alignSelf={"center"}>User Settings</Text>
        </Item>
        <Item key={UserAction.LogOut}>
          <Icon width={28} height={28}>
            <IoExitOutline size={20} />
          </Icon>
          <Text alignSelf={"center"}>Log Out</Text>
        </Item>
      </ActionMenu>

      <LogOutModal isOpen={isLogOutModalOpen} onClose={closeLogOutModal} />
      <UpdateUserModal
        activeUserId={user?.userId ?? ""}
        isOpen={isUpdateUserModalOpen}
        onClose={() => setIsUpdateUserModalOpen(false)}
      />
    </div>
  );
};

export default observer(AccountUserActionMenu);
