import { makeAutoObservable } from "mobx";

import { Collection } from "../models/Collection";
import { LaptopModel } from "../models/LaptopModel";
import { Laptop } from "../types/inventoryTypes";

export class LaptopsStore {
  private _laptopsCollection: Collection<string, LaptopModel>;

  private constructor() {
    this._laptopsCollection = new Collection<string, LaptopModel>(
      "the laptop doesn't exist in store"
    );

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get laptops() {
    return this._laptopsCollection.orderedItems;
  }

  createOrUpdateLaptop(laptop: Laptop) {
    const hasItem = this._laptopsCollection.hasItem(laptop.laptopId);
    if (hasItem) {
      const laptopModel = this._laptopsCollection.getItem(laptop.laptopId);
      laptopModel.update(laptop);
    } else {
      const laptopModel = LaptopModel.create(laptop);
      this._laptopsCollection.addItem(laptop.laptopId, laptopModel);
    }
  }

  getLaptop(laptopId: string) {
    return this._laptopsCollection.getItem(laptopId);
  }

  deleteLaptop(laptopId: string) {
    this._laptopsCollection.deleteItem(laptopId);
  }

  static create(): LaptopsStore {
    return new LaptopsStore();
  }
}
