import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateUserMutationVariables = Types.Exact<{
  params: Types.UpdateUserParams;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'EmailAlreadyExist' } | { __typename?: 'InvalidEmail', message?: string | null, emailId: string } | { __typename?: 'InvalidPhoneNumber', message?: string | null } | { __typename?: 'InvalidUrl', message?: string | null, url: string } | { __typename?: 'User', userId: string, name: string, emailId: string, profilePic?: string | null, phoneNumber?: string | null } | { __typename?: 'UserIsNotAdmin' } | { __typename?: 'UserNotInAccount', message?: string | null, userId: string } };


export const UpdateUserDocument = gql`
    mutation UpdateUser($params: UpdateUserParams!) {
  updateUser(params: $params) {
    ... on User {
      userId
      name
      emailId
      profilePic
      phoneNumber
    }
    ... on UserNotInAccount {
      message
      userId
    }
    ... on InvalidEmail {
      message
      emailId
    }
    ... on InvalidUrl {
      message
      url
    }
    ... on InvalidPhoneNumber {
      message
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;