import { ApolloError } from "@apollo/client";

export const convertApolloError = (
  apolloError: ApolloError | undefined
): Error | null => {
  if (!apolloError) return null;

  const errorMessage = apolloError?.message;
  const error = new Error(errorMessage);

  return error;
};

export const getErrorMessageFromErrorType = (errorType?: string): string =>
  errorType ? errorType.replace(/([A-Z])/g, " $1") : "Something went wrong";
