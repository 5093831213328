import { Item, TabList, TabPanels, Tabs } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { TicketType } from "../../graphql/graphqlTypes";

import TicketsTable from "../TicketsTable/TicketsTable";
import PickUpTicketsTable from "../PickUpTicketsTable/PickUpTicketsTable";
import ReplacementTicketsTable from "../ReplacementTicketsTable/ReplacementTicketsTable";

const ticketTypeOptions = [
  {
    key: TicketType.Service,
    label: "Service",
  },
  {
    key: TicketType.Replacement,
    label: "Replacement",
  },
  {
    key: TicketType.PickUp,
    label: "Pick Up",
  },
];

const TicketTabs = (): React.ReactElement => {
  const [refreshId, setRefreshId] = useState("");

  const updateRefreshId = (): void => setRefreshId(Date.now().toString());

  const renderServiceTickets = (ticketType: TicketType): React.ReactElement => {
    switch (ticketType) {
      case TicketType.Service:
        return (
          <TicketsTable key={refreshId} updateRefreshId={updateRefreshId} />
        );
      case TicketType.PickUp:
        return (
          <PickUpTicketsTable
            key={refreshId}
            updateRefreshId={updateRefreshId}
          />
        );

      case TicketType.Replacement:
        return (
          <ReplacementTicketsTable
            key={refreshId}
            updateRefreshId={updateRefreshId}
          />
        );
    }
  };

  return (
    <Tabs aria-label="History of Ancient Rome">
      <TabList>
        {ticketTypeOptions.map((item) => (
          <Item key={item.key}>{item.label}</Item>
        ))}
      </TabList>

      <TabPanels>
        {ticketTypeOptions.map((item) => (
          <Item key={item.key}>{renderServiceTickets(item.key)}</Item>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default observer(TicketTabs);
