import { defaultTheme, Provider } from "@adobe/react-spectrum";
import { ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import StoresProvider from "./components/StoresProvider/StoresProvider";
import ToastArea from "./components/ToastArea/ToastArea";
import { client } from "./graphql/client";
import RouteProvider from "./routes/RouteProvider/RouteProvider";

const queryClient = new QueryClient();

const App = (): React.ReactElement => {
  const portalRoot = document.getElementById("toast-container");

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Provider theme={defaultTheme} colorScheme="light">
              <div className="bg-white w-screen h-screen">
                <StoresProvider>
                  <RouteProvider />
                </StoresProvider>
              </div>
            </Provider>
          </BrowserRouter>
        </ApolloProvider>
      </QueryClientProvider>

      {portalRoot ? ReactDOM.createPortal(<ToastArea />, portalRoot) : null}
    </>
  );
};

export default App;
