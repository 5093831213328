import {
  action,
  computed,
  observable,
  ObservableMap,
  makeObservable,
} from "mobx";

export class EntryDoesNotExistError extends Error {
  constructor(message: string) {
    super(message);
  }
}

interface ArrayItemType<IKey, IModel> {
  itemId: IKey;
  entity: IModel;
}

class Collection<IKey, IModel> {
  private _itemsMap!: ObservableMap<IKey, IModel>;
  private itemsArray!: ArrayItemType<IKey, IModel>[];

  readonly notFoundErrorMsg: string;

  constructor(errorMsg: string) {
    this.notFoundErrorMsg = errorMsg;

    this.init();
    makeObservable<
      Collection<IKey, IModel>,
      | "_itemsMap"
      | "itemsArray"
      | "init"
      | "addToItemsMap"
      | "addToItemsArray"
      | "deleteItemFromMap"
      | "deleteItemFromArray"
    >(this, {
      _itemsMap: observable,
      itemsArray: observable,
      init: action.bound,
      itemsMap: computed,
      orderedItems: computed,
      items: computed,
      totalItems: computed,
      addToItemsMap: action.bound,
      addToItemsArray: action.bound,
      addItem: action.bound,
      addItemBefore: action.bound,
      addItemAfter: action.bound,
      deleteItemFromMap: action.bound,
      deleteItemFromArray: action.bound,
      deleteItem: action.bound,
      clearData: action.bound,
    });
  }

  private init() {
    this._itemsMap = new ObservableMap();
    this.itemsArray = [];
  }

  get itemsMap(): ObservableMap<IKey, IModel> {
    return this._itemsMap;
  }

  get orderedItems(): IModel[] {
    return this.itemsArray.map((item) => item.entity);
  }

  get items(): IModel[] {
    return Array.from(this._itemsMap.values());
  }

  get totalItems(): number {
    return this._itemsMap.size;
  }

  hasItem = (itemId: IKey): boolean => this._itemsMap.has(itemId);

  getItem = (itemId: IKey): IModel => {
    const item = this._itemsMap.get(itemId);

    if (!item)
      throw new EntryDoesNotExistError(`${itemId} : ${this.notFoundErrorMsg}`);
    return item;
  };

  private addToItemsMap(key: IKey, item: IModel): void {
    this._itemsMap.set(key, item);
  }

  private addToItemsArray(itemId: IKey, item: IModel, position: number): void {
    if (this.hasItem(itemId)) {
      this.deleteItemFromArray(itemId);
    }
    this.itemsArray.splice(position, 0, {
      itemId,
      entity: item,
    });
  }

  addItem(key: IKey, item: IModel, position = this.totalItems): void {
    this.addToItemsArray(key, item, position);
    this.addToItemsMap(key, item);
  }

  addItemBefore(key: IKey, item: IModel, position: number) {
    this.addToItemsArray(key, item, position > 0 ? position - 1 : 0);
    this.addToItemsMap(key, item);
  }

  addItemAfter(key: IKey, item: IModel, position: number) {
    this.addToItemsArray(key, item, position + 1);
    this.addToItemsMap(key, item);
  }

  getItemPosition(key: IKey): number | null {
    const index = this.itemsArray.findIndex((item) => item.itemId === key);
    return index === -1 ? null : index;
  }

  private deleteItemFromMap(itemId: IKey): void {
    this._itemsMap.delete(itemId);
  }

  private deleteItemFromArray(itemId: IKey): void {
    this.itemsArray = this.itemsArray.filter((item) => item.itemId !== itemId);
  }

  deleteItem(itemId: IKey): void {
    this.deleteItemFromMap(itemId);
    this.deleteItemFromArray(itemId);
  }

  clearData() {
    this.init();
  }
}

export { Collection };
