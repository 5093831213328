import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";

import { DASHBOARD_ROUTE } from "../../constants/navigationConstants";

const HomeRoute = (): React.ReactElement => {
  return <Navigate to={DASHBOARD_ROUTE} replace />;
};

export default observer(HomeRoute);
