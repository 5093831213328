import { CalendarDate } from "@internationalized/date";
import { makeAutoObservable } from "mobx";

import { LaptopStatus } from "../graphql/graphqlTypes";
import { Laptop } from "../types/inventoryTypes";

export class LaptopModel {
  readonly laptopId: string;

  private _modelNumber!: string;
  private _serialNumber!: string;
  private _vendor!: string;
  private _status!: LaptopStatus;
  private _procuredDate!: CalendarDate | null | undefined;
  private _configuration: string | null | undefined;

  private _companyName: string | null | undefined;
  private _companyId: string | null | undefined;
  private _deliveryDate!: CalendarDate | null | undefined;
  private _returnDate!: CalendarDate | null | undefined;
  private _replacementDate!: CalendarDate | null | undefined;
  private _employeeId: string | null | undefined;
  private _employeeName: string | null | undefined;

  private constructor(data: Laptop) {
    this.laptopId = data.laptopId;

    this.update(data);

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get modelNumber() {
    return this._modelNumber;
  }

  get serialNumber() {
    return this._serialNumber;
  }

  get vendor() {
    return this._vendor;
  }

  get status() {
    return this._status;
  }

  get procuredDate() {
    return this._procuredDate;
  }

  get configuration() {
    return this._configuration;
  }

  get companyId() {
    return this._companyId;
  }

  get companyName() {
    return this._companyName;
  }

  get deliveryDate() {
    return this._deliveryDate;
  }

  get returnDate() {
    return this._returnDate;
  }

  get replacementDate() {
    return this._replacementDate;
  }

  get employeeId() {
    return this._employeeId;
  }

  get employeeName() {
    return this._employeeName;
  }

  setModelNumber(modelNumber: string) {
    this._modelNumber = modelNumber;
  }

  setSerialNumber(serialNumber: string) {
    this._serialNumber = serialNumber;
  }

  setVendor(vendor: string) {
    this._vendor = vendor;
  }

  setStatus(status: LaptopStatus) {
    this._status = status;
  }

  setProcuredDate(procuredDate: CalendarDate | null | undefined) {
    this._procuredDate = procuredDate;
  }

  setDeliveryDate(deliveryDate: CalendarDate | null | undefined) {
    this._deliveryDate = deliveryDate;
  }

  setReturnDate(returnDate: CalendarDate | null | undefined) {
    this._returnDate = returnDate;
  }

  setReplacementDate(replacementDate: CalendarDate | null | undefined) {
    this._replacementDate = replacementDate;
  }

  setConfiguration(configuration: string | null | undefined) {
    this._configuration = configuration;
  }

  setCompanyId(companyId: string | null | undefined) {
    this._companyId = companyId;
  }

  setCompanyName(companyName: string | null | undefined) {
    this._companyName = companyName;
  }

  setEmployeeId(employeeId: string | null | undefined) {
    this._employeeId = employeeId;
  }

  setEmployeeName(employeeName: string | null | undefined) {
    this._employeeName = employeeName;
  }

  update(data: Laptop) {
    this._modelNumber = data.modelNumber;
    this._serialNumber = data.serialNumber;
    this._configuration = data.configuration;
    this._vendor = data.vendor;
    this._status = data.status;
    this._procuredDate = data.procuredDate;

    this._companyId = data.companyId;
    this._companyName = data.companyName;
    this._deliveryDate = data.deliveryDate;
    this._returnDate = data.returnDate;
    this._replacementDate = data.replacementDate;

    this._employeeId = data.employeeId;
    this._employeeName = data.employeeName;
  }

  static create(data: Laptop): LaptopModel {
    return new LaptopModel(data);
  }
}
