import axios, { AxiosRequestConfig } from "axios";

import { AUTH_BASE_URL } from "../constants/apiConstants";

const axiosConfig: AxiosRequestConfig = {
  baseURL: AUTH_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 10000,
};

export const axiosClient = axios.create(axiosConfig);
