interface Props {
  className?: string;
}

export const CompanyLogo = (props: Props) => (
  <img
    src="./app-logo.jpg"
    alt="saffron technologies"
    className={props.className}
  />
);
