import { observer } from "mobx-react-lite";
import { Bounce, ToastContainer } from "react-toastify";

const ToastArea = (): React.ReactElement => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={2000}
      hideProgressBar
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="light"
      transition={Bounce}
    />
  );
};

export default observer(ToastArea);
