import { observer } from "mobx-react-lite";
import { PropsWithChildren, useEffect } from "react";
import { useGetUserAppConfig } from "../../apis/GetUserAppConfig/useGetUserAppConfig";

import Loader from "../../components/Loader/Loader";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";

const UserAppConfigController = (
  props: PropsWithChildren
): React.ReactElement => {
  const getUserAppConfigAPI = useGetUserAppConfig();

  const { user } = useUserAppConfigStore();

  const getUserAppConfig = (): void => {
    getUserAppConfigAPI.triggerAPI({});
  };

  useEffect(() => {
    getUserAppConfig();
  }, []);

  return user ? <>{props.children}</> : <Loader />;
};

export default observer(UserAppConfigController);
