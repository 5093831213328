import { observer } from "mobx-react-lite";
import { createContext, PropsWithChildren, useContext, useRef } from "react";

import { LaptopsStore } from "../stores/LaptopsStore";

export const LaptopsStoreContext = createContext<LaptopsStore | null>(null);

const LaptopsStoreProvider = (props: PropsWithChildren) => {
  const laptopsStoreRef = useRef(LaptopsStore.create());

  return (
    <LaptopsStoreContext.Provider value={laptopsStoreRef.current}>
      {props.children}
    </LaptopsStoreContext.Provider>
  );
};

export const useLaptopsStore = (): LaptopsStore => {
  const laptopsStore = useContext(LaptopsStoreContext);

  if (!laptopsStore) {
    throw new Error("Expected to be wrapped in Laptops Store Provider");
  }

  return laptopsStore;
};

export default observer(LaptopsStoreProvider);
