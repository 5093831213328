import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetTicketsInAccountQueryVariables = Types.Exact<{
  params: Types.GetTicketsInAccountParams;
}>;


export type GetTicketsInAccountQuery = { __typename?: 'Query', getTicketsInAccount: { __typename?: 'CompanyNotFound', message?: string | null, companyId: string } | { __typename?: 'GetTicketsInAccountResult', totalCount: number, tickets?: Array<{ __typename?: 'Ticket', ticketId: string, ticketType: Types.TicketType, status: Types.TicketStatus, companyName: string, description?: string | null, config?: Array<string | null> | null, creationDatetime: any, technicalPerson?: string | null, replacementDate?: any | null, oldLaptopReturnDate?: any | null, laptop: { __typename?: 'Laptop', laptopId: string, modelNumber: string, serialNumber: string, configuration?: string | null, vendor: string, procuredDate: any, status: Types.LaptopStatus, rentedDetails?: { __typename?: 'LaptopRentedDetails', companyId: string, companyName: string, deliveryDate: any, returnDate?: any | null, replacementDate?: any | null, employeeId: string, employeeName: string } | null }, newLaptop?: { __typename?: 'Laptop', laptopId: string, modelNumber: string, serialNumber: string, configuration?: string | null, vendor: string } | null } | null> | null } | { __typename?: 'InvalidLimit', message?: string | null } | { __typename?: 'InvalidOffset', message?: string | null } };


export const GetTicketsInAccountDocument = gql`
    query GetTicketsInAccount($params: GetTicketsInAccountParams!) {
  getTicketsInAccount(params: $params) {
    ... on GetTicketsInAccountResult {
      totalCount
      tickets {
        ticketId
        ticketType
        status
        companyName
        description
        config
        creationDatetime
        laptop {
          laptopId
          modelNumber
          serialNumber
          configuration
          vendor
          procuredDate
          status
          rentedDetails {
            companyId
            companyName
            deliveryDate
            returnDate
            replacementDate
            employeeId
            employeeName
          }
        }
        newLaptop {
          laptopId
          modelNumber
          serialNumber
          configuration
          vendor
        }
        technicalPerson
        replacementDate
        oldLaptopReturnDate
      }
    }
    ... on CompanyNotFound {
      message
      companyId
    }
    ... on InvalidLimit {
      message
    }
    ... on InvalidOffset {
      message
    }
  }
}
    `;
export type GetTicketsInAccountQueryResult = Apollo.QueryResult<GetTicketsInAccountQuery, GetTicketsInAccountQueryVariables>;