export const formatToIndianNumber = (number: number): string => {
  if (number < 1000) {
    return number.toString();
  } else if (number < 100000) {
    const formatted = number / 1000;
    return formatted % 1 === 0
      ? `${formatted}K`
      : `${formatted.toFixed(2).replace(/\.?0*$/, "")}K`;
  } else if (number < 10000000) {
    const formatted = number / 100000;
    return formatted % 1 === 0
      ? `${formatted}L`
      : `${formatted.toFixed(2).replace(/\.?0*$/, "")}L`;
  } else if (number < 1000000000) {
    const formatted = number / 10000000;
    return formatted % 1 === 0
      ? `${formatted}Cr`
      : `${formatted.toFixed(2).replace(/\.?0*$/, "")}Cr`;
  } else if (number < 100000000000) {
    const formatted = number / 1000000000;
    return formatted % 1 === 0
      ? `${formatted}Ar`
      : `${formatted.toFixed(2).replace(/\.?0*$/, "")}Ar`;
  } else {
    const formatted = number / 100000000000;
    return formatted % 1 === 0
      ? `${formatted}Kharab`
      : `${formatted.toFixed(2).replace(/\.?0*$/, "")}Kharab`;
  }
};
