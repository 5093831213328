import { observer } from "mobx-react-lite";

import { useRaiseTicket } from "../../apis/RaiseTicket/useRaiseTicket";
import RaiseTicket from "../../components/RaiseTicket/RaiseTicket";
import { TicketType } from "../../graphql/graphqlTypes";
import { showSuccessToast } from "../../utils/toastUtils";

import { useRaiseTicketState } from "./useRaiseTicketState";

interface Props {
  companyId: string;
  laptopId: string;
  onClose: () => void;
}

const RaiseTicketController = (props: Props): React.ReactElement => {
  const { companyId, laptopId, onClose } = props;

  const state = useRaiseTicketState();

  const raiseTicketAPI = useRaiseTicket();

  const onRaiseTicket = (): void => {
    const { ticketType, description, ticketConfig } = state;

    const isValid =
      ticketType &&
      (ticketType === TicketType.Service
        ? !!description || ticketConfig.length > 0
        : true);

    if (!isValid) return;

    raiseTicketAPI.triggerAPI({
      reqObj: {
        companyId,
        laptopId,
        ticketType,
        description,
        ticketConfig,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("Ticket Raised");
      },
      onFailure: () => {},
    });
  };

  return (
    <RaiseTicket
      ticketType={state.ticketType}
      description={state.description}
      ticketConfig={state.ticketConfig}
      setTicketType={state.setTicketType}
      setDescription={state.setDescription}
      setTicketConfig={state.setTicketConfig}
      onClose={onClose}
      onRaiseTicket={onRaiseTicket}
      isPending={raiseTicketAPI.isLoading}
    />
  );
};

export default observer(RaiseTicketController);
