import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCompaniesInAccountQueryVariables = Types.Exact<{
  params: Types.GetCompaniesInAccountParams;
}>;


export type GetCompaniesInAccountQuery = { __typename?: 'Query', getCompaniesInAccount: { __typename?: 'GetCompaniesInAccountResult', totalCount: number, companies?: Array<{ __typename?: 'Company', companyId: string, name: string, logoUrl?: string | null, rentedLaptopsCount: number, address?: string | null, manager: { __typename?: 'BaseUser', userId: string, name: string, emailId: string, profilePic?: string | null } } | null> | null } | { __typename?: 'InvalidLimit', message?: string | null } | { __typename?: 'InvalidOffset', message?: string | null } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const GetCompaniesInAccountDocument = gql`
    query GetCompaniesInAccount($params: GetCompaniesInAccountParams!) {
  getCompaniesInAccount(params: $params) {
    ... on GetCompaniesInAccountResult {
      totalCount
      companies {
        companyId
        name
        logoUrl
        rentedLaptopsCount
        address
        manager {
          userId
          name
          emailId
          profilePic
        }
      }
    }
    ... on InvalidLimit {
      message
    }
    ... on InvalidOffset {
      message
    }
    ... on UserIsNotAdmin {
      message
    }
  }
}
    `;
export type GetCompaniesInAccountQueryResult = Apollo.QueryResult<GetCompaniesInAccountQuery, GetCompaniesInAccountQueryVariables>;