import {
  Button,
  ButtonGroup,
  Content,
  DatePicker,
  Divider,
  Heading,
  TextArea,
  TextField,
} from "@adobe/react-spectrum";
import { CalendarDate } from "@internationalized/date";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  serialNumber: string | null;
  modelNumber: string | null;
  vendor: string | null;
  configuration: string | null;
  procuredDate: CalendarDate | null | undefined;

  setSerialNumber: (serialNumber: string | null) => void;
  setModelNumber: (modelNumber: string | null) => void;
  setVendor: (vendor: string | null) => void;
  setConfiguration: (configuration: string | null) => void;
  setProcuredDate: (procuredDate: CalendarDate | null) => void;

  onClose: () => void;
  onAddLaptop: () => void;
}

const AddLaptop = (props: Props): React.ReactElement => {
  const {
    serialNumber,
    modelNumber,
    vendor,
    configuration,
    procuredDate,
    setSerialNumber,
    setModelNumber,
    setVendor,
    setProcuredDate,
    onClose,
    setConfiguration,
    onAddLaptop,
  } = props;

  const isValid = modelNumber && serialNumber && vendor && configuration;

  const renderSerialNumber = () => (
    <TextField
      label="Serial Number"
      value={serialNumber ?? ""}
      onChange={setSerialNumber}
      width={"100%"}
      isRequired
    />
  );

  const renderModelNumber = () => (
    <TextField
      label="Model Number"
      value={modelNumber ?? ""}
      onChange={setModelNumber}
      width={"100%"}
      isRequired
    />
  );

  const renderVendor = () => (
    <TextField
      label="Vendor"
      value={vendor ?? ""}
      onChange={setVendor}
      width={"100%"}
      isRequired
    />
  );

  const renderConfiguration = () => (
    <TextArea
      label="Configuration"
      value={configuration ?? ""}
      onChange={setConfiguration}
      width={"100%"}
      isRequired
    />
  );

  const renderProcuredDate = (): React.ReactElement => (
    <DatePicker
      label="Procured Date"
      value={procuredDate}
      onChange={setProcuredDate}
      UNSAFE_className="flex-1 min-w-0"
    />
  );

  return (
    <>
      <Heading>Add Laptop</Heading>
      <Divider />

      <Content UNSAFE_className="scrollbar">
        <div className={cn("flex flex-col gap-2 w-full")}>
          {renderSerialNumber()}
          {renderModelNumber()}
          {renderVendor()}
          {renderConfiguration()}
          {renderProcuredDate()}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button variant="accent" onPress={onAddLaptop} isDisabled={!isValid}>
          {"Add"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(AddLaptop);
