export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  GQLDateScalar: { input: any; output: any; }
  GQLDateTimeScalar: { input: any; output: any; }
};

export type AddCompanyParams = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  employeeEmail: Scalars['String']['input'];
  managerName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AddCompanyResponse = Company | CompanyNameAlreadyExist | EmailAlreadyExist | InvalidEmail | InvalidPhoneNumber | InvalidUrl | UserIsNotAdmin;

export type AdminCannotRaiseTicket = GraphqlBaseErrorInterface & {
  __typename?: 'AdminCannotRaiseTicket';
  message?: Maybe<Scalars['String']['output']>;
};

export type BaseUser = {
  __typename?: 'BaseUser';
  emailId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profilePic?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  manager: BaseUser;
  name: Scalars['String']['output'];
  rentedLaptopsCount: Scalars['Int']['output'];
};

export type CompanyIsRequiredForNonAdmin = GraphqlBaseErrorInterface & {
  __typename?: 'CompanyIsRequiredForNonAdmin';
  message?: Maybe<Scalars['String']['output']>;
};

export type CompanyNameAlreadyExist = GraphqlBaseErrorInterface & {
  __typename?: 'CompanyNameAlreadyExist';
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CompanyNotFound = GraphqlBaseErrorInterface & {
  __typename?: 'CompanyNotFound';
  companyId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateAdminUserParams = {
  emailId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdminUserResponse = EmailAlreadyExist | InvalidEmail | InvalidPhoneNumber | MaxAdminUsersLimitExceeded | User | UserIsNotAdmin;

export type CreateLaptopParams = {
  configuration: Scalars['String']['input'];
  modelNumber: Scalars['String']['input'];
  procuredDate?: InputMaybe<Scalars['GQLDateScalar']['input']>;
  rentedDetails?: InputMaybe<CreateLaptopRentedDetails>;
  serialNumber: Scalars['String']['input'];
  vendor: Scalars['String']['input'];
};

export type CreateLaptopRentedDetails = {
  companyId: Scalars['String']['input'];
  deliveryDate: Scalars['GQLDateScalar']['input'];
  employeeId: Scalars['String']['input'];
  employeeName: Scalars['String']['input'];
};

export type CreateLaptopResponse = CompanyNotFound | DeliveryDateCannotBeInPast | DeliveryDateMustBeGreaterThanProcuredDate | Laptop | SerialNumberAlreadyExist | UserIsNotAdmin;

export type DeactivateUserParams = {
  userId: Scalars['String']['input'];
};

export type DeactivateUserResponse = DeactivateUserResult | UserIsNotAdmin | UserNotInAccount;

export type DeactivateUserResult = {
  __typename?: 'DeactivateUserResult';
  userId: Scalars['String']['output'];
};

export type DeliveryDateCannotBeInPast = GraphqlBaseErrorInterface & {
  __typename?: 'DeliveryDateCannotBeInPast';
  message?: Maybe<Scalars['String']['output']>;
};

export type DeliveryDateMustBeGreaterThanProcuredDate = GraphqlBaseErrorInterface & {
  __typename?: 'DeliveryDateMustBeGreaterThanProcuredDate';
  message?: Maybe<Scalars['String']['output']>;
};

export type EmailAlreadyExist = GraphqlBaseErrorInterface & {
  __typename?: 'EmailAlreadyExist';
  emailId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type GetAccountLaptopsParams = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LaptopStatus>;
};

export type GetAccountLaptopsResponse = CompanyIsRequiredForNonAdmin | CompanyNotFound | GetAccountLaptopsResult | InvalidLimit | InvalidOffset;

export type GetAccountLaptopsResult = {
  __typename?: 'GetAccountLaptopsResult';
  laptops?: Maybe<Array<Maybe<Laptop>>>;
  totalCount: Scalars['Int']['output'];
};

export type GetAccountUsersResponse = GetAccountUsersResult | UserIsNotAdmin;

export type GetAccountUsersResult = {
  __typename?: 'GetAccountUsersResult';
  users?: Maybe<Array<Maybe<User>>>;
};

export type GetCompaniesInAccountParams = {
  pagination: Pagination;
};

export type GetCompaniesInAccountResponse = GetCompaniesInAccountResult | InvalidLimit | InvalidOffset | UserIsNotAdmin;

export type GetCompaniesInAccountResult = {
  __typename?: 'GetCompaniesInAccountResult';
  companies?: Maybe<Array<Maybe<Company>>>;
  totalCount: Scalars['Int']['output'];
};

export type GetCompanyDashboardDataResponse = GetCompanyDashboardDataResult | NotCompanyManager;

export type GetCompanyDashboardDataResult = {
  __typename?: 'GetCompanyDashboardDataResult';
  ticketsPending: Scalars['Int']['output'];
  ticketsRaised: Scalars['Int']['output'];
  totalRentedLaptops: Scalars['Int']['output'];
};

export type GetCompanyParams = {
  companyId: Scalars['String']['input'];
};

export type GetCompanyResponse = CompanyNotFound | GetCompanyResult;

export type GetCompanyResult = {
  __typename?: 'GetCompanyResult';
  company: Company;
};

export type GetGraphResponse = GetGraphResult | UserIsNotAdmin;

export type GetGraphResult = {
  __typename?: 'GetGraphResult';
  availableItems?: Maybe<Array<Maybe<GraphDataPoint>>>;
  rentedItems?: Maybe<Array<Maybe<GraphDataPoint>>>;
  totalItems?: Maybe<Array<Maybe<GraphDataPoint>>>;
};

export type GetLaptopStatsResponse = LaptopStatsDetails | UserIsNotAdmin;

export type GetTicketsInAccountParams = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
  ticketTypes?: InputMaybe<Array<InputMaybe<TicketType>>>;
};

export type GetTicketsInAccountResponse = CompanyNotFound | GetTicketsInAccountResult | InvalidLimit | InvalidOffset;

export type GetTicketsInAccountResult = {
  __typename?: 'GetTicketsInAccountResult';
  tickets?: Maybe<Array<Maybe<Ticket>>>;
  totalCount: Scalars['Int']['output'];
};

export type GraphDataPoint = {
  __typename?: 'GraphDataPoint';
  count: Scalars['String']['output'];
  month: Scalars['String']['output'];
};

export type GraphqlBaseErrorInterface = {
  message?: Maybe<Scalars['String']['output']>;
};

export type InvalidEmail = GraphqlBaseErrorInterface & {
  __typename?: 'InvalidEmail';
  emailId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type InvalidLimit = GraphqlBaseErrorInterface & {
  __typename?: 'InvalidLimit';
  message?: Maybe<Scalars['String']['output']>;
};

export type InvalidOffset = GraphqlBaseErrorInterface & {
  __typename?: 'InvalidOffset';
  message?: Maybe<Scalars['String']['output']>;
};

export type InvalidPhoneNumber = GraphqlBaseErrorInterface & {
  __typename?: 'InvalidPhoneNumber';
  message?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
};

export type InvalidUrl = GraphqlBaseErrorInterface & {
  __typename?: 'InvalidUrl';
  message?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type Laptop = {
  __typename?: 'Laptop';
  configuration?: Maybe<Scalars['String']['output']>;
  laptopId: Scalars['String']['output'];
  modelNumber: Scalars['String']['output'];
  procuredDate: Scalars['GQLDateScalar']['output'];
  rentedDetails?: Maybe<LaptopRentedDetails>;
  serialNumber: Scalars['String']['output'];
  status: LaptopStatus;
  vendor: Scalars['String']['output'];
};

export type LaptopIsAlreadyLinkedToCompany = GraphqlBaseErrorInterface & {
  __typename?: 'LaptopIsAlreadyLinkedToCompany';
  laptopId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type LaptopIsDamaged = GraphqlBaseErrorInterface & {
  __typename?: 'LaptopIsDamaged';
  laptopId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type LaptopIsRented = GraphqlBaseErrorInterface & {
  __typename?: 'LaptopIsRented';
  laptopId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type LaptopNotFound = GraphqlBaseErrorInterface & {
  __typename?: 'LaptopNotFound';
  laptopId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type LaptopNotLinkedToCompany = GraphqlBaseErrorInterface & {
  __typename?: 'LaptopNotLinkedToCompany';
  companyId: Scalars['String']['output'];
  laptopId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type LaptopRentedDetails = {
  __typename?: 'LaptopRentedDetails';
  companyId: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  deliveryDate: Scalars['GQLDateScalar']['output'];
  employeeId: Scalars['String']['output'];
  employeeName: Scalars['String']['output'];
  replacementDate?: Maybe<Scalars['GQLDateScalar']['output']>;
  returnDate?: Maybe<Scalars['GQLDateScalar']['output']>;
};

export type LaptopStats = {
  __typename?: 'LaptopStats';
  changesFromLastMonth: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
};

export type LaptopStatsDetails = {
  __typename?: 'LaptopStatsDetails';
  availableItems: LaptopStats;
  companiesCount: LaptopStats;
  rentedItems: LaptopStats;
  totalItems: LaptopStats;
};

/** An enumeration. */
export enum LaptopStatus {
  Available = 'AVAILABLE',
  Damaged = 'DAMAGED',
  Rented = 'RENTED'
}

export type MakeLaptopAvailableParams = {
  laptopId: Scalars['String']['input'];
};

export type MakeLaptopAvailableResponse = LaptopIsRented | LaptopNotFound | MakeLaptopAvailableResult | UserIsNotAdmin;

export type MakeLaptopAvailableResult = {
  __typename?: 'MakeLaptopAvailableResult';
  laptopId: Scalars['String']['output'];
};

export type MaxAdminUsersLimitExceeded = GraphqlBaseErrorInterface & {
  __typename?: 'MaxAdminUsersLimitExceeded';
  maxCount: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCompany: AddCompanyResponse;
  createAdminUser: CreateAdminUserResponse;
  createLaptop: CreateLaptopResponse;
  deactivateUser: DeactivateUserResponse;
  makeLaptopAvailable: MakeLaptopAvailableResponse;
  raiseTicket: RaiseTicketResponse;
  rentLaptop: RentLaptopResponse;
  updateCompany: UpdateCompanyResponse;
  updateLaptop: UpdateLaptopResponse;
  updateRentedLaptopCompanyDetails: UpdateRentedLaptopCompanyDetailsResponse;
  updateTicketStatus: UpdateTicketStatusResponse;
  updateUser: UpdateUserResponse;
};


export type MutationAddCompanyArgs = {
  params: AddCompanyParams;
};


export type MutationCreateAdminUserArgs = {
  params: CreateAdminUserParams;
};


export type MutationCreateLaptopArgs = {
  params: CreateLaptopParams;
};


export type MutationDeactivateUserArgs = {
  params: DeactivateUserParams;
};


export type MutationMakeLaptopAvailableArgs = {
  params: MakeLaptopAvailableParams;
};


export type MutationRaiseTicketArgs = {
  params: RaiseTicketParams;
};


export type MutationRentLaptopArgs = {
  params: RentLaptopParams;
};


export type MutationUpdateCompanyArgs = {
  params: UpdateCompanyParams;
};


export type MutationUpdateLaptopArgs = {
  params: UpdateLaptopParams;
};


export type MutationUpdateRentedLaptopCompanyDetailsArgs = {
  params: UpdateRentedLaptopCompanyDetailsParams;
};


export type MutationUpdateTicketStatusArgs = {
  params: UpdateTicketStatusParams;
};


export type MutationUpdateUserArgs = {
  params: UpdateUserParams;
};

export type NewLaptopShouldBeInAvailableState = GraphqlBaseErrorInterface & {
  __typename?: 'NewLaptopShouldBeInAvailableState';
  laptopId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type NotCompanyManager = GraphqlBaseErrorInterface & {
  __typename?: 'NotCompanyManager';
  message?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  getAccountLaptops: GetAccountLaptopsResponse;
  getAccountUsers: GetAccountUsersResponse;
  getCompaniesInAccount: GetCompaniesInAccountResponse;
  getCompany: GetCompanyResponse;
  getCompanyDashboardData: GetCompanyDashboardDataResponse;
  getGraphData: GetGraphResponse;
  getLaptopStats: GetLaptopStatsResponse;
  getTicketsInAccount: GetTicketsInAccountResponse;
  getUserAppConfig: UserAppConfig;
};


export type QueryGetAccountLaptopsArgs = {
  params: GetAccountLaptopsParams;
};


export type QueryGetCompaniesInAccountArgs = {
  params: GetCompaniesInAccountParams;
};


export type QueryGetCompanyArgs = {
  params: GetCompanyParams;
};


export type QueryGetTicketsInAccountArgs = {
  params: GetTicketsInAccountParams;
};

export type RaiseTicketParams = {
  companyId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  laptopId: Scalars['String']['input'];
  ticketConfig?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ticketType: TicketType;
};

export type RaiseTicketResponse = AdminCannotRaiseTicket | CompanyNotFound | LaptopNotFound | LaptopNotLinkedToCompany | Ticket;

export type RentLaptopParams = {
  companyId: Scalars['String']['input'];
  deliveryDate: Scalars['GQLDateScalar']['input'];
  employeeId: Scalars['String']['input'];
  employeeName: Scalars['String']['input'];
  laptopId: Scalars['String']['input'];
  returnDate?: InputMaybe<Scalars['GQLDateScalar']['input']>;
};

export type RentLaptopResponse = CompanyNotFound | DeliveryDateMustBeGreaterThanProcuredDate | Laptop | LaptopIsAlreadyLinkedToCompany | LaptopIsDamaged | LaptopNotFound | UserIsNotAdmin;

export type ReplacementDetailsAreRequired = GraphqlBaseErrorInterface & {
  __typename?: 'ReplacementDetailsAreRequired';
  message?: Maybe<Scalars['String']['output']>;
};

export type ReplacementTicketDetailsParams = {
  laptopId: Scalars['String']['input'];
  oldLaptopReturnDate?: InputMaybe<Scalars['GQLDateScalar']['input']>;
  replacementDate: Scalars['GQLDateScalar']['input'];
  technicalPerson?: InputMaybe<Scalars['String']['input']>;
};

export type ReturnDateIsRequired = GraphqlBaseErrorInterface & {
  __typename?: 'ReturnDateIsRequired';
  message?: Maybe<Scalars['String']['output']>;
};

export type SerialNumberAlreadyExist = GraphqlBaseErrorInterface & {
  __typename?: 'SerialNumberAlreadyExist';
  message?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
};

export type Ticket = {
  __typename?: 'Ticket';
  companyName: Scalars['String']['output'];
  config?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  creationDatetime: Scalars['GQLDateTimeScalar']['output'];
  description?: Maybe<Scalars['String']['output']>;
  laptop: Laptop;
  newLaptop?: Maybe<Laptop>;
  oldLaptopReturnDate?: Maybe<Scalars['GQLDateScalar']['output']>;
  replacementDate?: Maybe<Scalars['GQLDateScalar']['output']>;
  status: TicketStatus;
  technicalPerson?: Maybe<Scalars['String']['output']>;
  ticketId: Scalars['String']['output'];
  ticketType: TicketType;
};

export type TicketNotFound = GraphqlBaseErrorInterface & {
  __typename?: 'TicketNotFound';
  message?: Maybe<Scalars['String']['output']>;
  ticketId: Scalars['String']['output'];
};

/** An enumeration. */
export enum TicketStatus {
  Discarded = 'DISCARDED',
  Pending = 'PENDING',
  Resolved = 'RESOLVED'
}

/** An enumeration. */
export enum TicketType {
  PickUp = 'PICK_UP',
  Replacement = 'REPLACEMENT',
  Service = 'SERVICE'
}

export type UpdateCompanyParams = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['String']['input'];
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
};

export type UpdateCompanyResponse = Company | CompanyNotFound | InvalidUrl | UserIsNotAdmin;

export type UpdateLaptopParams = {
  configuration: Scalars['String']['input'];
  laptopId: Scalars['String']['input'];
  vendor: Scalars['String']['input'];
};

export type UpdateLaptopResponse = Laptop | LaptopNotFound | UserIsNotAdmin;

export type UpdateRentedLaptopCompanyDetailsParams = {
  companyId: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  employeeName: Scalars['String']['input'];
  laptopId: Scalars['String']['input'];
};

export type UpdateRentedLaptopCompanyDetailsResponse = CompanyNotFound | LaptopNotFound | UpdateRentedLaptopCompanyDetailsResult | UserIsNotAdmin;

export type UpdateRentedLaptopCompanyDetailsResult = {
  __typename?: 'UpdateRentedLaptopCompanyDetailsResult';
  companyId: Scalars['String']['output'];
  employeeId: Scalars['String']['output'];
  employeeName: Scalars['String']['output'];
  laptopId: Scalars['String']['output'];
};

export type UpdateTicketStatusParams = {
  replacementDetails?: InputMaybe<ReplacementTicketDetailsParams>;
  returnDate?: InputMaybe<Scalars['GQLDateScalar']['input']>;
  status: TicketStatus;
  ticketId: Scalars['String']['input'];
};

export type UpdateTicketStatusResponse = NewLaptopShouldBeInAvailableState | ReplacementDetailsAreRequired | ReturnDateIsRequired | TicketNotFound | UpdateTicketStatusResult | UserIsNotAdmin;

export type UpdateTicketStatusResult = {
  __typename?: 'UpdateTicketStatusResult';
  status: TicketStatus;
  ticketId: Scalars['String']['output'];
};

export type UpdateUserParams = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profilePic?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UpdateUserResponse = EmailAlreadyExist | InvalidEmail | InvalidPhoneNumber | InvalidUrl | User | UserIsNotAdmin | UserNotInAccount;

export type User = {
  __typename?: 'User';
  emailId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePic?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserAppConfig = {
  __typename?: 'UserAppConfig';
  baseUser: User;
  isAdmin: Scalars['Boolean']['output'];
  otherDetails?: Maybe<UserOtherDetails>;
};

export type UserIsNotAdmin = GraphqlBaseErrorInterface & {
  __typename?: 'UserIsNotAdmin';
  message?: Maybe<Scalars['String']['output']>;
};

export type UserNotInAccount = GraphqlBaseErrorInterface & {
  __typename?: 'UserNotInAccount';
  message?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserOtherDetails = {
  __typename?: 'UserOtherDetails';
  companyId: Scalars['String']['output'];
};
