import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import CompaniesStoreProvider from "../../contexts/CompaniesStoreContext";
import DashboardStoreProvider from "../../contexts/DashboardStoreContext";
import LaptopsStoreProvider from "../../contexts/LaptopsStoreContext";
import UsersStoreProvider from "../../contexts/UsersStoreContext";
import UserAppConfigStoreContext from "../../contexts/UserAppConfigStoreContext";

const StoresProvider = (props: PropsWithChildren): React.ReactElement => {
  return (
    <UserAppConfigStoreContext>
      <UsersStoreProvider>
        <DashboardStoreProvider>
          <LaptopsStoreProvider>
            <CompaniesStoreProvider>{props.children}</CompaniesStoreProvider>
          </LaptopsStoreProvider>
        </DashboardStoreProvider>
      </UsersStoreProvider>
    </UserAppConfigStoreContext>
  );
};

export default observer(StoresProvider);
