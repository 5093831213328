import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetGraphDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGraphDataQuery = { __typename?: 'Query', getGraphData: { __typename?: 'GetGraphResult', totalItems?: Array<{ __typename?: 'GraphDataPoint', month: string, count: string } | null> | null, availableItems?: Array<{ __typename?: 'GraphDataPoint', month: string, count: string } | null> | null, rentedItems?: Array<{ __typename?: 'GraphDataPoint', month: string, count: string } | null> | null } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const GetGraphDataDocument = gql`
    query GetGraphData {
  getGraphData {
    ... on GetGraphResult {
      totalItems {
        month
        count
      }
      availableItems {
        month
        count
      }
      rentedItems {
        month
        count
      }
    }
    ... on UserIsNotAdmin {
      message
    }
  }
}
    `;
export type GetGraphDataQueryResult = Apollo.QueryResult<GetGraphDataQuery, GetGraphDataQueryVariables>;