import { observer } from "mobx-react-lite";

import { useCreateAdminUser } from "../../apis/CreateAdminUser/useCreateAdminUser";
import AddUser from "../../components/AddUser/AddUser";
import { showSuccessToast } from "../../utils/toastUtils";

import { useUserState } from "./useUserState";

interface Props {
  onClose: () => void;
}

const AddUserController = (props: Props): React.ReactElement => {
  const { onClose } = props;

  const state = useUserState();

  const createUserAPI = useCreateAdminUser();

  const onCreateUser = (): void => {
    const { phoneNumber, emailId, name } = state;

    const isValid = emailId && name;

    if (!isValid) return;

    createUserAPI.triggerAPI({
      reqObj: {
        emailId,
        name,
        phoneNumber,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("User created");
      },
      onFailure: () => {},
    });
  };

  return (
    <AddUser
      emailId={state.emailId}
      phoneNumber={state.phoneNumber}
      name={state.name}
      setEmailId={state.setEmailId}
      setPhoneNumber={state.setPhoneNumber}
      setName={state.setName}
      onClose={onClose}
      onAddUser={onCreateUser}
      isPending={createUserAPI.isLoading}
    />
  );
};

export default observer(AddUserController);
