import { makeAutoObservable } from "mobx";

import { BaseUser, Company } from "../graphql/graphqlTypes";

export class CompanyModel {
  readonly companyId: string;

  private _manager: BaseUser;
  private _name: string;
  private _address: string | null | undefined;
  private _rentedLaptopsCount: number;

  private _logoUrl: string | null | undefined;

  private constructor(data: Company) {
    this.companyId = data.companyId;

    this._manager = data.manager;
    this._name = data.name;
    this._address = data.address;
    this._rentedLaptopsCount = data.rentedLaptopsCount;

    this._logoUrl = data.logoUrl;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get manager() {
    return this._manager;
  }

  get name() {
    return this._name;
  }

  get logoUrl() {
    return this._logoUrl;
  }

  get rentedLaptopsCount() {
    return this._rentedLaptopsCount;
  }

  get address() {
    return this._address;
  }

  setManager(manager: BaseUser) {
    this._manager = manager;
  }

  setName(name: string) {
    this._name = name;
  }

  setLogoUrl(logoUrl: string | null | undefined) {
    this._logoUrl = logoUrl;
  }

  setRentedLaptopsCount(rentedLaptopsCount: number) {
    this._rentedLaptopsCount = rentedLaptopsCount;
  }

  setAddress(address: string | null | undefined) {
    this._address = address;
  }

  update(data: Company) {
    this._manager = data.manager;
    this._name = data.name;
    this._rentedLaptopsCount = data.rentedLaptopsCount;
    this._logoUrl = data.logoUrl;
    this._address = data.address;
  }

  static create(data: Company): CompanyModel {
    return new CompanyModel(data);
  }
}
