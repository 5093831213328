import { observer } from "mobx-react-lite";

import { useCreateLaptop } from "../../apis/CreateLaptop/useCreateLaptop";
import AddLaptop from "../../components/AddLaptop/AddLaptop";
import { showSuccessToast } from "../../utils/toastUtils";

import { useLaptopState } from "./useLaptopState";

interface Props {
  onClose: () => void;
  updateRefreshId: () => void;
}

const AddLaptopController = (props: Props): React.ReactElement => {
  const { onClose, updateRefreshId } = props;

  const state = useLaptopState();

  const createLaptopAPI = useCreateLaptop();

  const onCreateLaptop = (): void => {
    const { modelNumber, serialNumber, vendor, configuration, procuredDate } =
      state;

    const isValid = modelNumber && serialNumber && vendor && configuration;

    if (!isValid) return;

    createLaptopAPI.triggerAPI({
      reqObj: {
        modelNumber,
        serialNumber,
        vendor,
        configuration,
        procuredDate: procuredDate?.toString(),
      },
      onSuccess: () => {
        updateRefreshId();
        onClose();
        showSuccessToast("Laptop created");
      },
      onFailure: () => {},
    });
  };

  return (
    <AddLaptop
      serialNumber={state.serialNumber}
      modelNumber={state.modelNumber}
      vendor={state.vendor}
      configuration={state.configuration}
      procuredDate={state.procuredDate}
      setSerialNumber={state.setSerialNumber}
      setModelNumber={state.setModelNumber}
      setVendor={state.setVendor}
      setProcuredDate={state.setProcuredDate}
      onClose={onClose}
      onAddLaptop={onCreateLaptop}
      setConfiguration={state.setConfiguration}
    />
  );
};

export default observer(AddLaptopController);
