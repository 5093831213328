import { makeAutoObservable } from "mobx";

import { Company } from "../graphql/graphqlTypes";
import { Collection } from "../models/Collection";
import { CompanyModel } from "../models/CompanyModel";

export class CompaniesStore {
  private _companiesCollection: Collection<string, CompanyModel>;

  private constructor() {
    this._companiesCollection = new Collection<string, CompanyModel>(
      "the comapny doesn't exist in store"
    );

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get companies() {
    return this._companiesCollection.orderedItems;
  }

  createOrUpdateCompany(company: Company) {
    const hasItem = this._companiesCollection.hasItem(company.companyId);
    if (hasItem) {
      const companyModel = this._companiesCollection.getItem(company.companyId);
      companyModel.update(company);
    } else {
      const companyModel = CompanyModel.create(company);
      this._companiesCollection.addItem(company.companyId, companyModel);
    }
  }

  getCompany(companyId: string) {
    return this._companiesCollection.getItem(companyId);
  }

  hasCompany(companyId: string) {
    return this._companiesCollection.hasItem(companyId);
  }

  deleteCompany(companyId: string) {
    this._companiesCollection.deleteItem(companyId);
  }

  static create(): CompaniesStore {
    return new CompaniesStore();
  }
}
