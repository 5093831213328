import { observer } from "mobx-react-lite";

import { useUpdateRentedLaptopCompanyDetails } from "../../apis/UpdateRentedLaptopCompanyDetails/useUpdateRentedLaptopCompanyDetails";
import UpdateLaptopRentedDetails from "../../components/UpdateLaptopRentedDetails/UpdateLaptopRentedDetails";
import { useLaptopsStore } from "../../contexts/LaptopsStoreContext";
import { showSuccessToast } from "../../utils/toastUtils";
import { useUpdateRentedLaptopState } from "./useUpdateRentedLaptopState";

interface Props {
  laptopId: string;
  onClose: () => void;
  updateRefreshId: () => void;
}

const UpdateLaptopController = (props: Props): React.ReactElement => {
  const { laptopId, onClose, updateRefreshId } = props;

  const laptopModel = useLaptopsStore().getLaptop(laptopId);

  const state = useUpdateRentedLaptopState(laptopModel);

  const updateLaptopAPI = useUpdateRentedLaptopCompanyDetails();

  const onUpdateLaptop = (): void => {
    updateLaptopAPI.triggerAPI({
      reqObj: {
        laptopId,
        companyId: laptopModel.companyId ?? "",
        employeeId: state.employeeId ?? "",
        employeeName: state.employeeName ?? "",
      },
      onSuccess: () => {
        updateRefreshId();
        onClose();
        showSuccessToast("Laptop Updated");
      },
    });
  };

  return (
    <UpdateLaptopRentedDetails
      employeeId={state.employeeId}
      employeeName={state.employeeName}
      setEmployeeId={state.setEmployeeId}
      setEmployeeName={state.setEmployeeName}
      onClose={onClose}
      onUpdateLaptop={onUpdateLaptop}
    />
  );
};

export default observer(UpdateLaptopController);
