import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "../constants/regexConstants";
import { ValidationResult } from "../types/validationTypes";

export const validateEmail = (
  email: string,
  isRequired?: boolean
): ValidationResult => {
  const trimmedEmail = email.trim();

  if (isRequired && !trimmedEmail) {
    return {
      isValid: false,
      message: "Email is required",
    };
  }

  const isValid = EMAIL_REGEX.test(trimmedEmail);

  return {
    isValid,
    message: isValid ? null : "Invalid email address",
  };
};

export const validatePhoneNumber = (phoneNumber: string): ValidationResult => {
  const trimmedPhoneNumber = phoneNumber.trim();
  const isValid = PHONE_NUMBER_REGEX.test(trimmedPhoneNumber);

  return {
    isValid,
    message: isValid ? null : "Invalid phone number",
  };
};
