import { observer } from "mobx-react-lite";
import { createContext, PropsWithChildren, useContext, useRef } from "react";

import { UserAppConfigStore } from "../stores/UserAppConfigStore";

export const UserAppConfigStoreContext =
  createContext<UserAppConfigStore | null>(null);

const UserAppConfigStoreProvider = (props: PropsWithChildren) => {
  const userAppConfigStoreRef = useRef(UserAppConfigStore.create());

  return (
    <UserAppConfigStoreContext.Provider value={userAppConfigStoreRef.current}>
      {props.children}
    </UserAppConfigStoreContext.Provider>
  );
};

export const useUserAppConfigStore = (): UserAppConfigStore => {
  const userAppConfigStore = useContext(UserAppConfigStoreContext);

  if (!userAppConfigStore) {
    throw new Error("Expected to be wrapped in UserAppConfig Store Provider");
  }

  return userAppConfigStore;
};

export default observer(UserAppConfigStoreProvider);
