import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  SpectrumTextFieldProps,
  TextArea,
  TextField,
} from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  validatePhoneNumber,
  validateEmail,
} from "../../utils/validationUtils";

interface Props {
  companyName: string | null;
  address: string | null;
  managerName: string | null;
  employeeEmail: string | null;
  phoneNumber: string | null;
  companyLogo: string | null;

  setCompanyName: (companyName: string | null) => void;
  setAddress: (address: string | null) => void;
  setManagerName: (managerName: string | null) => void;
  setEmployeeEmail: (employeeEmail: string | null) => void;
  setPhoneNumber: (phoneNumber: string | null) => void;
  setCompanyLogo: (companyLogo: string | null) => void;

  onClose: () => void;
  onCreateCompany: () => void;

  isEdit?: boolean;
}

const CreateCompany = (props: Props): React.ReactElement => {
  const {
    companyName,
    address,
    managerName,
    employeeEmail,
    phoneNumber,
    companyLogo,
    setCompanyName,
    setAddress,
    setManagerName,
    setEmployeeEmail,
    onClose,
    onCreateCompany,
    setPhoneNumber,
    setCompanyLogo,
    isEdit,
  } = props;

  const commonProps: SpectrumTextFieldProps = {
    type: "text",
    width: "100%",
  };

  const isPhoneNumberValid = phoneNumber
    ? validatePhoneNumber(phoneNumber).isValid
    : true;
  const isEmailValid = validateEmail(employeeEmail ?? "").isValid;

  const areDetailsValid =
    companyName?.trim() &&
    managerName?.trim() &&
    isEmailValid &&
    isPhoneNumberValid;

  const renderCompanyName = () => (
    <TextField
      label="Company Name"
      value={companyName ?? ""}
      onChange={setCompanyName}
      {...commonProps}
      isRequired
    />
  );

  const renderAddress = () => (
    <TextArea
      label="Company Address"
      value={address ?? ""}
      onChange={setAddress}
      {...commonProps}
    />
  );

  const renderManagerName = () => (
    <TextField
      label="Manager Name"
      value={managerName ?? ""}
      onChange={setManagerName}
      {...commonProps}
      isRequired
      UNSAFE_className="flex-1 min-w-0"
    />
  );

  const renderEmployeeEmail = () => (
    <TextField
      label="Manager Email"
      {...commonProps}
      value={employeeEmail ?? ""}
      onChange={setEmployeeEmail}
      validationState={isEmailValid ? undefined : "invalid"}
      isRequired
    />
  );

  const renderPhoneNumber = () => (
    <TextField
      label="Mobile Number"
      type="tel"
      value={phoneNumber ?? ""}
      onChange={setPhoneNumber}
      UNSAFE_className="flex-1 min-w-0"
      validationState={isPhoneNumberValid ? undefined : "invalid"}
    />
  );

  const renderCompanyLogo = () => (
    <TextField
      label="Company Logo Url"
      {...commonProps}
      value={companyLogo ?? ""}
      onChange={setCompanyLogo}
    />
  );

  return (
    <>
      <Heading>{isEdit ? "Update Company Details" : "Add Company"}</Heading>
      <Divider />

      <Content UNSAFE_className="scrollbar">
        <div className={cn("flex flex-col gap-2 w-full")}>
          {renderCompanyName()}
          {renderCompanyLogo()}
          {renderAddress()}
          <div className="flex gap-2">
            {renderManagerName()}
            {renderPhoneNumber()}
          </div>
          {renderEmployeeEmail()}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button
          variant="accent"
          onPress={onCreateCompany}
          isDisabled={!areDetailsValid}
        >
          {isEdit ? "Update" : "Add"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(CreateCompany);
