import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type RentLaptopMutationVariables = Types.Exact<{
  params: Types.RentLaptopParams;
}>;


export type RentLaptopMutation = { __typename?: 'Mutation', rentLaptop: { __typename?: 'CompanyNotFound', message?: string | null, companyId: string } | { __typename?: 'DeliveryDateMustBeGreaterThanProcuredDate' } | { __typename?: 'Laptop', laptopId: string } | { __typename?: 'LaptopIsAlreadyLinkedToCompany', message?: string | null, laptopId: string } | { __typename?: 'LaptopIsDamaged', message?: string | null, laptopId: string } | { __typename?: 'LaptopNotFound', message?: string | null, laptopId: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const RentLaptopDocument = gql`
    mutation RentLaptop($params: RentLaptopParams!) {
  rentLaptop(params: $params) {
    ... on Laptop {
      laptopId
    }
    ... on UserIsNotAdmin {
      message
    }
    ... on LaptopNotFound {
      message
      laptopId
    }
    ... on LaptopIsDamaged {
      message
      laptopId
    }
    ... on CompanyNotFound {
      message
      companyId
    }
    ... on LaptopIsAlreadyLinkedToCompany {
      message
      laptopId
    }
  }
}
    `;
export type RentLaptopMutationFn = Apollo.MutationFunction<RentLaptopMutation, RentLaptopMutationVariables>;
export type RentLaptopMutationResult = Apollo.MutationResult<RentLaptopMutation>;
export type RentLaptopMutationOptions = Apollo.BaseMutationOptions<RentLaptopMutation, RentLaptopMutationVariables>;