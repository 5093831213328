import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetAccountLaptopsQueryVariables = Types.Exact<{
  params: Types.GetAccountLaptopsParams;
}>;


export type GetAccountLaptopsQuery = { __typename?: 'Query', getAccountLaptops: { __typename?: 'CompanyIsRequiredForNonAdmin' } | { __typename?: 'CompanyNotFound', message?: string | null, companyId: string } | { __typename?: 'GetAccountLaptopsResult', totalCount: number, laptops?: Array<{ __typename?: 'Laptop', laptopId: string, modelNumber: string, serialNumber: string, configuration?: string | null, vendor: string, procuredDate: any, status: Types.LaptopStatus, rentedDetails?: { __typename?: 'LaptopRentedDetails', companyId: string, companyName: string, deliveryDate: any, returnDate?: any | null, replacementDate?: any | null, employeeId: string, employeeName: string } | null } | null> | null } | { __typename?: 'InvalidLimit', message?: string | null } | { __typename?: 'InvalidOffset', message?: string | null } };


export const GetAccountLaptopsDocument = gql`
    query GetAccountLaptops($params: GetAccountLaptopsParams!) {
  getAccountLaptops(params: $params) {
    ... on GetAccountLaptopsResult {
      totalCount
      laptops {
        laptopId
        modelNumber
        serialNumber
        configuration
        vendor
        procuredDate
        status
        rentedDetails {
          companyId
          companyName
          deliveryDate
          returnDate
          replacementDate
          employeeId
          employeeName
        }
      }
    }
    ... on InvalidLimit {
      message
    }
    ... on InvalidOffset {
      message
    }
    ... on CompanyNotFound {
      message
      companyId
    }
  }
}
    `;
export type GetAccountLaptopsQueryResult = Apollo.QueryResult<GetAccountLaptopsQuery, GetAccountLaptopsQueryVariables>;