import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Content,
  Divider,
  Grid,
  Heading,
  TextField,
} from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { FaLaptopCode } from "react-icons/fa";
import { GiAutoRepair } from "react-icons/gi";
import { MdOutlineLocalShipping } from "react-icons/md";

import { SERVICE_TYPES } from "../../constants/commonConstants";
import { TicketType } from "../../graphql/graphqlTypes";

interface Props {
  ticketType: TicketType | null;
  description: string | null;
  ticketConfig: string[];

  setTicketType: (ticketType: TicketType | null) => void;
  setDescription: (description: string | null) => void;
  setTicketConfig: (ticketConfig: string[]) => void;

  onClose: () => void;
  onRaiseTicket: () => void;

  isPending: boolean;
}

const ticketTypesData = [
  {
    value: TicketType.Service,
    label: "Service",
    description: "Need to fix an issue?",
    className: "bg-red-300",
    icon: <GiAutoRepair size={28} />,
  },
  {
    value: TicketType.PickUp,
    label: "Pick Up",
    description: "Need to return your laptop?",
    className: "bg-green-300",
    icon: <MdOutlineLocalShipping size={28} />,
  },
  {
    value: TicketType.Replacement,
    label: "Replacement",
    description: "Need a new laptop?",
    className: "bg-blue-300",
    icon: <FaLaptopCode size={28} />,
  },
];

const RaiseTicket = (props: Props): React.ReactElement => {
  const {
    ticketType,
    description,
    ticketConfig,
    setTicketType,
    setDescription,
    setTicketConfig,
    onClose,
    onRaiseTicket,
    isPending,
  } = props;

  const isValid =
    ticketType &&
    (ticketType === TicketType.Service
      ? !!description || ticketConfig.length > 0
      : true);

  const renderTicketType = () => (
    <div className="flex justify-center items-center gap-4">
      {ticketTypesData.map((ticket) => (
        <div
          className={cn(
            "flex flex-col items-center bg-white hover:shadow p-4 border rounded-lg w-40 text-center transition-shadow cursor-pointer",
            ticketType === ticket.value ? "border-blue-500" : "border-gray-300"
          )}
          onClick={() => setTicketType(ticket.value)}
        >
          <div
            className={cn(
              "flex justify-center items-center bg-gray-100 rounded-full w-14 h-14",
              ticket.className
            )}
          >
            {ticket.icon}
          </div>
          <p className={cn("mt-3 font-semibold text-base text-gray-800")}>
            {ticket.label}
          </p>
          <p className="text-gray-500 text-sm">{ticket.description}</p>
        </div>
      ))}
    </div>
  );

  const renderDescription = () => (
    <TextField
      label="Other"
      value={description ?? ""}
      onChange={setDescription}
      width={"50%"}
    />
  );

  const renderTicketConfig = () => (
    <CheckboxGroup
      label="Service Type"
      value={ticketConfig}
      onChange={setTicketConfig}
      isRequired
    >
      <Grid
        columns={["1fr", "1fr", "1fr"]}
        autoRows="size-400"
        alignItems="start"
      >
        {SERVICE_TYPES.map((serviceType) => (
          <Checkbox value={serviceType}>{serviceType}</Checkbox>
        ))}
      </Grid>
    </CheckboxGroup>
  );

  const shouldShowTickerConfig = ticketType === TicketType.Service;
  const shouldShowDescription =
    shouldShowTickerConfig && ticketConfig.includes("Other");

  return (
    <>
      <Heading>Raise Ticket</Heading>
      <Divider />

      <Content UNSAFE_className="scrollbar">
        <div className={cn("flex flex-col gap-2 w-full")}>
          {renderTicketType()}
          {shouldShowTickerConfig ? renderTicketConfig() : null}
          {shouldShowDescription ? renderDescription() : null}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button
          variant="accent"
          onPress={onRaiseTicket}
          isDisabled={!isValid}
          isPending={isPending}
        >
          {"Submit"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(RaiseTicket);
