import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type RaiseTicketMutationVariables = Types.Exact<{
  params: Types.RaiseTicketParams;
}>;


export type RaiseTicketMutation = { __typename?: 'Mutation', raiseTicket: { __typename?: 'AdminCannotRaiseTicket', message?: string | null } | { __typename?: 'CompanyNotFound', message?: string | null, companyId: string } | { __typename?: 'LaptopNotFound', message?: string | null, laptopId: string } | { __typename?: 'LaptopNotLinkedToCompany', message?: string | null, laptopId: string, companyId: string } | { __typename?: 'Ticket', ticketId: string, ticketType: Types.TicketType, status: Types.TicketStatus, companyName: string, description?: string | null, config?: Array<string | null> | null, laptop: { __typename?: 'Laptop', laptopId: string, modelNumber: string, serialNumber: string, configuration?: string | null, vendor: string, procuredDate: any, status: Types.LaptopStatus, rentedDetails?: { __typename?: 'LaptopRentedDetails', companyName: string, deliveryDate: any, returnDate?: any | null, replacementDate?: any | null, employeeId: string, employeeName: string } | null } } };


export const RaiseTicketDocument = gql`
    mutation RaiseTicket($params: RaiseTicketParams!) {
  raiseTicket(params: $params) {
    ... on Ticket {
      ticketId
      ticketType
      status
      companyName
      description
      config
      laptop {
        laptopId
        modelNumber
        serialNumber
        configuration
        vendor
        procuredDate
        status
        rentedDetails {
          companyName
          deliveryDate
          returnDate
          replacementDate
          employeeId
          employeeName
        }
      }
    }
    ... on AdminCannotRaiseTicket {
      message
    }
    ... on LaptopNotLinkedToCompany {
      message
      laptopId
      companyId
    }
    ... on LaptopNotFound {
      message
      laptopId
    }
    ... on CompanyNotFound {
      message
      companyId
    }
  }
}
    `;
export type RaiseTicketMutationFn = Apollo.MutationFunction<RaiseTicketMutation, RaiseTicketMutationVariables>;
export type RaiseTicketMutationResult = Apollo.MutationResult<RaiseTicketMutation>;
export type RaiseTicketMutationOptions = Apollo.BaseMutationOptions<RaiseTicketMutation, RaiseTicketMutationVariables>;