import { Item, Picker } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { useAsyncList } from "react-stately";

import { useGetCompaniesInAccount } from "../../apis/GetCompaniesInAccount/useGetCompaniesInAccount";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { Company } from "../../graphql/graphqlTypes";

interface Props {
  companyId: string;
  onChangeCompanyId: (id: string) => void;
  containerClassName?: string;
}

const CompaniesSearchController = (props: Props): React.ReactElement => {
  const getCompaniesAPI = useGetCompaniesInAccount();

  const getCompanies = (offset: number) =>
    getCompaniesAPI.triggerAPI({
      reqObj: {
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset,
        },
      },
    });

  let list = useAsyncList<Company>({
    async load({ cursor }) {
      let response = await getCompanies(Number(cursor) || 0);
      const UpdatedCursor = response.length
        ? Number(cursor) + DEFAULT_API_LIMIT
        : undefined;
      return {
        items: response,
        cursor: UpdatedCursor?.toString(),
      };
    },
  });

  return (
    <Picker
      label="Rent Laptop to Company"
      items={list.items}
      isLoading={list.isLoading}
      onLoadMore={list.loadMore}
      selectedKey={props.companyId}
      onSelectionChange={(key) => props.onChangeCompanyId(key as string)}
      UNSAFE_className={props.containerClassName}
    >
      {(item) => <Item key={item.companyId}>{item.name}</Item>}
    </Picker>
  );
};

export default observer(CompaniesSearchController);
