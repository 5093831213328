import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";

import { axiosClient } from "../configs/axiosConfig";
import {
  GRAPHQL_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
} from "../constants/apiConstants";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../constants/authConstants";
import { getAccessToken, getRefreshToken } from "../utils/authUtils";

// Authorization link
const authLink = setContext((_, { headers }) => {
  // Get the token from localStorage (or any other storage)
  const token = getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// HTTP link to connect to the GraphQL API
const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
});

const refreshLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    const subscriber = forward(operation).subscribe({
      next: observer.next.bind(observer),
      error: async (error) => {
        if (error.statusCode === 401) {
          try {
            const refreshToken = getRefreshToken();
            const reqObj = JSON.stringify({ refresh_token: refreshToken });
            const response = await axiosClient.post(
              REFRESH_TOKEN_ENDPOINT,
              reqObj
            );
            if (response.status === 200) {
              const accessToken = response.data.access_token;
              Cookies.set(ACCESS_TOKEN_KEY, accessToken);

              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              const retrySubscriber = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });

              return () => retrySubscriber.unsubscribe();
            }
          } catch (refreshError) {
            Cookies.remove(ACCESS_TOKEN_KEY);
            Cookies.remove(REFRESH_TOKEN_KEY);
            window.location.href = "/login";
          }
        }
        observer.error(error);
      },
      complete: observer.complete.bind(observer),
    });

    return () => subscriber.unsubscribe();
  });
});

// Combine the authLink and httpLink
const link = ApolloLink.from([refreshLink, authLink.concat(httpLink)]);

// Apollo Client setup
export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
