import { useState } from "react";

import { CompanyModel } from "../../models/CompanyModel";

export const useCompanyState = (defaultCompany: CompanyModel) => {
  const [companyName, setCompanyName] = useState<string | null>(
    defaultCompany.name
  );
  const [address, setAddress] = useState<string | null>(
    defaultCompany.address ?? null
  );
  const [managerName, setManagerName] = useState<string | null>(
    defaultCompany.manager.name
  );
  const [employeeEmail, setEmployeeEmail] = useState<string | null>(
    defaultCompany.manager.emailId
  );
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [companyLogo, setCompanyLogo] = useState<string | null>(
    defaultCompany.logoUrl ?? null
  );

  return {
    companyName,
    address,
    managerName,
    employeeEmail,
    phoneNumber,
    companyLogo,
    setCompanyName,
    setAddress,
    setManagerName,
    setEmployeeEmail,
    setPhoneNumber,
    setCompanyLogo,
  };
};
