import { ProgressCircle } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";

const Loader = (): React.ReactElement => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <ProgressCircle aria-label="Loading…" isIndeterminate />
    </div>
  );
};

export default observer(Loader);
