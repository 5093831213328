import React, { useState } from "react";

import LoginEmailStepController from "../LoginEmailStepController/LoginEmailStepController";
import LoginOtpStepController from "../LoginOtpStepController/LoginOtpStepController";

export interface SendOTPConfig {
  email: string;
  phoneNumber?: string;
}

type LoginStepType = "LOGIN_STEP" | "OTP_STEP";

const LoginController = (): React.ReactElement => {
  const [activeStep, setActiveStep] = useState<LoginStepType>("LOGIN_STEP");
  const [sendOTPConfig, setSendOTPConfig] = useState<SendOTPConfig | null>(
    null
  );

  const onNextStep = (data: SendOTPConfig): void => {
    setSendOTPConfig(data);
    setActiveStep("OTP_STEP");
  };

  const onBackStep = (): void => {
    setActiveStep("LOGIN_STEP");
  };

  const renderViewBasedOnStep = (): React.ReactElement => {
    switch (activeStep) {
      case "LOGIN_STEP":
        return <LoginEmailStepController onNextStep={onNextStep} />;
      case "OTP_STEP": {
        if (!sendOTPConfig) return <></>;

        return (
          <LoginOtpStepController
            onBackStep={onBackStep}
            sendOTPConfig={sendOTPConfig}
          />
        );
      }
    }
  };

  return renderViewBasedOnStep();
};

export default LoginController;
