import { Content, Heading, IllustratedMessage } from "@adobe/react-spectrum";
import { CalendarDate } from "@internationalized/date";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";
import type { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";

import { useGetAccountLaptops } from "../../apis/GetAccountLaptops/useGetAccountLaptops";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { LaptopStatus } from "../../graphql/graphqlTypes";
import { Laptop } from "../../types/inventoryTypes";

import Loader from "../Loader/Loader";
import RaiseTicketModal from "../RaiseTicketModal/RaiseTicketModal";
import UpdateLaptopModal from "../UpdateLaptopModal/UpdateLaptopModal";
import MakeLaptopAvailableModalController from "../../controllers/MakeLaptopAvailableModalController/MakeLaptopAvailableModalController";
import UpdateLaptopRentedDetailsModal from "../UpdateLaptopRentedDetailsModal/UpdateLaptopRentedDetailsModal";

interface Props {
  companyId?: string;
  searchText?: string;
  updateRefreshId: () => void;
}

const renderStatusBadge = (status: LaptopStatus): React.ReactElement => {
  switch (status) {
    case LaptopStatus.Available:
      return (
        <div className="bg-green-100 px-2 py-1 rounded-2xl font-medium text-green-600 text-sm">
          Available
        </div>
      );
    case LaptopStatus.Damaged:
      return (
        <div className="bg-red-100 px-2 py-1 rounded-2xl font-medium text-red-600 text-sm">
          Damaged
        </div>
      );
    case LaptopStatus.Rented:
      return (
        <div className="bg-blue-100 px-2 py-1 rounded-2xl font-medium text-blue-600 text-sm">
          Rented
        </div>
      );
  }
};

const LaptopsTable = (props: Props): React.ReactElement => {
  const gridRef = useRef<AgGridReact<Laptop>>(null);

  const { isAdmin, companyId } = useUserAppConfigStore();

  const [activeLaptopId, setActiveLaptopId] = useState<string | null>(null);
  const [rentedLaptopId, setRentedLaptopId] = useState<string | null>(null);
  const [damagedLaptopId, setDamagedLaptopId] = useState<string | null>(null);
  const [showNoSearchResults, setShowNoSearchResults] = useState(false);

  const onClose = () => setActiveLaptopId(null);
  const onCloseDamagedLaptopModal = () => setDamagedLaptopId(null);
  const onCloseRentedLaptopModal = () => setRentedLaptopId(null);

  const getAccountLaptopsAPI = useGetAccountLaptops();

  const getAccountLaptops = (offset = 0) => {
    return getAccountLaptopsAPI.triggerAPI({
      reqObj: {
        companyId: props.companyId,
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset,
        },
        searchQuery: props.searchText,
      },
    });
  };

  const columnDefs: ColDef<Laptop>[] = [
    {
      field: "serialNumber",
      headerName: "Laptop Serial No",
      width: 150,
    },
    {
      field: "vendor",
      headerName: "Laptop Vendor",
      sortable: false,
      width: 140,
      hide: !isAdmin,
    },
    {
      field: "modelNumber",
      headerName: "Model Number",
      width: 140,
    },
    {
      field: "configuration",
      headerName: "Configuration",
      width: 150,
    },

    {
      field: "status",
      headerName: "Status",
      cellRenderer: (value: any) => (
        <div className="flex items-center h-full">
          {renderStatusBadge(value.value)}
        </div>
      ),
      width: 100,
      hide: !isAdmin,
    },

    {
      field: "companyName",
      headerName: "Company Name",
      valueFormatter: (value) => value.value ?? "-",
      hide: !isAdmin,
    },
    {
      field: "procuredDate",
      headerName: "Procured Date",
      valueFormatter: (value) =>
        (value.value as CalendarDate | undefined)?.toString() ?? "-",
      width: 150,
      hide: !isAdmin,
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      valueFormatter: (value) =>
        (value.value as CalendarDate | undefined)?.toString() ?? "-",
      width: 150,
    },
    {
      field: "employeeId",
      headerName: "Employee Id",
      valueFormatter: (value) => value.value ?? "-",
      width: 120,
      onCellClicked({ data }) {
        if (data?.status === LaptopStatus.Rented)
          setRentedLaptopId(data.laptopId);
      },
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
      valueFormatter: (value) => value.value ?? "-",
      onCellClicked({ data }) {
        if (data?.status === LaptopStatus.Rented)
          setRentedLaptopId(data.laptopId);
      },
    },
    {
      field: "returnDate",
      headerName: "Return Date",
      valueFormatter: (value) =>
        (value.value as CalendarDate | undefined)?.toString() ?? "-",
      width: 150,
    },
    {
      field: "replacementDate",
      headerName: "Replacement Date",
      valueFormatter: (value) =>
        (value.value as CalendarDate | undefined)?.toString() ?? "-",
      width: 160,
    },
  ];

  const datasource: IDatasource = useMemo(
    () => ({
      getRows: async (params: IGetRowsParams) => {
        const { startRow, endRow, successCallback, failCallback } = params;

        try {
          const response = await getAccountLaptops(startRow);

          const data: Laptop[] = response.laptops;

          const lastRow =
            data.length < endRow - startRow
              ? startRow + data.length
              : undefined;

          if (startRow === 0) setShowNoSearchResults(data.length === 0);

          successCallback(data, lastRow);
        } catch (error) {
          failCallback();
        }
      },
    }),
    []
  );

  const renderNoDataView = (): React.ReactElement => (
    <div className="bg-gray-50 w-full h-full">
      <IllustratedMessage>
        <NoSearchResults />
        <Heading>No Laptops Found</Heading>
        <Content>
          {props.searchText
            ? "No laptops matched your search"
            : " No laptops are added yet"}
        </Content>
      </IllustratedMessage>
    </div>
  );

  return (
    <div className="flex-1 w-full h-full ag-theme-quartz">
      {showNoSearchResults ? (
        renderNoDataView()
      ) : (
        <AgGridReact<Laptop>
          ref={gridRef}
          columnDefs={columnDefs as any}
          defaultColDef={{
            sortable: false,
            onCellClicked({ data }) {
              if (data) {
                if (data.status === LaptopStatus.Damaged)
                  setDamagedLaptopId(data.laptopId);
                else setActiveLaptopId(data.laptopId);
              }
            },
          }}
          rowModelType="infinite"
          cacheBlockSize={DEFAULT_API_LIMIT}
          maxConcurrentDatasourceRequests={1}
          datasource={datasource}
          loading={getAccountLaptopsAPI.isLoading}
          loadingOverlayComponent={() => <Loader />}
        />
      )}

      {isAdmin ? (
        <>
          <UpdateLaptopModal
            activeLaptopId={activeLaptopId}
            isOpen={!!activeLaptopId}
            onClose={onClose}
            updateRefreshId={props.updateRefreshId}
          />
          <MakeLaptopAvailableModalController
            activeLaptopId={damagedLaptopId}
            isOpen={!!damagedLaptopId}
            onClose={onCloseDamagedLaptopModal}
            updateRefreshId={props.updateRefreshId}
          />
        </>
      ) : (
        <RaiseTicketModal
          laptopId={activeLaptopId ?? ""}
          companyId={companyId ?? ""}
          isOpen={!!activeLaptopId}
          onClose={onClose}
        />
      )}
      <UpdateLaptopRentedDetailsModal
        activeLaptopId={rentedLaptopId}
        isOpen={!!rentedLaptopId}
        onClose={onCloseRentedLaptopModal}
        updateRefreshId={props.updateRefreshId}
      />
    </div>
  );
};

export default LaptopsTable;
