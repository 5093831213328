import { parseDate } from "@internationalized/date";

import { useLaptopsStore } from "../../contexts/LaptopsStoreContext";
import { Laptop } from "../../types/inventoryTypes";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { GetAccountLaptopsQuery as Query } from "./query.generatedTypes";
import { APIResponse } from "./useGetAccountLaptops";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => APIResponse;
}

export const useResponseHandler = (): ReturnType => {
  const laptopsStore = useLaptopsStore();
  const handleResponse = (args: ArgsType): APIResponse => {
    const response = args.data.getAccountLaptops;

    let responseData: APIResponse = {
      laptops: [],
      totalCount: 0,
    };

    switch (response.__typename) {
      case "GetAccountLaptopsResult": {
        args.onSuccess?.();
        if (!response.laptops) return responseData;

        const laptops: Laptop[] = [];

        response.laptops.forEach((laptop) => {
          if (!laptop) return;

          laptops.push({
            laptopId: laptop.laptopId,
            modelNumber: laptop.modelNumber,
            serialNumber: laptop.serialNumber,
            vendor: laptop.vendor,
            status: laptop.status,
            procuredDate: parseDate(laptop.procuredDate),
            configuration: laptop.configuration,
            companyId: laptop.rentedDetails?.companyId,
            companyName: laptop.rentedDetails?.companyName,
            deliveryDate: laptop.rentedDetails?.deliveryDate
              ? parseDate(laptop.rentedDetails.deliveryDate)
              : undefined,
            returnDate: laptop.rentedDetails?.returnDate
              ? parseDate(laptop.rentedDetails.returnDate)
              : undefined,
            replacementDate: laptop.rentedDetails?.replacementDate
              ? parseDate(laptop.rentedDetails.replacementDate)
              : undefined,
            employeeId: laptop.rentedDetails?.employeeId,
            employeeName: laptop.rentedDetails?.employeeName,
          });
        });

        laptops.forEach(laptopsStore.createOrUpdateLaptop);

        return {
          laptops,
          totalCount: response.totalCount,
        };
      }
      case "CompanyNotFound":
      case "InvalidLimit":
      case "InvalidOffset":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
    return responseData;
  };

  return {
    handleResponse,
  };
};
