import { useState } from "react";

import { LaptopModel } from "../../models/LaptopModel";

export const useUpdateRentedLaptopState = (laptopModel: LaptopModel) => {
  const [employeeId, setEmployeeId] = useState<string | null>(
    laptopModel.employeeId ?? null
  );
  const [employeeName, setEmployeeName] = useState<string | null>(
    laptopModel.employeeName ?? null
  );

  return {
    employeeId,
    employeeName,
    setEmployeeId,
    setEmployeeName,
  };
};
