import Cookies from "js-cookie";
import { useState } from "react";

import useVerifyOTP from "../../apis/VerifyOTP/useVerifyOTP";
import LoginOtpStep from "../../components/LoginOtpStep/LoginOtpStep";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../../constants/authConstants";
import { useRedirection } from "../../hooks/useRedirection";
import { showFailureToast } from "../../utils/toastUtils";

import { SendOTPConfig } from "../LoginController/LoginController";

interface Props {
  sendOTPConfig: SendOTPConfig;
  onBackStep: () => void;
}

const LoginOtpStepController = (props: Props): React.ReactElement => {
  const { sendOTPConfig, onBackStep } = props;

  const [otp, setOtp] = useState("");

  const verifyOTPAPI = useVerifyOTP();
  const { goToHome } = useRedirection();

  const onVerifyOTP = (): void => {
    verifyOTPAPI.triggerAPI({
      reqObj: { email: sendOTPConfig.email, otp: otp },
      onFailure: showFailureToast,
      onSuccess(data) {
        Cookies.set(ACCESS_TOKEN_KEY, data.access_token);
        Cookies.set(REFRESH_TOKEN_KEY, data.refresh_token, {
          expires: data.expires_in,
        });
        goToHome();
      },
    });
  };

  return (
    <LoginOtpStep
      sendOTPConfig={sendOTPConfig}
      otp={otp}
      setOtp={setOtp}
      onBackStep={onBackStep}
      onVerifyOTP={onVerifyOTP}
      isPending={verifyOTPAPI.isPending}
    />
  );
};

export default LoginOtpStepController;
