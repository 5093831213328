import { observer } from "mobx-react-lite";

interface Props {
  label: string;
  value: number;
  icon: React.ReactElement;
}

const StatItem = (props: Props): React.ReactElement => {
  const { label, icon, value } = props;

  return (
    <div className="flex flex-1 items-center gap-2 bg-white shadow px-6 py-4 rounded-xl min-w-0">
      <div className="bg-blue-50 p-4 rounded-full">{icon}</div>
      <div className="text-right flex flex-col flex-1 gap-2">
        <p className="font-medium text-gray-600 text-sm">{label}</p>
        <p className="font-semibold text-gray-700 text-xl">{value}</p>
      </div>
    </div>
  );
};

export default observer(StatItem);
