import cn from "classnames";
import { observer } from "mobx-react-lite";
import { BsPersonCircle } from "react-icons/bs";

import { colors } from "../../constants/colors";

interface Props {
  name: string;
  imageUrl?: string;
  containerClassName?: string;
}

const Avatar = (props: Props): React.ReactElement => {
  const { name, imageUrl, containerClassName } = props;

  return (
    <div className={cn("flex items-center gap-2", containerClassName)}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={name}
          width={32}
          height={32}
          className="shadow rounded-full"
        />
      ) : (
        <BsPersonCircle size={32} fill={colors["gray-800"]} />
      )}
      <p className={cn("font-normal text-base", "text-gray-700")}>{name}</p>
    </div>
  );
};

export default observer(Avatar);
