import { makeAutoObservable } from "mobx";

import { User } from "../graphql/graphqlTypes";

export class UserModel {
  readonly userId: string;
  private _name: string;
  private _emailId: string;
  private _profilePic: string | null | undefined;
  private _phoneNumber: string | null | undefined;

  private constructor(data: User) {
    this.userId = data.userId;
    this._name = data.name;
    this._emailId = data.emailId;
    this._profilePic = data.profilePic;
    this._phoneNumber = data.phoneNumber;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get name() {
    return this._name;
  }

  get emailId() {
    return this._emailId;
  }

  get profilePic() {
    return this._profilePic;
  }

  get phoneNumber() {
    return this._phoneNumber;
  }

  setName(name: string) {
    this._name = name;
  }

  setEmailId(email: string) {
    this._emailId = email;
  }

  setProfilePic(profilePic: string | null | undefined) {
    this._profilePic = profilePic;
  }

  setPhoneNumber(phoneNumber: string | null | undefined) {
    this._phoneNumber = phoneNumber;
  }

  update(data: User) {
    this._name = data.name;
    this._emailId = data.emailId;
    this._profilePic = data.profilePic;
    this._phoneNumber = data.phoneNumber;
  }

  static create(data: User) {
    return new UserModel(data);
  }
}
