import { observer } from "mobx-react-lite";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { colors } from "../../constants/colors";
import { useDashboardStore } from "../../contexts/DashboardStoreContext";

const CompareStockGraph = (): React.ReactElement => {
  const dashboardStore = useDashboardStore();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={dashboardStore.dataPoints}
        margin={{
          top: 6,
          right: 24,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={colors["gray-300"]} />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={{ stroke: colors["gray-400"] }}
        />
        <YAxis
          label={{
            value: `No of Laptops`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
          tickLine={false}
          axisLine={{ stroke: colors["gray-400"] }}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="available"
          name="Available Laptops"
          stroke={colors["success-600"]}
          strokeWidth={1.5}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="rented"
          name="Rented Laptops"
          stroke={colors["error-600"]}
          strokeWidth={1.5}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default observer(CompareStockGraph);
