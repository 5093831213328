import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import TicketTabs from "../../components/TicketTabs/TicketTabs";

const TicketsController = (): React.ReactElement => {
  const renderHeader = (): React.ReactElement => (
    <div className="flex items-center w-full">
      <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>Tickets</p>
    </div>
  );

  return (
    <DefaultPageBodyLayout>
      {renderHeader()}

      <div className="flex flex-col bg-gray-50 w-full h-full">
        <div className="flex flex-1 p-4 pt-0">
          <TicketTabs />
        </div>
      </div>
    </DefaultPageBodyLayout>
  );
};

export default observer(TicketsController);
