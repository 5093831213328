import { toast } from "react-toastify";

export const showSuccessToast = (message: string): void => {
  toast(message, {
    type: "success",
  });
};

export const showFailureToast = (message: string): void => {
  toast(message, {
    type: "error",
  });
};
