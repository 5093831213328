import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateLaptopMutationVariables = Types.Exact<{
  params: Types.UpdateLaptopParams;
}>;


export type UpdateLaptopMutation = { __typename?: 'Mutation', updateLaptop: { __typename?: 'Laptop', laptopId: string, modelNumber: string, serialNumber: string, configuration?: string | null, vendor: string, procuredDate: any, status: Types.LaptopStatus, rentedDetails?: { __typename?: 'LaptopRentedDetails', companyName: string, deliveryDate: any, returnDate?: any | null, replacementDate?: any | null, employeeId: string, employeeName: string } | null } | { __typename?: 'LaptopNotFound', message?: string | null, laptopId: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const UpdateLaptopDocument = gql`
    mutation UpdateLaptop($params: UpdateLaptopParams!) {
  updateLaptop(params: $params) {
    ... on Laptop {
      laptopId
      modelNumber
      serialNumber
      configuration
      vendor
      procuredDate
      status
      rentedDetails {
        companyName
        deliveryDate
        returnDate
        replacementDate
        employeeId
        employeeName
      }
    }
    ... on UserIsNotAdmin {
      message
    }
    ... on LaptopNotFound {
      message
      laptopId
    }
  }
}
    `;
export type UpdateLaptopMutationFn = Apollo.MutationFunction<UpdateLaptopMutation, UpdateLaptopMutationVariables>;
export type UpdateLaptopMutationResult = Apollo.MutationResult<UpdateLaptopMutation>;
export type UpdateLaptopMutationOptions = Apollo.BaseMutationOptions<UpdateLaptopMutation, UpdateLaptopMutationVariables>;