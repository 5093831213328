import { MutationStatus, useMutation } from "@tanstack/react-query";

import { axiosClient } from "../../configs/axiosConfig";
import { VERIFY_OTP_ENDPOINT } from "../../constants/apiConstants";
import { VerifyOTPParams, VerifyOTPResponse } from "../../types/restAPITypes";

interface TriggerArgs {
  reqObj: VerifyOTPParams;
  onSuccess?: (data: VerifyOTPResponse) => void;
  onFailure?: (errorMessage: string) => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerArgs) => void;
  status: MutationStatus;
  isPending: boolean;
}

const verifyOTP = async (
  reqObj: VerifyOTPParams
): Promise<VerifyOTPResponse> => {
  const response = await axiosClient.post(VERIFY_OTP_ENDPOINT, reqObj, {
    withCredentials: true,
  });
  return response.data;
};

const useVerifyOTP = (): ReturnType => {
  const { mutate, status, isPending } = useMutation({
    mutationFn: verifyOTP,
  });

  const triggerAPI = (args: TriggerArgs): void => {
    mutate(args.reqObj, {
      onSuccess(data) {
        args.onSuccess?.(data);
      },
      onError(error) {
        args.onFailure?.(error.message ?? "Something went wrong");
      },
    });
  };

  return { triggerAPI, status, isPending };
};

export default useVerifyOTP;
