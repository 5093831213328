import { observer } from "mobx-react-lite";

import { useUpdateUser } from "../../apis/UpdateUser/useUpdateUser";
import UpdateUser from "../../components/UpdateUser/UpdateUser";
import { useUsersStore } from "../../contexts/UsersStoreContext";
import { showSuccessToast } from "../../utils/toastUtils";

import { useUpdateUserState } from "./useUpdateUserState";

interface Props {
  userId: string;
  onClose: () => void;
}

const UpdateUserController = (props: Props): React.ReactElement => {
  const { userId, onClose } = props;

  const userModel = useUsersStore().getUser(userId);

  const state = useUpdateUserState(userModel);

  const updateUserAPI = useUpdateUser();

  const onUpdateUser = (): void => {
    const { phoneNumber, emailId, name, profilePic } = state;

    const isValid = emailId && name;

    if (!isValid) return;

    updateUserAPI.triggerAPI({
      reqObj: {
        userId: userModel.userId,
        email: emailId,
        name,
        phoneNumber,
        profilePic,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("User updated");
      },
    });
  };

  return (
    <UpdateUser
      emailId={state.emailId}
      phoneNumber={state.phoneNumber}
      name={state.name}
      profilePic={state.profilePic}
      setEmailId={state.setEmailId}
      setPhoneNumber={state.setPhoneNumber}
      setName={state.setName}
      setProfilePic={state.setProfilePic}
      onClose={onClose}
      onUpdateUser={onUpdateUser}
      isPending={updateUserAPI.isLoading}
    />
  );
};

export default observer(UpdateUserController);
