import { observer } from "mobx-react-lite";

import { useRentLaptop } from "../../apis/RentLaptop/useRentLaptop";
import { useUpdateLaptop } from "../../apis/UpdateLaptop/useUpdateLaptop";
import UpdateLaptop from "../../components/UpdateLaptop/UpdateLaptop";
import { useLaptopsStore } from "../../contexts/LaptopsStoreContext";
import { LaptopStatus } from "../../graphql/graphqlTypes";
import { showSuccessToast } from "../../utils/toastUtils";

import { useUpdateLaptopState } from "./useUpdateLaptopState";

interface Props {
  laptopId: string;
  onClose: () => void;
  updateRefreshId: () => void;
}

const UpdateLaptopController = (props: Props): React.ReactElement => {
  const { laptopId, onClose, updateRefreshId } = props;

  const laptopModel = useLaptopsStore().getLaptop(laptopId);

  const isAlreadyRented = laptopModel.status !== LaptopStatus.Available;

  const state = useUpdateLaptopState(laptopModel);

  const updateLaptopAPI = useUpdateLaptop();
  const rentLaptopAPI = useRentLaptop();

  const onUpdateLaptop = (): void => {
    updateLaptopAPI.triggerAPI({
      reqObj: {
        configuration: state.configuration ?? "",
        laptopId,
        vendor: state.vendor ?? "",
      },
      onSuccess: () => {
        updateRefreshId();
        onClose();
        showSuccessToast("Laptop Updated");
      },
    });

    if (!state.companyId) return;

    if (!state.employeeId?.trim() || !state.employeeName?.trim()) return;

    rentLaptopAPI.triggerAPI({
      reqObj: {
        laptopId,
        companyId: state.companyId,
        deliveryDate: state.deliveryDate?.toString(),
        employeeId: state.employeeId,
        employeeName: state.employeeName,
        returnDate: state.returnDate?.toString(),
      },
    });
  };

  return (
    <UpdateLaptop
      companyId={state.companyId}
      setCompanyId={state.setCompanyId}
      deliveryDate={state.deliveryDate}
      returnDate={state.returnDate}
      employeeId={state.employeeId}
      employeeName={state.employeeName}
      configuration={state.configuration}
      vendor={state.vendor}
      setDeliveryDate={state.setDeliveryDate}
      setReturnDate={state.setReturnDate}
      setEmployeeId={state.setEmployeeId}
      setEmployeeName={state.setEmployeeName}
      setConfiguration={state.setConfiguration}
      setVendor={state.setVendor}
      onClose={onClose}
      onUpdateLaptop={onUpdateLaptop}
      isAlreadyRented={isAlreadyRented}
      procuredDate={laptopModel.procuredDate ?? undefined}
    />
  );
};

export default observer(UpdateLaptopController);
