import { useLazyQuery } from "@apollo/client";

import { Laptop } from "../../types/inventoryTypes";
import { convertApolloError } from "../../utils/apiUtils";

import {
  GetAccountLaptopsQuery as Query,
  GetAccountLaptopsDocument as QueryDocument,
  GetAccountLaptopsQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  reqObj: QueryVariables["params"];
  onSuccess?: () => void;
  onFailure?: () => void;
}

export interface APIResponse {
  laptops: Laptop[];
  totalCount: number;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => Promise<APIResponse>;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetAccountLaptops = (): ReturnType => {
  const [getAccountLaptops, { loading, error }] = useLazyQuery<
    Query,
    QueryVariables
  >(QueryDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = async (args: TriggerAPIArgs): Promise<APIResponse> => {
    const response = await getAccountLaptops({
      variables: {
        params: args.reqObj,
      },
      fetchPolicy: "network-only",
      onError: args.onFailure,
    });
    if (!response || !response.data)
      return {
        laptops: [],
        totalCount: 0,
      };

    return handleResponse({
      data: response.data,
      onSuccess: args.onSuccess,
      onFailure: args.onFailure,
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
