import { useLazyQuery } from "@apollo/client";

import { Ticket } from "../../graphql/graphqlTypes";
import { convertApolloError } from "../../utils/apiUtils";

import {
  GetTicketsInAccountQuery as Query,
  GetTicketsInAccountDocument as QueryDocument,
  GetTicketsInAccountQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  reqObj: QueryVariables["params"];
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => Promise<Ticket[]>;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetTicketsInAccount = (): ReturnType => {
  const [getTicketsInAccount, { loading, error }] = useLazyQuery<
    Query,
    QueryVariables
  >(QueryDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = async (args: TriggerAPIArgs): Promise<Ticket[]> => {
    const response = await getTicketsInAccount({
      variables: {
        params: args.reqObj,
      },
      onError: args.onFailure,
      fetchPolicy: "network-only",
    });

    if (!response || !response.data) return [];

    return handleResponse({
      data: response.data,
      onSuccess: args.onSuccess,
      onFailure: args.onFailure,
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
