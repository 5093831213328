import { observer } from "mobx-react-lite";

import CompareStockGraph from "../CompareStockGraph/CompareStockGraph";
import LaptopStats from "../LaptopStats/LaptopStats";
import TotalStockGraph from "../TotalStockGraph/TotalStockGraph";

const AdminDashboard = (): React.ReactElement => {
  return (
    <>
      <LaptopStats />
      <div className="flex flex-1 gap-4 m-4 mt-0 max-h-[360px]">
        <div className="flex-1 bg-white shadow p-4 rounded-xl h-full">
          <TotalStockGraph />
        </div>
        <div className="flex-1 bg-white shadow p-4 rounded-xl h-full">
          <CompareStockGraph />
        </div>
      </div>
    </>
  );
};

export default observer(AdminDashboard);
