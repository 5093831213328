import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Heading,
  Text,
} from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";

import { useMakeLaptopAvailable } from "../../apis/MakeLaptopAvailable/useMakeLaptopAvailable";

interface Props {
  activeLaptopId: string | null;
  isOpen: boolean;
  onClose: () => void;
  updateRefreshId: () => void;
}

const MakeLaptopAvailableModalController = (
  props: Props
): React.ReactElement => {
  const { activeLaptopId, isOpen, onClose, updateRefreshId } = props;

  const makeLaptopAvailableAPI = useMakeLaptopAvailable();

  if (!activeLaptopId) return <></>;

  const onUpdateLaptop = () => {
    makeLaptopAvailableAPI.triggerAPI({
      reqObj: { laptopId: activeLaptopId },
      onSuccess: updateRefreshId,
    });
  };

  return (
    <DialogContainer onDismiss={onClose}>
      {isOpen && (
        <Dialog size="M">
          <Heading>Update Laptop Status</Heading>

          <Divider />
          <Content>
            <Text>
              {"Are you sure you want to make this laptop available?"}
            </Text>
          </Content>

          <ButtonGroup>
            <Button variant="secondary" onPress={onClose}>
              Cancel
            </Button>
            <Button
              variant="accent"
              onPress={onUpdateLaptop}
              isPending={makeLaptopAvailableAPI.isLoading}
            >
              {"Make Available"}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(MakeLaptopAvailableModalController);
