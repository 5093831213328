import { useState } from "react";

import { UserModel } from "../../models/UserModel";

export const useUpdateUserState = (userModel: UserModel) => {
  const [emailId, setEmailId] = useState<string | null>(
    userModel.emailId || null
  );
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    userModel.phoneNumber || null
  );
  const [name, setName] = useState<string | null>(userModel.name || null);
  const [profilePic, setProfilePic] = useState<string | null>(
    userModel.profilePic || null
  );

  return {
    emailId,
    phoneNumber,
    name,
    profilePic,
    setEmailId,
    setPhoneNumber,
    setName,
    setProfilePic,
  };
};
