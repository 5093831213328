import { observer } from "mobx-react-lite";
import { createContext, PropsWithChildren, useContext, useRef } from "react";

import { CompaniesStore } from "../stores/CompaniesStore";

export const CompaniesStoreContext = createContext<CompaniesStore | null>(null);

const CompaniesStoreProvider = (props: PropsWithChildren) => {
  const companiesStoreRef = useRef(CompaniesStore.create());

  return (
    <CompaniesStoreContext.Provider value={companiesStoreRef.current}>
      {props.children}
    </CompaniesStoreContext.Provider>
  );
};

export const useCompaniesStore = (): CompaniesStore => {
  const companiesStore = useContext(CompaniesStoreContext);

  if (!companiesStore) {
    throw new Error("Expected to be wrapped in Companies Store Provider");
  }

  return companiesStore;
};

export default observer(CompaniesStoreProvider);
