import { SearchField } from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import debounce from "lodash.debounce";
import { useGetLaptopStats } from "../../apis/GetLaptopStats/useGetLaptopStats";
import AddLaptopModal from "../../components/AddLaptopModal/AddLaptopModal";
import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import LaptopsTable from "../../components/LaptopsTable/LaptopsTable";

const InventoryController = (): React.ReactElement => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [refreshId, setRefreshId] = useState("");

  const updateRefreshId = (): void => setRefreshId(Date.now().toString());

  const getLaptopStatsAPI = useGetLaptopStats();

  const getLaptopStats = (): void => {
    getLaptopStatsAPI.triggerAPI({});
  };

  useEffect(() => {
    getLaptopStats();
  }, []);

  const debouncedGetAccountLaptopsRef = useRef(
    debounce(setDebouncedSearchQuery, 500)
  );

  const onChangeSearchQuery = (value: string): void => {
    setSearchQuery(value);

    debouncedGetAccountLaptopsRef.current(value);
  };

  const renderHeader = (): React.ReactElement => (
    <div className="flex items-center gap-4 w-full">
      <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>
        Inventory
      </p>

      <SearchField
        placeholder="Search by serial number"
        value={searchQuery}
        onChange={onChangeSearchQuery}
        width={250}
      />

      <AddLaptopModal updateRefreshId={updateRefreshId} />
    </div>
  );

  return (
    <DefaultPageBodyLayout>
      {renderHeader()}

      <div className="flex flex-col bg-gray-50 w-full h-full">
        <div className="flex-1 p-4">
          <LaptopsTable
            key={`${debouncedSearchQuery}-${refreshId}`}
            searchText={debouncedSearchQuery}
            updateRefreshId={updateRefreshId}
          />
        </div>
      </div>
    </DefaultPageBodyLayout>
  );
};

export default observer(InventoryController);
