import { useCompaniesStore } from "../../contexts/CompaniesStoreContext";
import { Company } from "../../graphql/graphqlTypes";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { GetCompaniesInAccountQuery as Query } from "./query.generatedTypes";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => Company[];
}

export const useResponseHandler = (): ReturnType => {
  const companiesStore = useCompaniesStore();

  const handleResponse = (args: ArgsType): Company[] => {
    const response = args.data.getCompaniesInAccount;

    switch (response.__typename) {
      case "GetCompaniesInAccountResult": {
        const companiesList: Company[] = [];
        response.companies?.forEach((company) => {
          if (company) {
            companiesStore.createOrUpdateCompany(company);

            companiesList.push(company);
          }
        });
        args.onSuccess?.();
        return companiesList;
      }
      case "UserIsNotAdmin":
      case "InvalidLimit":
      case "InvalidOffset":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
    return [];
  };

  return {
    handleResponse,
  };
};
