import { ActionButton, Icon } from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";

import { useGetAccountLaptops } from "../../apis/GetAccountLaptops/useGetAccountLaptops";
import { useGetCompany } from "../../apis/GetCompany/useGetCompany";
import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import LaptopsTable from "../../components/LaptopsTable/LaptopsTable";
import Loader from "../../components/Loader/Loader";
import UpdateCompanyModal from "../../components/UpdateCompanyModal/UpdateCompanyModal";
import { colors } from "../../constants/colors";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { useCompaniesStore } from "../../contexts/CompaniesStoreContext";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { useRedirection } from "../../hooks/useRedirection";

interface Props {
  companyId: string;
}

const CompanyDetailedViewController = (props: Props): React.ReactElement => {
  const { companyId } = props;

  const [searchQuery, setSearchQuery] = useState("");
  const [refreshId, setRefreshId] = useState("");

  const updateRefreshId = (): void => setRefreshId(Date.now().toString());

  const companiesStore = useCompaniesStore();

  const getAccountLaptopsAPI = useGetAccountLaptops();
  const getCompanyAPI = useGetCompany();

  const { isAdmin } = useUserAppConfigStore();

  const getCompany = (): void => {
    getCompanyAPI.triggerAPI({
      reqObj: {
        companyId,
      },
    });
  };

  const getAccountLaptops = (): void => {
    getAccountLaptopsAPI.triggerAPI({
      reqObj: {
        companyId,
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset: 0,
        },
        searchQuery,
      },
    });
  };

  useEffect(() => {
    getCompany();
    getAccountLaptops();
  }, []);

  const hasCompanyData = companiesStore.hasCompany(companyId);

  const { goToCompaniesList, goBack } = useRedirection();

  const handleOnBack = () => {
    if (window.history.length > 1) {
      goBack();
    } else {
      goToCompaniesList();
    }
  };

  const renderHeader = (): React.ReactElement => {
    const company = companiesStore.getCompany(companyId);
    return (
      <div className="flex items-center gap-2">
        {isAdmin ? (
          <ActionButton onPress={handleOnBack} isQuiet>
            <Icon width={16} height={16}>
              <FaChevronLeft />
            </Icon>
          </ActionButton>
        ) : null}
        <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>
          {company.name}
        </p>

        {isAdmin ? <UpdateCompanyModal defaultCompany={company} /> : null}
      </div>
    );
  };

  const renderSuccessView = (): React.ReactElement => (
    <DefaultPageBodyLayout>
      {renderHeader()}

      <div className="flex flex-col gap-4 bg-gray-50 p-4 w-full h-full">
        <div className="flex flex-1 gap-4 min-h-0">
          <LaptopsTable
            key={refreshId}
            companyId={companyId}
            updateRefreshId={updateRefreshId}
          />
        </div>
      </div>
    </DefaultPageBodyLayout>
  );

  return hasCompanyData ? renderSuccessView() : <Loader />;
};

export default observer(CompanyDetailedViewController);
