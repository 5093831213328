import { ActionButton, Button, Icon, Text } from "@adobe/react-spectrum";
import cn from "classnames";
import { FaArrowLeft } from "react-icons/fa6";
import { GoDash } from "react-icons/go";
import OtpInput from "react-otp-input";

import { SendOTPConfig } from "../../controllers/LoginController/LoginController";

interface Props {
  sendOTPConfig: SendOTPConfig;
  otp: string;
  setOtp: (otp: string) => void;
  onBackStep: () => void;
  onVerifyOTP: () => void;
  isPending: boolean;
}

const LoginOtpStep = (props: Props): React.ReactElement => {
  const { sendOTPConfig, otp, setOtp, onBackStep, onVerifyOTP, isPending } =
    props;

  return (
    <div className="flex flex-col items-center gap-4 bg-white shadow-xl p-8 rounded-2xl w-[400px]">
      <ActionButton isQuiet onPress={onBackStep} alignSelf={"self-start"}>
        <Icon>
          <FaArrowLeft />
        </Icon>
        <Text>Back</Text>
      </ActionButton>

      <p className={cn("font-bold text-2xl", "text-gray-900")}>
        Verify with OTP
      </p>
      <p className={cn("font-regular text-base", "text-gray-600")}>
        Please enter the OTP sent to{" "}
        <span className="font-semibold">{sendOTPConfig.email}</span>
        {sendOTPConfig.phoneNumber ? (
          <>
            {" and "}
            <span className="font-semibold">{sendOTPConfig.phoneNumber}</span>
          </>
        ) : null}
      </p>

      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={(index) => index === 2 && <GoDash size={20} />}
        renderInput={(inputProps, index) => (
          <input
            {...inputProps}
            className="border-gray-300 focus:border-2 m-1 p-1 border focus:border-blue-600 rounded-xl focus:ring ring-blue-100 font-medium text-center text-sm align-middle outline-none"
            style={{
              width: "44px",
              height: "44px",
              appearance: "none",
            }}
            maxLength={1}
            autoFocus={index === 0}
            onKeyDown={(e) => e.key === "Enter" && onVerifyOTP()}
          />
        )}
      />

      <Button
        variant="accent"
        width={"100%"}
        onPress={onVerifyOTP}
        isPending={isPending}
      >
        Verify & Login
      </Button>

      <p className={cn("font-regular text-sm", "text-gray-600")}>
        © 2024 Saffron Technologies Pvt Ltd
      </p>
    </div>
  );
};

export default LoginOtpStep;
