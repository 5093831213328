import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateTicketStatusMutationVariables = Types.Exact<{
  params: Types.UpdateTicketStatusParams;
}>;


export type UpdateTicketStatusMutation = { __typename?: 'Mutation', updateTicketStatus: { __typename?: 'NewLaptopShouldBeInAvailableState' } | { __typename?: 'ReplacementDetailsAreRequired', message?: string | null } | { __typename?: 'ReturnDateIsRequired', message?: string | null } | { __typename?: 'TicketNotFound', message?: string | null, ticketId: string } | { __typename?: 'UpdateTicketStatusResult', ticketId: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const UpdateTicketStatusDocument = gql`
    mutation UpdateTicketStatus($params: UpdateTicketStatusParams!) {
  updateTicketStatus(params: $params) {
    ... on UpdateTicketStatusResult {
      ticketId
    }
    ... on UserIsNotAdmin {
      message
    }
    ... on TicketNotFound {
      message
      ticketId
    }
    ... on ReplacementDetailsAreRequired {
      message
    }
    ... on ReturnDateIsRequired {
      message
    }
  }
}
    `;
export type UpdateTicketStatusMutationFn = Apollo.MutationFunction<UpdateTicketStatusMutation, UpdateTicketStatusMutationVariables>;
export type UpdateTicketStatusMutationResult = Apollo.MutationResult<UpdateTicketStatusMutation>;
export type UpdateTicketStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTicketStatusMutation, UpdateTicketStatusMutationVariables>;