import { observer } from "mobx-react-lite";

import CompanyDetailedViewController from "../../controllers/CompanyDetailedViewController/CompanyDetailedViewController";
import { useEntityIdsFromUrl } from "../../utils/routeUtils";

const CompanyDetailedViewRoute = (): React.ReactElement => {
  const { companyId } = useEntityIdsFromUrl();
  if (!companyId) return <></>;

  return <CompanyDetailedViewController companyId={companyId} />;
};

export default observer(CompanyDetailedViewRoute);
