import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetLaptopStatsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLaptopStatsQuery = { __typename?: 'Query', getLaptopStats: { __typename?: 'LaptopStatsDetails', totalItems: { __typename?: 'LaptopStats', count: number, changesFromLastMonth: number }, availableItems: { __typename?: 'LaptopStats', count: number, changesFromLastMonth: number }, rentedItems: { __typename?: 'LaptopStats', count: number, changesFromLastMonth: number }, companiesCount: { __typename?: 'LaptopStats', count: number, changesFromLastMonth: number } } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const GetLaptopStatsDocument = gql`
    query GetLaptopStats {
  getLaptopStats {
    ... on UserIsNotAdmin {
      message
    }
    ... on LaptopStatsDetails {
      totalItems {
        count
        changesFromLastMonth
      }
      availableItems {
        count
        changesFromLastMonth
      }
      rentedItems {
        count
        changesFromLastMonth
      }
      companiesCount {
        count
        changesFromLastMonth
      }
    }
  }
}
    `;
export type GetLaptopStatsQueryResult = Apollo.QueryResult<GetLaptopStatsQuery, GetLaptopStatsQueryVariables>;