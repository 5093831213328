import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { LOGIN_ROUTE } from "../../constants/navigationConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { isUserLoggedIn } from "../../utils/authUtils";

import Navbar from "../Navbar/Navbar";

const ProtectedRoute = (): React.ReactElement => {
  const { user } = useUserAppConfigStore();

  return isUserLoggedIn() ? (
    <div className={cn("flex w-full h-full")}>
      {user ? <Navbar /> : null}

      <div className="flex-1 min-w-0">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={LOGIN_ROUTE} replace />
  );
};

export default observer(ProtectedRoute);
