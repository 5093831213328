import { makeAutoObservable } from "mobx";

import { DataPoint } from "../types/coreTypes";

export class DashboardStore {
  private _totalLaptops: number;
  private _availableLaptops: number;
  private _rentedLaptops: number;
  private _totalCompanies: number;

  private _totalTickets: number;
  private _pendingTickets: number;

  private _graphDataPoints: DataPoint[];

  private constructor() {
    this._totalLaptops = 0;
    this._availableLaptops = 0;
    this._rentedLaptops = 0;
    this._totalCompanies = 0;
    this._totalTickets = 0;
    this._pendingTickets = 0;

    this._graphDataPoints = [];

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get totalLaptops() {
    return this._totalLaptops;
  }

  setTotalLaptops(totalLaptops: number) {
    this._totalLaptops = totalLaptops;
  }

  get availableLaptops() {
    return this._availableLaptops;
  }

  setAvailableLaptops(availableLaptops: number) {
    this._availableLaptops = availableLaptops;
  }

  get rentedLaptops() {
    return this._rentedLaptops;
  }

  setRentedLaptops(rentedLaptops: number) {
    this._rentedLaptops = rentedLaptops;
  }

  get totalCompanies() {
    return this._totalCompanies;
  }

  setTotalCompanies(totalCompanies: number) {
    this._totalCompanies = totalCompanies;
  }

  get dataPoints() {
    return this._graphDataPoints;
  }

  setDataPoints(dataPoints: DataPoint[]) {
    this._graphDataPoints = dataPoints;
  }

  get totalTickets() {
    return this._totalTickets;
  }

  setTotalTickets(count: number) {
    this._totalTickets = count;
  }

  get pendingTickets() {
    return this._pendingTickets;
  }

  setPendingTickets(count: number) {
    this._pendingTickets = count;
  }

  static create(): DashboardStore {
    return new DashboardStore();
  }
}
