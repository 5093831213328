import {
  ActionButton,
  Dialog,
  DialogTrigger,
  Icon,
} from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { MdOutlineSettings } from "react-icons/md";

import UpdateCompanyController from "../../controllers/UpdateCompanyController/UpdateCompanyController";
import { CompanyModel } from "../../models/CompanyModel";

interface Props {
  defaultCompany: CompanyModel;
}

const UpdateCompanyModal = (props: Props): React.ReactElement => {
  const { defaultCompany } = props;

  const renderUpdateButton = (): React.ReactElement => (
    <ActionButton>
      <Icon width={24} height={24}>
        <MdOutlineSettings />
      </Icon>
    </ActionButton>
  );

  return (
    <DialogTrigger>
      {renderUpdateButton()}
      {(close) => (
        <Dialog size="M">
          <UpdateCompanyController
            defaultCompany={defaultCompany}
            onClose={close}
          />
        </Dialog>
      )}
    </DialogTrigger>
  );
};

export default observer(UpdateCompanyModal);
