import { makeAutoObservable } from "mobx";

import { UserModel } from "../models/UserModel";

export class UserAppConfigStore {
  private _user: UserModel | null;
  private _isAdmin: boolean;
  private _companyId: string | null;

  constructor() {
    this._user = null;
    this._isAdmin = false;
    this._companyId = null;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get user(): UserModel | null {
    return this._user;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  get companyId(): string | null {
    return this._companyId;
  }

  setUser(user: UserModel) {
    this._user = user;
  }

  setIsAdmin(isAdmin: boolean) {
    this._isAdmin = isAdmin;
  }

  setCompanyId(companyId: string | null) {
    this._companyId = companyId;
  }

  static create(): UserAppConfigStore {
    return new UserAppConfigStore();
  }
}
