import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRentedLaptopCompanyDetailsMutationVariables = Types.Exact<{
  params: Types.UpdateRentedLaptopCompanyDetailsParams;
}>;


export type UpdateRentedLaptopCompanyDetailsMutation = { __typename?: 'Mutation', updateRentedLaptopCompanyDetails: { __typename?: 'CompanyNotFound', message?: string | null, companyId: string } | { __typename?: 'LaptopNotFound', message?: string | null, laptopId: string } | { __typename?: 'UpdateRentedLaptopCompanyDetailsResult', laptopId: string, employeeName: string, employeeId: string, companyId: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const UpdateRentedLaptopCompanyDetailsDocument = gql`
    mutation UpdateRentedLaptopCompanyDetails($params: UpdateRentedLaptopCompanyDetailsParams!) {
  updateRentedLaptopCompanyDetails(params: $params) {
    ... on UpdateRentedLaptopCompanyDetailsResult {
      laptopId
      employeeName
      employeeId
      companyId
    }
    ... on UserIsNotAdmin {
      message
    }
    ... on LaptopNotFound {
      message
      laptopId
    }
    ... on CompanyNotFound {
      message
      companyId
    }
  }
}
    `;
export type UpdateRentedLaptopCompanyDetailsMutationFn = Apollo.MutationFunction<UpdateRentedLaptopCompanyDetailsMutation, UpdateRentedLaptopCompanyDetailsMutationVariables>;
export type UpdateRentedLaptopCompanyDetailsMutationResult = Apollo.MutationResult<UpdateRentedLaptopCompanyDetailsMutation>;
export type UpdateRentedLaptopCompanyDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateRentedLaptopCompanyDetailsMutation, UpdateRentedLaptopCompanyDetailsMutationVariables>;