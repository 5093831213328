import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetAccountUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAccountUsersQuery = { __typename?: 'Query', getAccountUsers: { __typename?: 'GetAccountUsersResult', users?: Array<{ __typename?: 'User', userId: string, name: string, emailId: string, profilePic?: string | null, phoneNumber?: string | null } | null> | null } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const GetAccountUsersDocument = gql`
    query GetAccountUsers {
  getAccountUsers {
    ... on GetAccountUsersResult {
      users {
        userId
        name
        emailId
        profilePic
        phoneNumber
      }
    }
    ... on UserIsNotAdmin {
      message
    }
  }
}
    `;
export type GetAccountUsersQueryResult = Apollo.QueryResult<GetAccountUsersQuery, GetAccountUsersQueryVariables>;