import { observer } from "mobx-react-lite";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { colors } from "../../constants/colors";
import { useDashboardStore } from "../../contexts/DashboardStoreContext";
import { formatToIndianNumber } from "../../utils/numberUtils";

const TotalStockGraph = (): React.ReactElement => {
  const dashboardStore = useDashboardStore();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={dashboardStore.dataPoints}
        margin={{
          top: 6,
          right: 24,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          stroke={colors["gray-300"]}
        />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={{ stroke: colors["gray-400"] }}
        />
        <YAxis
          tickLine={false}
          axisLine={{ stroke: colors["gray-400"] }}
          label={{
            value: `No of Laptops`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
        />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="total"
          name={"Total Laptops"}
          fill={colors["blue-600"]}
          radius={[8, 8, 0, 0]}
          className="cursor-pointer"
          barSize={40}
        >
          <LabelList
            dataKey="count"
            position={"top"}
            offset={16}
            className="text-md-bold"
            fill={colors["gray-900"]}
            strokeWidth={2}
            fontWeight={600}
            formatter={(value: number) => formatToIndianNumber(value)}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default observer(TotalStockGraph);
