interface Props {
  className?: string;
}

export const CompanyMiniLogo = (props: Props) => (
  <img
    src="./app-logo-mini.jpg"
    alt="saffron technologies"
    className={props.className}
  />
);
