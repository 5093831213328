import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { useGetCompanyDashboardData } from "../../apis/GetCompanyDashboardData/useGetCompanyDashboardData";
import { useGetGraphData } from "../../apis/GetGraphData/useGetGraphData";
import { useGetLaptopStats } from "../../apis/GetLaptopStats/useGetLaptopStats";
import AdminDashboard from "../../components/AdminDashboard/AdminDashboard";
import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import CompanyDashboard from "../../components/CompanyDashboard/CompanyDashboard";
import { useGetCompany } from "../../apis/GetCompany/useGetCompany";

const DashboardController = (): React.ReactElement => {
  const getLaptopStatsAPI = useGetLaptopStats();
  const getGraphsAPI = useGetGraphData();
  const getCompanyAPI = useGetCompany();

  const getCompanyDashboardDataAPI = useGetCompanyDashboardData();

  const { isAdmin, companyId } = useUserAppConfigStore();

  const GetLaptopStats = (): void => {
    getLaptopStatsAPI.triggerAPI({});
  };

  const getCompanyDashboardData = (): void => {
    getCompanyDashboardDataAPI.triggerAPI({});
  };

  const getGraphs = (): void => {
    getGraphsAPI.triggerAPI({});
  };

  const getCompany = (): void => {
    if (companyId)
      getCompanyAPI.triggerAPI({
        reqObj: {
          companyId,
        },
      });
  };

  useEffect(() => {
    if (isAdmin) {
      GetLaptopStats();
      getGraphs();
    } else {
      getCompanyDashboardData();
      getCompany();
    }
  }, []);

  const renderHeader = (): React.ReactElement => (
    <div className="flex items-center w-full">
      <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>
        Dashboard
      </p>
    </div>
  );

  return (
    <DefaultPageBodyLayout>
      {renderHeader()}

      <div className="flex flex-col bg-gray-50 w-full h-full">
        {isAdmin ? <AdminDashboard /> : <CompanyDashboard />}
      </div>
    </DefaultPageBodyLayout>
  );
};

export default observer(DashboardController);
