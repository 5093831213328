import cn from "classnames";
import { observer } from "mobx-react-lite";
import { MdBusinessCenter } from "react-icons/md";

import { colors } from "../../constants/colors";
import { useRedirection } from "../../hooks/useRedirection";
import { CompanyModel } from "../../models/CompanyModel";

import Avatar from "../Avatar/Avatar";

interface Props {
  companies: CompanyModel[];
}

interface CompanyItemProps {
  company: CompanyModel;
  onClick: () => void;
}

const CompaniesList = (props: Props): React.ReactElement => {
  const { companies } = props;

  const { goToCompanyDetailedView } = useRedirection();

  return (
    <ul className={cn("flex flex-col gap-2 bg-gray-50 p-4 h-full")}>
      {companies.map((company) => (
        <CompanyItem
          company={company}
          key={company.companyId}
          onClick={() => goToCompanyDetailedView(company.companyId)}
        />
      ))}
    </ul>
  );
};

const CompanyItem = (props: CompanyItemProps): React.ReactElement => {
  const { company, onClick } = props;

  return (
    <div
      className={cn(
        "flex gap-2 bg-white shadow hover:shadow-md p-4 rounded-xl transition-shadow hover:cursor-pointer"
      )}
      onClick={onClick}
    >
      {company.logoUrl ? (
        <img
          src={company.logoUrl}
          alt={company.name}
          className="border rounded-lg w-[48px] h-[48px]"
        />
      ) : (
        <MdBusinessCenter size={48} fill={colors["gray-600"]} />
      )}
      <div className="flex flex-1 justify-between items-center gap-4">
        <div className="flex flex-col gap-1">
          <p className="text-base text-gray-800">{company.name}</p>
          {company.address ? (
            <p className="text-gray-500 text-sm">{company.address}</p>
          ) : null}
        </div>

        {company.manager ? (
          <Avatar
            name={company.manager.name}
            imageUrl={company.manager.profilePic ?? undefined}
            containerClassName="shrink-0"
          />
        ) : null}
      </div>
    </div>
  );
};

export default observer(CompaniesList);
