import { observer } from "mobx-react-lite";

import { useUpdateCompany } from "../../apis/UpdateCompany/useUpdateCompany";
import { useUpdateUser } from "../../apis/UpdateUser/useUpdateUser";
import CreateCompany from "../../components/CreateCompany/CreateCompany";
import { CompanyModel } from "../../models/CompanyModel";
import { showSuccessToast } from "../../utils/toastUtils";

import { useCompanyState } from "./useCompanyState";

interface Props {
  onClose: () => void;
  defaultCompany: CompanyModel;
}

const UpdateCompanyController = (props: Props): React.ReactElement => {
  const { defaultCompany, onClose } = props;

  const state = useCompanyState(defaultCompany);

  const updateCompanyAPI = useUpdateCompany();
  const updateUserAPI = useUpdateUser();

  const updateCompany = (): void => {
    const {
      companyName,
      companyLogo,
      managerName,
      employeeEmail,
      phoneNumber,
      address,
    } = state;

    const areDetailsValid = companyName && managerName && employeeEmail;

    if (!areDetailsValid) return;

    updateUserAPI.triggerAPI({
      reqObj: {
        userId: defaultCompany.manager.userId,
        email: employeeEmail,
        name: managerName,
        phoneNumber,
      },
    });

    updateCompanyAPI.triggerAPI({
      reqObj: {
        companyId: defaultCompany.companyId,
        companyName,
        companyLogo,
        address,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("Company Details Updated");
      },
    });
  };

  return (
    <CreateCompany
      isEdit
      companyName={state.companyName}
      address={state.address}
      managerName={state.managerName}
      employeeEmail={state.employeeEmail}
      phoneNumber={state.phoneNumber}
      companyLogo={state.companyLogo}
      setCompanyName={state.setCompanyName}
      setAddress={state.setAddress}
      setManagerName={state.setManagerName}
      setEmployeeEmail={state.setEmployeeEmail}
      setPhoneNumber={state.setPhoneNumber}
      setCompanyLogo={state.setCompanyLogo}
      onClose={onClose}
      onCreateCompany={updateCompany}
    />
  );
};

export default observer(UpdateCompanyController);
