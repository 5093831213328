import { observer } from "mobx-react-lite";

import { useAddCompany } from "../../apis/AddCompany/useAddCompany";
import CreateCompany from "../../components/CreateCompany/CreateCompany";
import { showSuccessToast } from "../../utils/toastUtils";

import { useCompanyState } from "./useCompanyState";

interface Props {
  onClose: () => void;
}

const CreateOrUpdateEmployeeController = (props: Props): React.ReactElement => {
  const { onClose } = props;

  const state = useCompanyState();

  const addCompanyAPI = useAddCompany();

  const addCompany = (): void => {
    const {
      companyName,
      companyLogo,
      managerName,
      employeeEmail,
      phoneNumber,
      address,
    } = state;

    const areDetailsValid = companyName && managerName && employeeEmail;

    if (!areDetailsValid) return;

    addCompanyAPI.triggerAPI({
      reqObj: {
        companyName,
        employeeEmail,
        managerName,
        companyLogo,
        phoneNumber,
        address,
      },
      onSuccess: () => {
        onClose();
        showSuccessToast("Company Added");
      },
    });
  };

  return (
    <CreateCompany
      companyName={state.companyName}
      address={state.address}
      managerName={state.managerName}
      employeeEmail={state.employeeEmail}
      phoneNumber={state.phoneNumber}
      companyLogo={state.companyLogo}
      setCompanyName={state.setCompanyName}
      setAddress={state.setAddress}
      setManagerName={state.setManagerName}
      setEmployeeEmail={state.setEmployeeEmail}
      setPhoneNumber={state.setPhoneNumber}
      setCompanyLogo={state.setCompanyLogo}
      onClose={onClose}
      onCreateCompany={addCompany}
    />
  );
};

export default observer(CreateOrUpdateEmployeeController);
