import { useState } from "react";

import useSendOTP from "../../apis/SendOTP/useSendOTP";
import LoginEmailStep from "../../components/LoginEmailStep/LoginEmailStep";
import { showFailureToast } from "../../utils/toastUtils";
import { validateEmail } from "../../utils/validationUtils";

import { SendOTPConfig } from "../LoginController/LoginController";

interface Props {
  onNextStep: (data: SendOTPConfig) => void;
}

const LoginEmailStepController = (props: Props): React.ReactElement => {
  const { onNextStep } = props;

  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(
    null
  );

  const sendOTPAPI = useSendOTP();

  const sendOTP = async (): Promise<void> => {
    sendOTPAPI.triggerAPI({
      reqObj: {
        email,
      },
      onSuccess(data) {
        onNextStep({
          email: data.email,
          phoneNumber: data.masked_phone_number,
        });
      },
      onFailure: showFailureToast,
    });
  };

  const handleOnNextStep = (): void => {
    const validationResult = validateEmail(email, true);
    setEmailErrorMessage(validationResult.message || null);

    if (validationResult.isValid) {
      sendOTP();
    }
  };

  return (
    <LoginEmailStep
      email={email}
      setEmail={setEmail}
      emailErrorMessage={emailErrorMessage}
      onNextStep={handleOnNextStep}
      isPending={sendOTPAPI.isPending}
    />
  );
};

export default LoginEmailStepController;
