import { observer } from "mobx-react-lite";
import { createContext, PropsWithChildren, useContext, useRef } from "react";

import { UsersStore } from "../stores/UsersStore";

export const UsersStoreContext = createContext<UsersStore | null>(null);

const UsersStoreProvider = (props: PropsWithChildren) => {
  const usersStoreRef = useRef(UsersStore.create());

  return (
    <UsersStoreContext.Provider value={usersStoreRef.current}>
      {props.children}
    </UsersStoreContext.Provider>
  );
};

export const useUsersStore = (): UsersStore => {
  const usersStore = useContext(UsersStoreContext);

  if (!usersStore) {
    throw new Error("Expected to be wrapped in Users Store Provider");
  }

  return usersStore;
};

export default observer(UsersStoreProvider);
