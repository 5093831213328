import { CalendarDate } from "@internationalized/date";
import { useState } from "react";

export const useLaptopState = () => {
  const [serialNumber, setSerialNumber] = useState<string | null>(null);
  const [modelNumber, setModelNumber] = useState<string | null>(null);
  const [vendor, setVendor] = useState<string | null>(null);
  const [configuration, setConfiguration] = useState<string | null>(null);
  const [procuredDate, setProcuredDate] = useState<CalendarDate | null>(null);

  return {
    serialNumber,
    modelNumber,
    vendor,
    configuration,
    procuredDate,
    setSerialNumber,
    setModelNumber,
    setVendor,
    setConfiguration,
    setProcuredDate,
  };
};
