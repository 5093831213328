import { Navigate } from "react-router-dom";

import LoginController from "../../controllers/LoginController/LoginController";
import { isUserLoggedIn } from "../../utils/authUtils";

const LoginRoute = (): React.ReactElement =>
  isUserLoggedIn() ? (
    <Navigate to={"/"} replace />
  ) : (
    <div className="flex justify-center items-center bg-gray-100 w-full h-full">
      <LoginController />
    </div>
  );

export default LoginRoute;
