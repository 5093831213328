export const colors = {
  "base-black": "#000000",
  "base-white": "#FFFFFF",
  "gray-25": "#FCFCFD",
  "gray-50": "#F9FAFB",
  "gray-100": "#F2F4F7",
  "gray-200": "#EAECF0",
  "gray-300": "#D0D5DD",
  "gray-400": "#98A2B3",
  "gray-500": "#667085",
  "gray-600": "#475467",
  "gray-700": "#344054",
  "gray-800": "#1D2939",
  "gray-900": "#101828",
  "primary-25": "#F5FAFF",
  "primary-50": "#EFF8FF",
  "primary-100": "#D1E9FF",
  "primary-200": "#B2DDFF",
  "primary-300": "#84CAFF",
  "primary-400": "#53B1FD",
  "primary-500": "#2E90FA",
  "primary-600": "#1570EF",
  "primary-700": "#175CD3",
  "primary-800": "#1849A9",
  "primary-900": "#194185",
  "error-25": "#FFFBFA",
  "error-50": "#FEF3F2",
  "error-100": "#FEE4E2",
  "error-200": "#FECDCA",
  "error-300": "#FDA29B",
  "error-400": "#F97066",
  "error-500": "#F04438",
  "error-600": "#D92D20",
  "error-700": "#B42318",
  "error-800": "#912018",
  "error-900": "#7A271A",
  "warning-25": "#FFFCF5",
  "warning-50": "#FFFAEB",
  "warning-100": "#FEF0C7",
  "warning-200": "#FEDF89",
  "warning-300": "#FEC84B",
  "warning-400": "#FDB022",
  "warning-500": "#F79009",
  "warning-600": "#DC6803",
  "warning-700": "#B54708",
  "warning-800": "#93370D",
  "warning-900": "#7A2E0E",
  "success-25": "#F6FEF9",
  "success-50": "#ECFDF3",
  "success-100": "#D1FADF",
  "success-200": "#A6F4C5",
  "success-300": "#6CE9A6",
  "success-400": "#32D583",
  "success-500": "#12B76A",
  "success-600": "#039855",
  "success-700": "#027A48",
  "success-800": "#05603A",
  "success-900": "#054F31",
  "moss-25": "#FAFDF7",
  "moss-50": "#F5FBEE",
  "moss-100": "#E6F4D7",
  "moss-200": "#CEEAB0",
  "moss-300": "#ACDC79",
  "moss-400": "#86CB3C",
  "moss-500": "#669F2A",
  "moss-600": "#4F7A21",
  "moss-700": "#3F621A",
  "moss-800": "#335015",
  "moss-900": "#2B4212",
  "green-light-25": "#FAFEF5",
  "green-light-50": "#F3FEE7",
  "green-light-100": "#E3FBCC",
  "green-light-200": "#D0F8AB",
  "green-light-300": "#A6EF67",
  "green-light-400": "#85E13A",
  "green-light-500": "#66C61C",
  "green-light-600": "#4CA30D",
  "green-light-700": "#3B7C0F",
  "green-light-800": "#326212",
  "green-light-900": "#2B5314",
  "green-25": "#F6FEF9",
  "green-50": "#EDFCF2",
  "green-100": "#D3F8DF",
  "green-200": "#AAF0C4",
  "green-300": "#73E2A3",
  "green-400": "#3CCB7F",
  "green-500": "#16B364",
  "green-600": "#099250",
  "green-700": "#087443",
  "green-800": "#095C37",
  "green-900": "#084C2E",
  "teal-25": "#F6FEFC",
  "teal-50": "#F0FDF9",
  "teal-100": "#CCFBEF",
  "teal-200": "#99F6E0",
  "teal-300": "#5FE9D0",
  "teal-400": "#2ED3B7",
  "teal-500": "#15B79E",
  "teal-600": "#0E9384",
  "teal-700": "#107569",
  "teal-800": "#125D56",
  "teal-900": "#134E48",
  "cyan-25": "#F5FEFF",
  "cyan-50": "#ECFDFF",
  "cyan-100": "#CFF9FE",
  "cyan-200": "#A5F0FC",
  "cyan-300": "#67E3F9",
  "cyan-400": "#22CCEE",
  "cyan-500": "#06AED4",
  "cyan-600": "#088AB2",
  "cyan-700": "#0E7090",
  "cyan-800": "#155B75",
  "cyan-900": "#164C63",
  "blue-light-25": "#F5FBFF",
  "blue-light-50": "#F0F9FF",
  "blue-light-100": "#E0F2FE",
  "blue-light-200": "#B9E6FE",
  "blue-light-300": "#7CD4FD",
  "blue-light-400": "#36BFFA",
  "blue-light-500": "#0BA5EC",
  "blue-light-600": "#0086C9",
  "blue-light-700": "#026AA2",
  "blue-light-800": "#065986",
  "blue-light-900": "#0B4A6F",
  "blue-25": "#F5FAFF",
  "blue-50": "#EFF8FF",
  "blue-100": "#D1E9FF",
  "blue-200": "#B2DDFF",
  "blue-300": "#84CAFF",
  "blue-400": "#53B1FD",
  "blue-500": "#2E90FA",
  "blue-600": "#1570EF",
  "blue-700": "#175CD3",
  "blue-800": "#1849A9",
  "blue-900": "#194185",
  "blue-dark-25": "#F5F8FF",
  "blue-dark-50": "#EFF4FF",
  "blue-dark-100": "#D1E0FF",
  "blue-dark-200": "#B2CCFF",
  "blue-dark-300": "#84ADFF",
  "blue-dark-400": "#528BFF",
  "blue-dark-500": "#2970FF",
  "blue-dark-600": "#155EEF",
  "blue-dark-700": "#004EEB",
  "blue-dark-800": "#0040C1",
  "blue-dark-900": "#00359E",
  "indigo-25": "#F5F8FF",
  "indigo-50": "#EEF4FF",
  "indigo-100": "#E0EAFF",
  "indigo-200": "#C7D7FE",
  "indigo-300": "#A4BCFD",
  "indigo-400": "#8098F9",
  "indigo-500": "#6172F3",
  "indigo-600": "#444CE7",
  "indigo-700": "#3538CD",
  "indigo-800": "#2D31A6",
  "indigo-900": "#2D3282",
  "violet-25": "#FBFAFF",
  "violet-50": "#F5F3FF",
  "violet-100": "#ECE9FE",
  "violet-200": "#DDD6FE",
  "violet-300": "#C3B5FD",
  "violet-400": "#A48AFB",
  "violet-500": "#875BF7",
  "violet-600": "#7839EE",
  "violet-700": "#6927DA",
  "violet-800": "#5720B7",
  "violet-900": "#491C96",
  "purple-25": "#FAFAFF",
  "purple-50": "#F4F3FF",
  "purple-100": "#EBE9FE",
  "purple-200": "#D9D6FE",
  "purple-300": "#BDB4FE",
  "purple-400": "#9B8AFB",
  "purple-500": "#7A5AF8",
  "purple-600": "#6938EF",
  "purple-700": "#5925DC",
  "purple-800": "#4A1FB8",
  "purple-900": "#3E1C96",
  "fuchsia-25": "#FEFAFF",
  "fuchsia-50": "#FDF4FF",
  "fuchsia-100": "#FBE8FF",
  "fuchsia-200": "#F6D0FE",
  "fuchsia-300": "#EEAAFD",
  "fuchsia-400": "#E478FA",
  "fuchsia-500": "#D444F1",
  "fuchsia-600": "#BA24D5",
  "fuchsia-700": "#9F1AB1",
  "fuchsia-800": "#821890",
  "fuchsia-900": "#6F1877",
  "pink-25": "#FEF6FB",
  "pink-50": "#FDF2FA",
  "pink-100": "#FCE7F6",
  "pink-200": "#FCCEEE",
  "pink-300": "#FAA7E0",
  "pink-400": "#F670C7",
  "pink-500": "#EE46BC",
  "pink-600": "#DD2590",
  "pink-700": "#C11574",
  "pink-800": "#9E165F",
  "pink-900": "#851651",
  "rose-25": "#FFF5F6",
  "rose-50": "#FFF1F3",
  "rose-100": "#FFE4E8",
  "rose-200": "#FECDD6",
  "rose-300": "#FEA3B4",
  "rose-400": "#FD6F8E",
  "rose-500": "#F63D68",
  "rose-600": "#E31B54",
  "rose-700": "#C01048",
  "rose-800": "#A11043",
  "rose-900": "#89123E",
  "orange-dark-25": "#FFF9F5",
  "orange-dark-50": "#FFF4ED",
  "orange-dark-100": "#FFE6D5",
  "orange-dark-200": "#FFD6AE",
  "orange-dark-300": "#FF9C66",
  "orange-dark-400": "#FF692E",
  "orange-dark-500": "#FF4405",
  "orange-dark-600": "#E62E05",
  "orange-dark-700": "#BC1B06",
  "orange-dark-800": "#97180C",
  "orange-dark-900": "#771A0D",
  "orange-25": "#FEFAF5",
  "orange-50": "#FEF6EE",
  "orange-100": "#FDEAD7",
  "orange-200": "#F9DBAF",
  "orange-300": "#F7B27A",
  "orange-400": "#F38744",
  "orange-500": "#EF6820",
  "orange-600": "#E04F16",
  "orange-700": "#B93815",
  "orange-800": "#932F19",
  "orange-900": "#772917",
  "yellow-25": "#FEFDF0",
  "yellow-50": "#FEFBE8",
  "yellow-100": "#FEF7C3",
  "yellow-200": "#FEEE95",
  "yellow-300": "#FDE272",
  "yellow-400": "#FAC515",
  "yellow-500": "#EAAA08",
  "yellow-600": "#CA8504",
  "yellow-700": "#A15C07",
  "yellow-800": "#854A0E",
  "yellow-900": "#713B12",
};
