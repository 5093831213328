import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCompanyQueryVariables = Types.Exact<{
  params: Types.GetCompanyParams;
}>;


export type GetCompanyQuery = { __typename?: 'Query', getCompany: { __typename?: 'CompanyNotFound', message?: string | null, companyId: string } | { __typename?: 'GetCompanyResult', company: { __typename?: 'Company', companyId: string, name: string, logoUrl?: string | null, rentedLaptopsCount: number, address?: string | null, manager: { __typename?: 'BaseUser', userId: string, name: string, emailId: string, profilePic?: string | null } } } };


export const GetCompanyDocument = gql`
    query GetCompany($params: GetCompanyParams!) {
  getCompany(params: $params) {
    ... on GetCompanyResult {
      company {
        companyId
        name
        logoUrl
        rentedLaptopsCount
        address
        manager {
          userId
          name
          emailId
          profilePic
        }
      }
    }
    ... on CompanyNotFound {
      message
      companyId
    }
  }
}
    `;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;