import { observer } from "mobx-react-lite";
import { createContext, PropsWithChildren, useContext, useRef } from "react";

import { DashboardStore } from "../stores/DashboardStore";

export const DashboardStoreContext = createContext<DashboardStore | null>(null);

const DashboardStoreProvider = (props: PropsWithChildren) => {
  const dashboardStoreRef = useRef(DashboardStore.create());

  return (
    <DashboardStoreContext.Provider value={dashboardStoreRef.current}>
      {props.children}
    </DashboardStoreContext.Provider>
  );
};

export const useDashboardStore = (): DashboardStore => {
  const dashboardStore = useContext(DashboardStoreContext);

  if (!dashboardStore) {
    throw new Error("Expected to be wrapped in Dashboard Store Provider");
  }

  return dashboardStore;
};

export default observer(DashboardStoreProvider);
