import { useCompaniesStore } from "../../contexts/CompaniesStoreContext";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { AddCompanyMutation as Mutation } from "./mutation.generatedTypes";

interface ArgsType {
  data: Mutation;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const companiesStore = useCompaniesStore();

  const handleResponse = (args: ArgsType) => {
    const response = args.data.addCompany;

    switch (response.__typename) {
      case "Company": {
        companiesStore.createOrUpdateCompany(response);
        args.onSuccess?.();
        break;
      }
      case "EmailAlreadyExist":
      case "InvalidEmail":
      case "InvalidPhoneNumber":
      case "InvalidUrl":
      case "UserIsNotAdmin":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
