import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  TextField,
} from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import {
  validateEmail,
  validatePhoneNumber,
} from "../../utils/validationUtils";

interface Props {
  emailId: string | null;
  phoneNumber: string | null;
  name: string | null;
  profilePic: string | null;

  setEmailId: (emailId: string | null) => void;
  setPhoneNumber: (phoneNumber: string | null) => void;
  setName: (name: string | null) => void;
  setProfilePic: (profilePic: string | null) => void;

  onClose: () => void;
  onUpdateUser: () => void;
  isPending: boolean;
}

const UpdateUser = (props: Props): React.ReactElement => {
  const {
    emailId,
    phoneNumber,
    name,
    profilePic,
    setEmailId,
    setPhoneNumber,
    setName,
    setProfilePic,
    onClose,
    onUpdateUser,
    isPending,
  } = props;

  const isValidPhoneNumber = phoneNumber
    ? validatePhoneNumber(phoneNumber).isValid
    : true;

  const isValidEmail = validateEmail(emailId ?? "").isValid;

  const isValid = isValidEmail && !!name?.trim() && isValidPhoneNumber;

  const renderEmailId = () => (
    <TextField
      label="Email"
      placeholder="Enter email"
      value={emailId ?? ""}
      onChange={setEmailId}
      width={"100%"}
      validationState={isValidEmail ? undefined : "invalid"}
      isRequired
    />
  );

  const renderPhoneNumber = () => (
    <TextField
      label="Phone Number"
      placeholder="Enter Phone Number"
      value={phoneNumber ?? ""}
      onChange={setPhoneNumber}
      width={"100%"}
      validationState={isValidPhoneNumber ? undefined : "invalid"}
    />
  );

  const renderName = () => (
    <TextField
      label="Name"
      placeholder="Enter Name"
      value={name ?? ""}
      onChange={setName}
      width={"100%"}
      isRequired
    />
  );

  const renderProfilePic = () => (
    <TextField
      label="Profile Pic Url"
      placeholder="Enter profile pic url"
      value={profilePic ?? ""}
      onChange={setProfilePic}
      width={"100%"}
    />
  );

  return (
    <>
      <Heading>Update User</Heading>
      <Divider />

      <Content UNSAFE_className="scrollbar">
        <div className={cn("flex flex-col gap-2 w-full")}>
          {renderName()}
          {renderEmailId()}
          {renderPhoneNumber()}
          {renderProfilePic()}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button
          variant="accent"
          onPress={onUpdateUser}
          isDisabled={!isValid}
          isPending={isPending}
        >
          {"Update"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(UpdateUser);
