import {
  Button,
  Dialog,
  DialogTrigger,
  Icon,
  Text,
} from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { FaPlus } from "react-icons/fa6";

import CreateCompanyController from "../../controllers/CreateCompanyController/CreateCompanyController";

const CreateCompanyModal = (): React.ReactElement => {
  const renderCreateButton = (): React.ReactElement => (
    <Button variant="accent">
      <Icon width={16} height={16}>
        <FaPlus />
      </Icon>
      <Text>{"Add Company"}</Text>
    </Button>
  );

  return (
    <DialogTrigger>
      {renderCreateButton()}
      {(close) => (
        <Dialog size="M">
          <CreateCompanyController onClose={close} />
        </Dialog>
      )}
    </DialogTrigger>
  );
};

export default observer(CreateCompanyModal);
