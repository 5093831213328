import { useLazyQuery } from "@apollo/client";

import { convertApolloError } from "../../utils/apiUtils";

import {
  GetUserAppConfigQuery as Query,
  GetUserAppConfigDocument as QueryDocument,
  GetUserAppConfigQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => void;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetUserAppConfig = (): ReturnType => {
  const [getUserAppConfig, { loading, error, called }] = useLazyQuery<
    Query,
    QueryVariables
  >(QueryDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = (args: TriggerAPIArgs): void => {
    getUserAppConfig({
      fetchPolicy: "network-only",
      onCompleted(data) {
        handleResponse({
          data,
          onSuccess: args.onSuccess,
          onFailure: args.onFailure,
        });
      },
      onError: args.onFailure,
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: !called || loading };
};
