import { Button, Icon, TextField } from "@adobe/react-spectrum";
import cn from "classnames";
import React from "react";
import { MdOutlineMail } from "react-icons/md";

import { CompanyLogo } from "../../icons/CompanyLogo";

interface Props {
  email: string;
  setEmail: (email: string) => void;
  emailErrorMessage: string | null;
  onNextStep: () => void;
  isPending: boolean;
}

const LoginEmailStep = (props: Props): React.ReactElement => {
  const { email, setEmail, emailErrorMessage, onNextStep, isPending } = props;

  return (
    <div className="flex flex-col items-center gap-4 bg-white shadow-xl p-8 rounded-2xl w-[400px]">
      <CompanyLogo className="h-[120px]" />

      <p className={cn("font-bold text-2xl", "text-gray-900")}>Log in</p>
      <p className={cn("font-regular text-base", "text-gray-600")}>
        Welcome! Please enter your details.
      </p>

      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={setEmail}
        onKeyDown={(e) => e.key === "Enter" && onNextStep()}
        width={"100%"}
        isRequired
        icon={
          <Icon>
            <MdOutlineMail />
          </Icon>
        }
        validationState={emailErrorMessage ? "invalid" : undefined}
        errorMessage={emailErrorMessage}
      />

      <Button
        variant="accent"
        width={"100%"}
        onPress={onNextStep}
        isPending={isPending}
      >
        Login
      </Button>

      <p className={cn("font-regular text-sm", "text-gray-600")}>
        © 2024 Saffron Technologies Pvt Ltd
      </p>
    </div>
  );
};

export default LoginEmailStep;
