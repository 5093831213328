import { useLazyQuery } from "@apollo/client";

import { convertApolloError } from "../../utils/apiUtils";

import { Company } from "../../graphql/graphqlTypes";
import {
  GetCompaniesInAccountQuery as Query,
  GetCompaniesInAccountDocument as QueryDocument,
  GetCompaniesInAccountQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  reqObj: QueryVariables["params"];
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => Promise<Company[]>;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetCompaniesInAccount = (): ReturnType => {
  const [getCompaniesInAccount, { loading, error }] = useLazyQuery<
    Query,
    QueryVariables
  >(QueryDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = async (args: TriggerAPIArgs): Promise<Company[]> => {
    const response = await getCompaniesInAccount({
      variables: {
        params: args.reqObj,
      },
      fetchPolicy: "network-only",
      onError: args.onFailure,
    });

    if (!response || !response.data) return [];

    return handleResponse({
      data: response.data,
      onSuccess: args.onSuccess,
      onFailure: args.onFailure,
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
