export const AUTH_BASE_URL =
  "https://prxalmmxt9.execute-api.ap-south-1.amazonaws.com/prod/";

export const LOGIN_ENDPOINT = "auth/login/";

export const GRAPHQL_ENDPOINT =
  "https://prxalmmxt9.execute-api.ap-south-1.amazonaws.com/prod/graphql/";

export const VERIFY_OTP_ENDPOINT = "auth/verify-otp/";

export const REFRESH_TOKEN_ENDPOINT = "auth/refresh-token/";
