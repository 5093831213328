import { useLazyQuery } from "@apollo/client";

import { convertApolloError } from "../../utils/apiUtils";

import {
  GetGraphDataQuery as Query,
  GetGraphDataDocument as QueryDocument,
  GetGraphDataQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => void;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetGraphData = (): ReturnType => {
  const [getGraphData, { loading, error }] = useLazyQuery<
    Query,
    QueryVariables
  >(QueryDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = (args: TriggerAPIArgs): void => {
    getGraphData({
      onCompleted(data) {
        handleResponse({
          data,
          onSuccess: args.onSuccess,
          onFailure: args.onFailure,
        });
      },
      onError: args.onFailure,
      fetchPolicy: "network-only",
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
