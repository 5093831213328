import { useLazyQuery } from "@apollo/client";

import { convertApolloError } from "../../utils/apiUtils";

import {
  GetAccountUsersQuery as Query,
  GetAccountUsersDocument as QueryDocument,
  GetAccountUsersQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => void;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetAccountUsers = (): ReturnType => {
  const [getAccountUsers, { loading, error }] = useLazyQuery<
    Query,
    QueryVariables
  >(QueryDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = (args: TriggerAPIArgs): void => {
    getAccountUsers({
      fetchPolicy: "network-only",
      onCompleted(data) {
        handleResponse({
          data,
          onSuccess: args.onSuccess,
          onFailure: args.onFailure,
        });
      },
      onError: args.onFailure,
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
