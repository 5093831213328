import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddCompanyMutationVariables = Types.Exact<{
  params: Types.AddCompanyParams;
}>;


export type AddCompanyMutation = { __typename?: 'Mutation', addCompany: { __typename?: 'Company', companyId: string, name: string, logoUrl?: string | null, rentedLaptopsCount: number, address?: string | null, manager: { __typename?: 'BaseUser', userId: string, name: string, emailId: string, profilePic?: string | null } } | { __typename?: 'CompanyNameAlreadyExist' } | { __typename?: 'EmailAlreadyExist', message?: string | null, emailId: string } | { __typename?: 'InvalidEmail', message?: string | null, emailId: string } | { __typename?: 'InvalidPhoneNumber', message?: string | null, phoneNumber: string } | { __typename?: 'InvalidUrl', message?: string | null, url: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const AddCompanyDocument = gql`
    mutation AddCompany($params: AddCompanyParams!) {
  addCompany(params: $params) {
    ... on Company {
      companyId
      name
      logoUrl
      rentedLaptopsCount
      address
      manager {
        userId
        name
        emailId
        profilePic
      }
    }
    ... on EmailAlreadyExist {
      message
      emailId
    }
    ... on UserIsNotAdmin {
      message
    }
    ... on InvalidPhoneNumber {
      message
      phoneNumber
    }
    ... on InvalidEmail {
      message
      emailId
    }
    ... on InvalidUrl {
      message
      url
    }
  }
}
    `;
export type AddCompanyMutationFn = Apollo.MutationFunction<AddCompanyMutation, AddCompanyMutationVariables>;
export type AddCompanyMutationResult = Apollo.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<AddCompanyMutation, AddCompanyMutationVariables>;