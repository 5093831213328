import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { UpdateTicketStatusMutation as Mutation } from "./mutation.generatedTypes";

interface ArgsType {
  data: Mutation;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const handleResponse = (args: ArgsType) => {
    const response = args.data.updateTicketStatus;

    switch (response.__typename) {
      case "UpdateTicketStatusResult": {
        args.onSuccess?.();
        break;
      }
      case "UserIsNotAdmin":
      case "ReplacementDetailsAreRequired":
      case "ReturnDateIsRequired":
      case "TicketNotFound":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
