import { MutationStatus, useMutation } from "@tanstack/react-query";

import { axiosClient } from "../../configs/axiosConfig";
import { LOGIN_ENDPOINT } from "../../constants/apiConstants";
import { SendOTPParams, SendOTPResponse } from "../../types/restAPITypes";

interface TriggerArgs {
  reqObj: SendOTPParams;
  onSuccess?: (data: SendOTPResponse) => void;
  onFailure?: (errorMessage: string) => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerArgs) => void;
  status: MutationStatus;
  isPending: boolean;
}

const adminLogin = async (reqObj: SendOTPParams): Promise<SendOTPResponse> => {
  const response = await axiosClient.post(LOGIN_ENDPOINT, reqObj, {
    withCredentials: true,
  });
  return response.data;
};

const useSendOTP = (): ReturnType => {
  const { mutate, status, isPending } = useMutation({
    mutationFn: adminLogin,
  });

  const triggerAPI = (args: TriggerArgs): void => {
    mutate(args.reqObj, {
      onSuccess(data) {
        args.onSuccess?.(data);
      },
      onError(error) {
        args.onFailure?.(error.message ?? "Something went wrong");
      },
    });
  };

  return { triggerAPI, status, isPending };
};

export default useSendOTP;
