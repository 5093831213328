import { useState } from "react";

import { TicketType } from "../../graphql/graphqlTypes";

export const useRaiseTicketState = () => {
  const [ticketType, setTicketType] = useState<TicketType | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [ticketConfig, setTicketConfig] = useState<string[]>([]);

  return {
    ticketType,
    description,
    ticketConfig,
    setTicketType,
    setDescription,
    setTicketConfig,
  };
};
