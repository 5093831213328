import Cookies from "js-cookie";

import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../constants/authConstants";

export const getAccessToken = (): string | undefined => {
  const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
  return accessToken;
};

export const getRefreshToken = (): string | undefined => {
  const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
  return refreshToken;
};

export const isUserLoggedIn = (): boolean => {
  const accessToken = getAccessToken();
  return !!accessToken;
};
