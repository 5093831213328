import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateLaptopMutationVariables = Types.Exact<{
  params: Types.CreateLaptopParams;
}>;


export type CreateLaptopMutation = { __typename?: 'Mutation', createLaptop: { __typename?: 'CompanyNotFound' } | { __typename?: 'DeliveryDateCannotBeInPast' } | { __typename?: 'DeliveryDateMustBeGreaterThanProcuredDate' } | { __typename?: 'Laptop', laptopId: string, modelNumber: string, serialNumber: string, configuration?: string | null, vendor: string, procuredDate: any, status: Types.LaptopStatus, rentedDetails?: { __typename?: 'LaptopRentedDetails', companyName: string, deliveryDate: any, returnDate?: any | null, replacementDate?: any | null, employeeId: string, employeeName: string } | null } | { __typename?: 'SerialNumberAlreadyExist', message?: string | null, serialNumber: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const CreateLaptopDocument = gql`
    mutation CreateLaptop($params: CreateLaptopParams!) {
  createLaptop(params: $params) {
    ... on Laptop {
      laptopId
      modelNumber
      serialNumber
      configuration
      vendor
      procuredDate
      status
      rentedDetails {
        companyName
        deliveryDate
        returnDate
        replacementDate
        employeeId
        employeeName
      }
    }
    ... on SerialNumberAlreadyExist {
      message
      serialNumber
    }
    ... on UserIsNotAdmin {
      message
    }
  }
}
    `;
export type CreateLaptopMutationFn = Apollo.MutationFunction<CreateLaptopMutation, CreateLaptopMutationVariables>;
export type CreateLaptopMutationResult = Apollo.MutationResult<CreateLaptopMutation>;
export type CreateLaptopMutationOptions = Apollo.BaseMutationOptions<CreateLaptopMutation, CreateLaptopMutationVariables>;