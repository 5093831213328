import {
  ActionButton,
  Content,
  Heading,
  Icon,
  IllustratedMessage,
} from "@adobe/react-spectrum";
import { CalendarDate } from "@internationalized/date";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";
import type { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";

import { useGetTicketsInAccount } from "../../apis/GetTicketsInAccount/useGetTicketsInAccount";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { Ticket, TicketStatus, TicketType } from "../../graphql/graphqlTypes";
import { showSuccessToast } from "../../utils/toastUtils";

import Loader from "../Loader/Loader";
import UpdateTicketStatusModal from "../UpdateTicketStatusModal/UpdateTicketStatusModal";

const renderStatusBadge = (status: TicketStatus): React.ReactElement => {
  switch (status) {
    case TicketStatus.Resolved:
      return (
        <div className="bg-green-100 px-2 py-1 rounded-2xl font-medium text-green-600 text-sm">
          Resolved
        </div>
      );
    case TicketStatus.Discarded:
      return (
        <div className="bg-red-100 px-2 py-1 rounded-2xl font-medium text-red-600 text-sm">
          Discarded
        </div>
      );
    case TicketStatus.Pending:
      return (
        <div className="bg-blue-100 px-2 py-1 rounded-2xl font-medium text-blue-600 text-sm">
          Pending
        </div>
      );
  }
};

const getStatusInfoText = (
  replacementDate?: string,
  returnDate?: string
): string => {
  if (replacementDate && returnDate) {
    const isSameDate = replacementDate === returnDate;
    return isSameDate
      ? `Replacement done on ${replacementDate} with Returned`
      : `Replacement done on ${replacementDate} with Return on ${returnDate}`;
  }
  if (replacementDate)
    return `Replacement done on ${replacementDate} with Return is not done`;
  if (returnDate) return `Return Date: ${returnDate}`;
  return "-";
};

const renderLaptop = (serialNo: string) => (
  <div className="relative flex items-center w-full h-full group">
    {serialNo}
    <div className="group-hover:block top-1 right-0 absolute hidden">
      <ActionButton
        isQuiet
        onPress={() => {
          navigator.clipboard.writeText(serialNo).then(() => {
            showSuccessToast("Laptop Serial Number Copied");
          });
        }}
      >
        <Icon>
          <MdOutlineContentCopy />
        </Icon>
      </ActionButton>
    </div>
  </div>
);

const columnDefs: ColDef[] = [
  {
    field: "laptop.modelNumber",
    headerName: "Old Model & Configuration",
    width: 220,
    cellRenderer: (value: any) => {
      const ticket = value.data as Ticket;
      return `${ticket?.laptop?.modelNumber} - ${ticket?.laptop?.configuration}`;
    },
  },
  {
    field: "laptop.serialNumber",
    headerName: "Old Serial No",
    width: 150,
  },
  {
    field: "newLaptop.modelNumber",
    headerName: "New Model & Configuration",
    width: 220,
    cellRenderer: (value: any) => {
      const ticket = value.data as Ticket;
      const newLaptop = ticket?.newLaptop;
      return newLaptop
        ? `${newLaptop?.modelNumber} - ${newLaptop?.configuration}`
        : "-";
    },
  },
  {
    field: "newLaptop.serialNumber",
    headerName: "New Serial No",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    cellRenderer: (value: any) => (
      <div className="flex items-start h-full">
        {renderStatusBadge(value.value)}
      </div>
    ),
    width: 120,
  },

  {
    field: "replacementDate",
    headerName: "Replacement Date",
    valueFormatter: (value) =>
      (value.value as CalendarDate | undefined)?.toString() ?? "-",
    width: 180,
  },
  {
    field: "oldLaptopReturnDate",
    headerName: "Return Date",
    valueFormatter: (value) =>
      (value.value as CalendarDate | undefined)?.toString() ?? "-",
    width: 180,
  },
  {
    field: "companyName",
    headerName: "Company",
    width: 220,
  },

  {
    field: "creationDatetime",
    headerName: "Ticket Raised On",
    valueFormatter: (value) =>
      (value.value as CalendarDate | undefined)?.toString() ?? "-",
    width: 180,
  },
  {
    field: "technicalPerson",
    headerName: "Technical Person",
    width: 150,
  },
  {
    field: "laptop.replacementDate",
    headerName: "Status & Return Info",
    width: 300,

    cellRenderer: (value: any) => {
      const ticket = value.data as Ticket;
      return getStatusInfoText(
        ticket?.replacementDate,
        ticket?.oldLaptopReturnDate
      );
    },
  },
];

interface Props {
  updateRefreshId: () => void;
}

const ReplacementTicketsTable = (props: Props): React.ReactElement => {
  const gridRef = useRef<AgGridReact<Ticket>>(null);

  const { isAdmin, companyId } = useUserAppConfigStore();

  const [activeTicket, setActiveTicket] = useState<Ticket | null>(null);
  const [showNoSearchResults, setShowNoSearchResults] = useState(false);

  const onClose = () => setActiveTicket(null);

  const getTicketsAPI = useGetTicketsInAccount();

  const getTickets = (offset = 0) => {
    return getTicketsAPI.triggerAPI({
      reqObj: {
        companyId,
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset,
        },
        ticketTypes: [TicketType.Replacement],
      },
    });
  };

  const datasource: IDatasource = useMemo(
    () => ({
      getRows: async (params: IGetRowsParams) => {
        const { startRow, endRow, successCallback, failCallback } = params;

        try {
          const response = await getTickets(startRow);

          const data: Ticket[] = response;

          const lastRow =
            data.length < endRow - startRow
              ? startRow + data.length
              : undefined;

          if (startRow === 0) setShowNoSearchResults(data.length === 0);

          successCallback(data, lastRow);
        } catch (error) {
          failCallback();
        }
      },
    }),
    []
  );

  const renderNoDataView = (): React.ReactElement => (
    <div className="bg-gray-50 w-full h-full">
      <IllustratedMessage>
        <NoSearchResults />
        <Heading>No Tickets Found</Heading>
        <Content>No Tickets are raised yet</Content>
      </IllustratedMessage>
    </div>
  );

  return (
    <div className="flex-1 mt-2 w-full h-full ag-theme-quartz">
      {showNoSearchResults ? (
        renderNoDataView()
      ) : (
        <AgGridReact<Ticket>
          ref={gridRef}
          columnDefs={columnDefs as any}
          defaultColDef={{
            sortable: false,
            onCellClicked({ data }) {
              if (data && data.status === TicketStatus.Pending) {
                setActiveTicket(data);
              }
            },
            wrapHeaderText: true,
            wrapText: true,
            autoHeight: true,
            cellStyle: {
              lineHeight: "1.2",
              textAlign: "center",
              display: "flex",
              justify: "center",
              alignItems: "center",
            },
          }}
          rowHeight={60}
          rowModelType="infinite"
          cacheBlockSize={DEFAULT_API_LIMIT}
          maxConcurrentDatasourceRequests={1}
          datasource={datasource}
          loading={getTicketsAPI.isLoading}
          loadingOverlayComponent={() => <Loader />}
        />
      )}

      {isAdmin && activeTicket ? (
        <UpdateTicketStatusModal
          ticket={activeTicket}
          isOpen={!!activeTicket}
          onClose={onClose}
          updateRefreshId={props.updateRefreshId}
        />
      ) : null}
    </div>
  );
};

export default ReplacementTicketsTable;
