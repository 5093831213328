import { useMutation } from "@apollo/client";

import { convertApolloError } from "../../utils/apiUtils";

import {
  UpdateCompanyMutation as Mutation,
  UpdateCompanyDocument as MutationDocument,
  UpdateCompanyMutationVariables as MutationVariables,
} from "./mutation.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  reqObj: MutationVariables["params"];
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => void;
  apiError: Error | null;
  isLoading: boolean;
}

export const useUpdateCompany = (): ReturnType => {
  const [updateCompany, { loading, error }] = useMutation<
    Mutation,
    MutationVariables
  >(MutationDocument);

  const { handleResponse } = useResponseHandler();

  const triggerAPI = (args: TriggerAPIArgs): void => {
    updateCompany({
      variables: {
        params: args.reqObj,
      },
      onCompleted(data) {
        handleResponse({
          data,
          onSuccess: args.onSuccess,
          onFailure: args.onFailure,
        });
      },
      onError: args.onFailure,
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
