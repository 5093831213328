import { Dialog, DialogContainer } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";

import UpdateUserController from "../../controllers/UpdateUserController/UpdateUserController";

interface Props {
  activeUserId: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateUserModal = (props: Props): React.ReactElement => {
  const { activeUserId, isOpen, onClose } = props;

  if (!activeUserId) return <></>;

  return (
    <DialogContainer onDismiss={onClose}>
      {isOpen && (
        <Dialog size="S">
          <UpdateUserController onClose={onClose} userId={activeUserId} />
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(UpdateUserModal);
