import { useDashboardStore } from "../../contexts/DashboardStoreContext";
import { DataPoint } from "../../types/coreTypes";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { GetGraphDataQuery as Query } from "./query.generatedTypes";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const dashboardStore = useDashboardStore();

  const handleResponse = (args: ArgsType) => {
    const response = args.data.getGraphData;

    switch (response.__typename) {
      case "GetGraphResult": {
        const dataPoints: DataPoint[] = new Array(6).fill(0).map((_, index) => {
          const availableItem = response?.availableItems?.[index + 6];
          const rentedItem = response?.rentedItems?.[index + 6];
          const totalItem = response?.totalItems?.[index + 6];

          return {
            label: availableItem?.month.slice(0, 3) ?? "N/A",
            available: Number(availableItem?.count) ?? 0,
            rented: Number(rentedItem?.count) ?? 0,
            total: Number(totalItem?.count) ?? 0,
          };
        });

        dashboardStore.setDataPoints(dataPoints);
        args.onSuccess?.();
        break;
      }
      case "UserIsNotAdmin":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
