import { LaptopStatus, TicketType } from "../graphql/graphqlTypes";
import { Gender, Role } from "../types/coreTypes";

export const roleOptions: Role[] = ["MANAGER", "ADMIN"];

export const genderOptions: Gender[] = ["MALE", "FEMALE", "OTHERS"];

export const LAPTOP_STATUS_OPTIONS: LaptopStatus[] = [
  LaptopStatus.Available,
  LaptopStatus.Damaged,
  LaptopStatus.Rented,
];

export const DEFAULT_API_LIMIT = 30;

export const SERVICE_TYPES = [
  "Performance Issues",
  "Physical Damage",
  "Laptop not working",
  "OS Issues",
  "Virus Infection",
  "Other",
];

export const TICKET_TYPES: TicketType[] = [
  TicketType.PickUp,
  TicketType.Replacement,
  TicketType.Service,
];
