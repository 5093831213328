import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateCompanyMutationVariables = Types.Exact<{
  params: Types.UpdateCompanyParams;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'Company', companyId: string, name: string, logoUrl?: string | null, rentedLaptopsCount: number, address?: string | null, manager: { __typename?: 'BaseUser', userId: string, name: string, emailId: string, profilePic?: string | null } } | { __typename?: 'CompanyNotFound', message?: string | null, companyId: string } | { __typename?: 'InvalidUrl', message?: string | null, url: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($params: UpdateCompanyParams!) {
  updateCompany(params: $params) {
    ... on Company {
      companyId
      name
      logoUrl
      rentedLaptopsCount
      address
      manager {
        userId
        name
        emailId
        profilePic
      }
    }
    ... on UserIsNotAdmin {
      message
    }
    ... on CompanyNotFound {
      message
      companyId
    }
    ... on InvalidUrl {
      message
      url
    }
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;