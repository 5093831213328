import { Dialog, DialogContainer } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";

import UpdateLaptopController from "../../controllers/UpdateLaptopController/UpdateLaptopController";

interface Props {
  activeLaptopId: string | null;
  isOpen: boolean;
  onClose: () => void;
  updateRefreshId: () => void;
}

const UpdateLaptopModal = (props: Props): React.ReactElement => {
  const { activeLaptopId, isOpen, onClose, updateRefreshId } = props;

  if (!activeLaptopId) return <></>;

  return (
    <DialogContainer onDismiss={onClose}>
      {isOpen && (
        <Dialog size="M">
          <UpdateLaptopController
            onClose={onClose}
            laptopId={activeLaptopId}
            updateRefreshId={updateRefreshId}
          />
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(UpdateLaptopModal);
