import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { useUsersStore } from "../../contexts/UsersStoreContext";
import { UserModel } from "../../models/UserModel";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { GetUserAppConfigQuery as Query } from "./query.generatedTypes";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const userAppConfigStore = useUserAppConfigStore();
  const usersStore = useUsersStore();

  const handleResponse = (args: ArgsType) => {
    const response = args.data.getUserAppConfig;

    switch (response.__typename) {
      case "UserAppConfig": {
        userAppConfigStore.setUser(UserModel.create(response.baseUser));
        userAppConfigStore.setIsAdmin(response.isAdmin);
        userAppConfigStore.setCompanyId(
          response.otherDetails?.companyId ?? null
        );

        usersStore.createOrUpdateUser(response.baseUser);

        args.onSuccess?.();
        break;
      }
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
