import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { useUsersStore } from "../../contexts/UsersStoreContext";
import { GetAccountUsersQuery as Query } from "./query.generatedTypes";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const usersStore = useUsersStore();

  const handleResponse = (args: ArgsType) => {
    const response = args.data.getAccountUsers;

    switch (response.__typename) {
      case "GetAccountUsersResult": {
        response.users?.forEach((user) => {
          if (user) usersStore.createOrUpdateUser(user);
        });

        args.onSuccess?.();
        break;
      }
      case "UserIsNotAdmin":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
