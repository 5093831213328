import { observer } from "mobx-react-lite";
import { ComponentProps } from "react";
import { FiUsers } from "react-icons/fi";
import {
  MdBusinessCenter,
  MdEventAvailable,
  MdOutlineLaptopMac,
} from "react-icons/md";

import { colors } from "../../constants/colors";
import { useDashboardStore } from "../../contexts/DashboardStoreContext";

import StatItem from "../StatItem/StatItem";

const LaptopStats = (): React.ReactElement => {
  const dashboardStore = useDashboardStore();

  const statItems: ComponentProps<typeof StatItem>[] = [
    {
      label: "Total Laptops",
      value: dashboardStore.totalLaptops,
      icon: <MdOutlineLaptopMac size={32} fill={colors["blue-600"]} />,
    },
    {
      label: "Available Laptops",
      value: dashboardStore.availableLaptops,
      icon: <MdEventAvailable size={32} fill={colors["green-600"]} />,
    },
    {
      label: "Rented Laptops",
      value: dashboardStore.rentedLaptops,
      icon: <FiUsers size={32} stroke={colors["error-600"]} />,
    },
    {
      label: "Client Companies",
      value: dashboardStore.totalCompanies,
      icon: <MdBusinessCenter size={32} fill={colors["gray-600"]} />,
    },
  ];

  return (
    <div className="flex gap-4 p-4">
      {statItems.map((statItem) => (
        <StatItem key={statItem.label} {...statItem} />
      ))}
    </div>
  );
};

export default observer(LaptopStats);
