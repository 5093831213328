import { useParams } from "react-router-dom";

interface EntityIdsInUrl {
  companyId: string | undefined;
}

export const useEntityIdsFromUrl = (): EntityIdsInUrl => {
  const { companyId } = useParams<{
    companyId: string;
  }>();

  return {
    companyId,
  };
};
