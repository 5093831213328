import { useState } from "react";
import { CalendarDate } from "@internationalized/date";

import { LaptopModel } from "../../models/LaptopModel";

export const useUpdateLaptopState = (laptopModel: LaptopModel) => {
  const [companyId, setCompanyId] = useState<string | null>(
    laptopModel.companyId ?? null
  );
  const [deliveryDate, setDeliveryDate] = useState<CalendarDate | null>(
    laptopModel.deliveryDate ?? null
  );
  const [returnDate, setReturnDate] = useState<CalendarDate | null>(
    laptopModel.returnDate ?? null
  );
  const [employeeId, setEmployeeId] = useState<string | null>(
    laptopModel.employeeId ?? null
  );
  const [employeeName, setEmployeeName] = useState<string | null>(
    laptopModel.employeeName ?? null
  );
  const [configuration, setConfiguration] = useState<string | null>(
    laptopModel.configuration ?? null
  );
  const [vendor, setVendor] = useState<string | null>(
    laptopModel.vendor ?? null
  );

  return {
    companyId,
    employeeId,
    employeeName,
    deliveryDate,
    returnDate,
    configuration,
    vendor,
    setCompanyId,
    setEmployeeId,
    setEmployeeName,
    setDeliveryDate,
    setReturnDate,
    setConfiguration,
    setVendor,
  };
};
