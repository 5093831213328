import { ActionButton, Icon } from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { FaRegSquareCaretLeft, FaRegSquareCaretRight } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

import { colors } from "../../constants/colors";
import AccountUserController from "../../controllers/AccountUserController/AccountUserController";
import { CompanyLogo } from "../../icons/CompanyLogo";
import { CompanyMiniLogo } from "../../icons/CompanyMiniLogo";
import { NavItem } from "../../types/navbarTypes";

import { useNavbarData } from "./useNavbarData";

const Navbar = (): React.ReactElement => {
  const { navItems, isExpanded, toggleExpand } = useNavbarData();

  return (
    <div
      className={cn(
        "relative flex flex-col border-r w-[280px] h-full transition-[width] group",
        "border-gray-300",
        isExpanded ? "w-[280px]" : "w-[80px]"
      )}
    >
      <div className="flex justify-center py-4">
        {isExpanded ? (
          <CompanyLogo className="h-[100px]" />
        ) : (
          <CompanyMiniLogo className="mt-4 h-[40px]" />
        )}
      </div>

      <div className="top-1 right-1 absolute opacity-0 group-hover:opacity-100 transition-opacity">
        <ActionButton isQuiet onPress={toggleExpand}>
          <Icon>
            {isExpanded ? (
              <FaRegSquareCaretLeft stroke={colors["gray-600"]} />
            ) : (
              <FaRegSquareCaretRight stroke={colors["gray-600"]} />
            )}
          </Icon>
        </ActionButton>
      </div>

      <NavbarItemsList isExpanded={isExpanded} navItems={navItems} />

      <AccountUserController isExpanded={isExpanded} />
    </div>
  );
};

const NavbarItemsList = ({
  navItems,
  isExpanded,
}: {
  navItems: NavItem[];
  isExpanded: boolean;
}): React.ReactElement => (
  <div className="flex-1">
    {navItems.map((navItem) => (
      <NavItemComponent navItem={navItem} isExpanded={isExpanded} />
    ))}
  </div>
);

const NavItemComponent = ({
  navItem,
  isExpanded,
}: {
  navItem: NavItem;
  isExpanded: boolean;
}): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = location.pathname.includes(navItem.path);

  const handleRedirect = () => {
    navigate(navItem.path);
  };

  return (
    <div
      className={cn(
        "relative flex items-center gap-2 px-6 py-3 transition-shadow cursor-pointer",
        isActive ? "bg-blue-50 shadow" : "hover:bg-gray-50"
      )}
      onClick={handleRedirect}
    >
      {navItem.Icon}
      {isExpanded ? (
        <span className="font-medium text-gray-700 text-sm">
          {navItem.label}
        </span>
      ) : null}
      <div
        className={cn(
          "top-0 left-0 absolute bg-blue-600 rounded-tr-md rounded-br-md w-[4px] h-full transition-opacity",
          isActive ? "opacity-100" : "opacity-0"
        )}
      />
    </div>
  );
};

export default observer(Navbar);
