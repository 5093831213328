import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetCompanyDashboardDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyDashboardDataQuery = { __typename?: 'Query', getCompanyDashboardData: { __typename?: 'GetCompanyDashboardDataResult', totalRentedLaptops: number, ticketsRaised: number, ticketsPending: number } | { __typename?: 'NotCompanyManager', message?: string | null } };


export const GetCompanyDashboardDataDocument = gql`
    query GetCompanyDashboardData {
  getCompanyDashboardData {
    ... on GetCompanyDashboardDataResult {
      totalRentedLaptops
      ticketsRaised
      ticketsPending
    }
    ... on NotCompanyManager {
      message
    }
  }
}
    `;
export type GetCompanyDashboardDataQueryResult = Apollo.QueryResult<GetCompanyDashboardDataQuery, GetCompanyDashboardDataQueryVariables>;