import { useState } from "react";
import { HiOutlineTicket } from "react-icons/hi2";
import { IconBaseProps } from "react-icons/lib/iconBase";
import {
  MdBusinessCenter,
  MdOutlineHome,
  MdOutlineInventory2,
  MdOutlineManageAccounts,
  MdOutlineSpaceDashboard,
} from "react-icons/md";

import { colors } from "../../constants/colors";
import {
  COMPANIES_ROUTE,
  DASHBOARD_ROUTE,
  INVENTORY_ROUTE,
  TICKETS_ROUTE,
  USERS_ROUTE,
} from "../../constants/navigationConstants";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { NavItem } from "../../types/navbarTypes";

interface ReturnType {
  navItems: NavItem[];
  isExpanded: boolean;
  toggleExpand: () => void;
}

const commonIconProps: IconBaseProps = {
  size: 20,
  color: colors["gray-700"],
};

const adminNavItems: NavItem[] = [
  {
    label: "DashBoard",
    path: DASHBOARD_ROUTE,
    Icon: <MdOutlineSpaceDashboard {...commonIconProps} />,
  },
  {
    label: "Inventory",
    path: INVENTORY_ROUTE,
    Icon: <MdOutlineInventory2 {...commonIconProps} />,
  },
  {
    label: "Companies",
    path: COMPANIES_ROUTE,
    Icon: <MdBusinessCenter {...commonIconProps} />,
  },
  {
    label: "Tickets",
    path: TICKETS_ROUTE,
    Icon: <HiOutlineTicket {...commonIconProps} />,
  },
  {
    label: "Users",
    path: USERS_ROUTE,
    Icon: <MdOutlineManageAccounts {...commonIconProps} />,
  },
];

export const useNavbarData = (): ReturnType => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { companyId, isAdmin } = useUserAppConfigStore();

  const toggleExpand = () => setIsExpanded((prevState) => !prevState);

  const managerNavItems: NavItem[] = [
    {
      label: "DashBoard",
      path: DASHBOARD_ROUTE,
      Icon: <MdOutlineSpaceDashboard {...commonIconProps} />,
    },
    {
      label: "Inventory",
      path: `${COMPANIES_ROUTE}/${companyId}`,
      Icon: <MdOutlineHome {...commonIconProps} />,
    },
    {
      label: "Tickets",
      path: TICKETS_ROUTE,
      Icon: <HiOutlineTicket {...commonIconProps} />,
    },
  ];

  return {
    navItems: isAdmin ? adminNavItems : managerNavItems,
    isExpanded,
    toggleExpand,
  };
};
