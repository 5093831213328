import { Dialog, DialogContainer } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";

import RaiseTicketController from "../../controllers/RaiseTicketController/RaiseTicketController";

interface Props {
  laptopId: string;
  companyId: string;
  isOpen: boolean;
  onClose: () => void;
}

const RaiseTicketModal = (props: Props): React.ReactElement => {
  const { laptopId, companyId, isOpen, onClose } = props;

  return (
    <DialogContainer onDismiss={onClose}>
      {isOpen && (
        <Dialog size="L">
          <RaiseTicketController
            onClose={onClose}
            laptopId={laptopId}
            companyId={companyId}
          />
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(RaiseTicketModal);
