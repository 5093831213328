import { useNavigate } from "react-router-dom";

import {
  INVENTORY_ROUTE,
  COMPANIES_ROUTE,
  LOGIN_ROUTE,
} from "../constants/navigationConstants";

export const useRedirection = () => {
  const navigate = useNavigate();

  const goToHome = (): void => {
    navigate("/");
  };

  const goToBranchDetailedView = (branchId: string): void => {
    navigate(`${INVENTORY_ROUTE}/${branchId}`);
  };

  const goToInventoryRoute = (): void => {
    navigate(INVENTORY_ROUTE);
  };

  const goToCompanyDetailedView = (companyId: string): void => {
    navigate(`${COMPANIES_ROUTE}/${companyId}`);
  };

  const goToCompaniesList = (): void => {
    navigate(COMPANIES_ROUTE);
  };

  const goBack = (): void => {
    if (window.history.length > 1) navigate(-1);
  };

  const goToLoginPage = (): void => {
    navigate(LOGIN_ROUTE);
  };

  return {
    goToBranchDetailedView,
    goToInventoryRoute,
    goToCompanyDetailedView,
    goToCompaniesList,
    goBack,
    goToLoginPage,
    goToHome,
  };
};
