import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetUserAppConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserAppConfigQuery = { __typename?: 'Query', getUserAppConfig: { __typename?: 'UserAppConfig', isAdmin: boolean, baseUser: { __typename?: 'User', userId: string, name: string, emailId: string, profilePic?: string | null, phoneNumber?: string | null }, otherDetails?: { __typename?: 'UserOtherDetails', companyId: string } | null } };


export const GetUserAppConfigDocument = gql`
    query GetUserAppConfig {
  getUserAppConfig {
    isAdmin
    baseUser {
      userId
      name
      emailId
      profilePic
      phoneNumber
    }
    otherDetails {
      companyId
    }
  }
}
    `;
export type GetUserAppConfigQueryResult = Apollo.QueryResult<GetUserAppConfigQuery, GetUserAppConfigQueryVariables>;