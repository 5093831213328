import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateAdminUserMutationVariables = Types.Exact<{
  params: Types.CreateAdminUserParams;
}>;


export type CreateAdminUserMutation = { __typename?: 'Mutation', createAdminUser: { __typename?: 'EmailAlreadyExist' } | { __typename?: 'InvalidEmail', message?: string | null, emailId: string } | { __typename?: 'InvalidPhoneNumber', message?: string | null } | { __typename?: 'MaxAdminUsersLimitExceeded', message?: string | null, maxCount: number } | { __typename?: 'User', userId: string, name: string, emailId: string, profilePic?: string | null, phoneNumber?: string | null } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const CreateAdminUserDocument = gql`
    mutation CreateAdminUser($params: CreateAdminUserParams!) {
  createAdminUser(params: $params) {
    ... on User {
      userId
      name
      emailId
      profilePic
      phoneNumber
    }
    ... on UserIsNotAdmin {
      message
    }
    ... on InvalidEmail {
      message
      emailId
    }
    ... on InvalidPhoneNumber {
      message
    }
    ... on MaxAdminUsersLimitExceeded {
      message
      maxCount
    }
  }
}
    `;
export type CreateAdminUserMutationFn = Apollo.MutationFunction<CreateAdminUserMutation, CreateAdminUserMutationVariables>;
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>;