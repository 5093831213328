import { makeAutoObservable } from "mobx";

import { User } from "../graphql/graphqlTypes";
import { Collection } from "../models/Collection";
import { UserModel } from "../models/UserModel";

export class UsersStore {
  private _usersCollection: Collection<string, UserModel>;

  private constructor() {
    this._usersCollection = new Collection<string, UserModel>(
      "the user doesn't exist in store"
    );

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get users() {
    return this._usersCollection.orderedItems;
  }

  createOrUpdateUser(user: User) {
    const hasItem = this._usersCollection.hasItem(user.userId);
    if (hasItem) {
      const userModel = this._usersCollection.getItem(user.userId);
      userModel.update(user);
    } else {
      const userModel = UserModel.create(user);
      this._usersCollection.addItem(user.userId, userModel);
    }
  }

  getUser(userId: string) {
    return this._usersCollection.getItem(userId);
  }

  deleteUser(userId: string) {
    this._usersCollection.deleteItem(userId);
  }

  static create(): UsersStore {
    return new UsersStore();
  }
}
