import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { UserModel } from "../../models/UserModel";

import UpdateUserModal from "../UpdateUserModal/UpdateUserModal";

interface Props {
  users: UserModel[];
}

const UsersTable = (props: Props): React.ReactElement => {
  const { users } = props;

  const [activeUserId, setActiveUserId] = useState<string | null>(null);

  const onClose = () => setActiveUserId(null);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "emailId",
      headerName: "Email",
      sortable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      sortable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "profilePic",
      headerName: "Profile Pic",
      sortable: false,
      width: 100,
      flex: 1,
    },
  ]);

  return (
    <div className="flex-1 w-full h-full ag-theme-quartz">
      <AgGridReact
        rowData={users}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={15}
        paginationPageSizeSelector={false}
        onRowClicked={({ data }) => setActiveUserId(data.userId)}
      />

      <UpdateUserModal
        activeUserId={activeUserId}
        isOpen={!!activeUserId}
        onClose={onClose}
      />
    </div>
  );
};

export default observer(UsersTable);
