import * as Types from '../../graphql/graphqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type MakeLaptopAvailableMutationVariables = Types.Exact<{
  params: Types.MakeLaptopAvailableParams;
}>;


export type MakeLaptopAvailableMutation = { __typename?: 'Mutation', makeLaptopAvailable: { __typename?: 'LaptopIsRented', message?: string | null, laptopId: string } | { __typename?: 'LaptopNotFound', message?: string | null, laptopId: string } | { __typename?: 'MakeLaptopAvailableResult', laptopId: string } | { __typename?: 'UserIsNotAdmin', message?: string | null } };


export const MakeLaptopAvailableDocument = gql`
    mutation MakeLaptopAvailable($params: MakeLaptopAvailableParams!) {
  makeLaptopAvailable(params: $params) {
    ... on MakeLaptopAvailableResult {
      laptopId
    }
    ... on LaptopNotFound {
      message
      laptopId
    }
    ... on LaptopIsRented {
      message
      laptopId
    }
    ... on UserIsNotAdmin {
      message
    }
  }
}
    `;
export type MakeLaptopAvailableMutationFn = Apollo.MutationFunction<MakeLaptopAvailableMutation, MakeLaptopAvailableMutationVariables>;
export type MakeLaptopAvailableMutationResult = Apollo.MutationResult<MakeLaptopAvailableMutation>;
export type MakeLaptopAvailableMutationOptions = Apollo.BaseMutationOptions<MakeLaptopAvailableMutation, MakeLaptopAvailableMutationVariables>;