import {
  Button,
  ButtonGroup,
  Content,
  Divider,
  Heading,
  TextField,
} from "@adobe/react-spectrum";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  employeeId: string | null;
  employeeName: string | null;

  setEmployeeId: (employeeId: string | null) => void;
  setEmployeeName: (employeeName: string | null) => void;

  onClose: () => void;
  onUpdateLaptop: () => void;
}

const UpdateLaptopRentedDetails = (props: Props): React.ReactElement => {
  const {
    employeeId,
    employeeName,
    setEmployeeId,
    setEmployeeName,
    onClose,
    onUpdateLaptop,
  } = props;

  const isValid = !!employeeName?.trim() && !!employeeId?.trim();

  const renderEmployeeId = () => (
    <TextField
      label="Employee Id"
      value={employeeId ?? ""}
      onChange={setEmployeeId}
      width={"100%"}
      isRequired
    />
  );

  const renderEmployeeName = () => (
    <TextField
      label="Employee Name"
      value={employeeName ?? ""}
      onChange={setEmployeeName}
      width={"100%"}
      isRequired
    />
  );

  return (
    <>
      <Heading>Update Employee Details</Heading>
      <Divider />

      <Content UNSAFE_className="scrollbar">
        <div className={cn("flex flex-col gap-2 w-full")}>
          {renderEmployeeId()}
          {renderEmployeeName()}
        </div>
      </Content>

      <ButtonGroup>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button variant="accent" onPress={onUpdateLaptop} isDisabled={!isValid}>
          {"Update"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default observer(UpdateLaptopRentedDetails);
