import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { IoPersonCircle } from "react-icons/io5";

import AccountUserActionMenu from "../../components/AccountUserActionMenu/AccountUserActionMenu";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";
import { useUsersStore } from "../../contexts/UsersStoreContext";

interface Props {
  isExpanded: boolean;
}

const AccountUserController = (props: Props): React.ReactElement => {
  const { isExpanded } = props;

  const { user } = useUserAppConfigStore();
  const usersStore = useUsersStore();

  const userModel = user?.userId ? usersStore.getUser(user?.userId) : null;

  const renderUserDetails = (): React.ReactElement => (
    <>
      {userModel?.profilePic ? (
        <img
          src={userModel.profilePic}
          alt={userModel.name}
          width={40}
          height={40}
          className="bg-cover rounded-full"
        />
      ) : (
        <IoPersonCircle size={40} />
      )}

      <div className="flex-1 min-w-0">
        <p
          className={cn("font-semibold text-sm truncate", "text-gray-700")}
          title={userModel?.name}
        >
          {userModel?.name}
        </p>
        <p
          className={cn("text-sm truncate", "text-gray-600")}
          title={userModel?.emailId}
        >
          {userModel?.emailId}
        </p>
      </div>
    </>
  );

  return (
    <div className={cn("flex gap-3 p-6 border-t", "border-gray-300")}>
      {isExpanded ? renderUserDetails() : null}

      <AccountUserActionMenu />
    </div>
  );
};

export default observer(AccountUserController);
