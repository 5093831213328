import {
  Button,
  ButtonGroup,
  Content,
  DatePicker,
  Dialog,
  DialogContainer,
  Divider,
  Heading,
  Text,
  TextField,
} from "@adobe/react-spectrum";
import { CalendarDate } from "@internationalized/date";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { parseDate } from "@internationalized/date";

import { useUpdateTicketStatus } from "../../apis/UpdateTicketStatus/useUpdateTicketStatus";
import { Ticket, TicketStatus, TicketType } from "../../graphql/graphqlTypes";
import LaptopsSearchController from "../../controllers/LaptopsSearchController/LaptopsSearchController";

interface Props {
  ticket: Ticket;
  isOpen: boolean;
  onClose: () => void;
  updateRefreshId: () => void;
}

const UpdateTicketStatusModal = (props: Props): React.ReactElement => {
  const { ticket, isOpen, onClose, updateRefreshId } = props;

  const [replacementDate, setReplacementDate] = useState<CalendarDate | null>(
    () => {
      const returnDate = ticket.laptop?.rentedDetails?.returnDate;
      return returnDate ? parseDate(returnDate) : null;
    }
  );
  const [replacementLaptopId, setReplacementLaptopId] = useState<string | null>(null);
  const [returnDate, setReturnDate] = useState<CalendarDate | null>(null);
  const [technicalPerson, setTechnicalPerson] = useState<string | null>(null);

  const updateTicketStatusAPI = useUpdateTicketStatus();

  const isAlreadyReplaced = !!ticket.newLaptop?.laptopId
  const isReplacement = !isAlreadyReplaced && ticket.ticketType === TicketType.Replacement;

  const areDetailsFilled = isAlreadyReplaced ? !!returnDate : isReplacement
    ? !!replacementDate && !!returnDate && replacementLaptopId
    : true;

  const updateTicketStatus = (confirm: boolean): void => {
    updateTicketStatusAPI.triggerAPI({
      reqObj: {
        status: confirm
          ? TicketStatus.Resolved
          : TicketStatus.Discarded,
        ticketId: ticket.ticketId,
        replacementDetails: isReplacement
          ? {
              replacementDate: replacementDate?.toString(),
              laptopId: replacementLaptopId ?? "",
              oldLaptopReturnDate: returnDate?.toString(),
              technicalPerson,
            }
          : undefined,
        returnDate: isAlreadyReplaced ? returnDate?.toString() :  isReplacement ? undefined : replacementDate?.toString(),
      },
      onSuccess: updateRefreshId,
    });
  };

  const renderDeliveryDate = (): React.ReactElement => {
    switch (ticket.ticketType) {
      case TicketType.PickUp:
        return (
          <DatePicker
            label="Pick Up Date"
            value={replacementDate}
            onChange={setReplacementDate}
            description="Enter the date on which the laptop is picked up"
          />
        );
      case TicketType.Replacement:
        return (
          <div className="flex flex-col gap-2">
            { isAlreadyReplaced ?  null :
              <>
            <LaptopsSearchController
              laptopId={replacementLaptopId ?? ""}
              onChangeLaptopId={setReplacementLaptopId}
              containerClassName="!w-full"
              isRequired
            />
            <DatePicker
              label="Replacement Date"
              value={replacementDate}
              onChange={setReplacementDate}
              isRequired
            />
              </>
            }
            <DatePicker
              label="Return Date of old Laptop"
              value={returnDate}
              onChange={setReturnDate}
            />
              { isAlreadyReplaced ?  null :
            <TextField
              label="Technical Person"
              value={technicalPerson ?? ""}
              onChange={setTechnicalPerson}
              width={"100%"}
            />}
          </div>
        );
      default:
        return <Text>Has the service been completed?</Text>;
    }
  };

  return (
    <DialogContainer onDismiss={() => onClose()}>
      {isOpen && (
        <Dialog size="M">
          <Heading>Update Ticket Status</Heading>
          <Divider />
          <Content>{renderDeliveryDate()}</Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={onClose}>
              Cancel
            </Button>
            <Button
              variant="negative"
              onPress={() => updateTicketStatus(false)}
            >
              Discard Ticket
            </Button>
            <Button variant="accent" onPress={() => updateTicketStatus(true)}>
              {areDetailsFilled ? "Resolve" : "Update"}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogContainer>
  );
};

export default observer(UpdateTicketStatusModal);
