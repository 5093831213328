import { ComboBox, Item } from "@adobe/react-spectrum";
import { observer } from "mobx-react-lite";
import { useAsyncList } from "react-stately";

import { useGetAccountLaptops } from "../../apis/GetAccountLaptops/useGetAccountLaptops";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { Laptop, LaptopStatus } from "../../graphql/graphqlTypes";
import { useState } from "react";

interface Props {
  laptopId: string;
  onChangeLaptopId: (id: string) => void;
  containerClassName?: string;
  isRequired?: boolean;
}

const LaptopsSearchController = (props: Props): React.ReactElement => {
  const getLaptopsAPI = useGetAccountLaptops();

  const [filterText, setFilterText] = useState("");

  const getLaptops = (offset: number, searchQuery?: string) =>
    getLaptopsAPI.triggerAPI({
      reqObj: {
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset,
        },
        searchQuery,
        status: LaptopStatus.Available,
      },
    });

  let list = useAsyncList<Laptop>({
    async load({ cursor, filterText }) {
      let response = await getLaptops(Number(cursor) || 0, filterText);
      const UpdatedCursor = response.totalCount
        ? Number(cursor) + DEFAULT_API_LIMIT
        : undefined;
      const shouldStopFetching = response.laptops.length < DEFAULT_API_LIMIT;
      return {
        items: response.laptops,
        cursor: shouldStopFetching ? undefined : UpdatedCursor?.toString(),
      };
    },
  });

  return (
    <ComboBox
      label="Select Laptop to replace with"
      items={list.items}
      inputValue={filterText}
      onInputChange={setFilterText}
      loadingState={list.loadingState}
      onLoadMore={list.loadMore}
      selectedKey={props.laptopId}
      onSelectionChange={(key) => {
        const selectedItem = list.items.find((item) => item.laptopId === key);
        setFilterText(
          `${selectedItem?.serialNumber} - ${selectedItem?.modelNumber}`
        );
        props.onChangeLaptopId(key as string);
      }}
      UNSAFE_className={props.containerClassName}
      isRequired={props.isRequired}
    >
      {(item) => (
        <Item
          key={item.laptopId}
        >{`${item.serialNumber} - ${item.modelNumber}`}</Item>
      )}
    </ComboBox>
  );
};

export default observer(LaptopsSearchController);
