import { observer } from "mobx-react-lite";
import { ComponentProps } from "react";
import { MdBusinessCenter, MdOutlineLaptopMac } from "react-icons/md";

import { colors } from "../../constants/colors";
import { useCompaniesStore } from "../../contexts/CompaniesStoreContext";
import { useDashboardStore } from "../../contexts/DashboardStoreContext";
import { useUserAppConfigStore } from "../../contexts/UserAppConfigStoreContext";

import { HiOutlineTicket } from "react-icons/hi2";
import StatItem from "../StatItem/StatItem";

const CompanyDashboard = (): React.ReactElement => {
  const dashboardStore = useDashboardStore();
  const companiesStore = useCompaniesStore();

  const { companyId } = useUserAppConfigStore();

  const statItems: ComponentProps<typeof StatItem>[] = [
    {
      label: "Rented Laptops",
      value: dashboardStore.rentedLaptops,
      icon: <MdOutlineLaptopMac size={32} fill={colors["green-600"]} />,
    },
    {
      label: "Tickets Raised",
      value: dashboardStore.totalTickets,
      icon: <HiOutlineTicket size={32} fill={colors["blue-600"]} />,
    },
    {
      label: "Tickets Pending",
      value: dashboardStore.pendingTickets,
      icon: <HiOutlineTicket size={32} stroke={colors["error-600"]} />,
    },
  ];

  const renderClientHeader = (): React.ReactElement => {
    if (!companyId || !companiesStore.hasCompany(companyId)) return <></>;

    const company = companiesStore.getCompany(companyId);

    return (
      <div className="flex items-center gap-2 bg-white shadow m-4 p-4 rounded-xl">
        {company.logoUrl ? (
          <img
            src={company.logoUrl ?? ""}
            alt={company.name}
            className="border rounded-lg w-[60px] h-[60px]"
          />
        ) : (
          <MdBusinessCenter size={60} fill={colors["gray-600"]} />
        )}
        <div className="flex flex-1 justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-base text-gray-800">{company.name}</p>
            {company.address ? (
              <p className="text-gray-500 text-sm">{company.address}</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderClientHeader()}

      <div className="flex gap-4 p-4">
        {statItems.map((statItem) => (
          <StatItem key={statItem.label} {...statItem} />
        ))}
      </div>
    </>
  );
};

export default observer(CompanyDashboard);
