import { Content, Heading, IllustratedMessage } from "@adobe/react-spectrum";
import NoSearchResults from "@spectrum-icons/illustrations/NoSearchResults";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { useGetCompaniesInAccount } from "../../apis/GetCompaniesInAccount/useGetCompaniesInAccount";
import CompaniesList from "../../components/CompaniesList/CompaniesList";
import CreateCompanyModal from "../../components/CreateCompanyModal/CreateCompanyModal";
import DefaultPageBodyLayout from "../../components/DefaultPageBodyLayout/DefaultPageBodyLayout";
import Loader from "../../components/Loader/Loader";
import { DEFAULT_API_LIMIT } from "../../constants/commonConstants";
import { useCompaniesStore } from "../../contexts/CompaniesStoreContext";

const CompaniesController = (): React.ReactElement => {
  const getCompaniesInAccountAPI = useGetCompaniesInAccount();

  const companiesStore = useCompaniesStore();

  const getCompaniesInAccount = (): void => {
    getCompaniesInAccountAPI.triggerAPI({
      reqObj: {
        pagination: {
          limit: DEFAULT_API_LIMIT,
          offset: 0,
        },
      },
    });
  };

  useEffect(() => {
    getCompaniesInAccount();
  }, []);

  const renderHeader = (): React.ReactElement => (
    <div className="flex items-center w-full">
      <p className={cn("flex-1 font-bold text-xl", "text-gray-900")}>
        Companies
      </p>
      <CreateCompanyModal />
    </div>
  );

  const renderNoDataView = (): React.ReactElement =>
    getCompaniesInAccountAPI.isLoading ? (
      <Loader />
    ) : (
      <div className="bg-gray-50 w-full h-full">
        <IllustratedMessage>
          <NoSearchResults />
          <Heading>No Companies Found</Heading>
          <Content>
            No companies are added yet. You can create a new one.
          </Content>
        </IllustratedMessage>
      </div>
    );

  return (
    <DefaultPageBodyLayout>
      {renderHeader()}
      {companiesStore.companies.length ? (
        <CompaniesList companies={companiesStore.companies} />
      ) : (
        renderNoDataView()
      )}
    </DefaultPageBodyLayout>
  );
};

export default observer(CompaniesController);
