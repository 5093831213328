import { useUsersStore } from "../../contexts/UsersStoreContext";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { CreateAdminUserMutation as Mutation } from "./mutation.generatedTypes";

interface ArgsType {
  data: Mutation;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const usersStore = useUsersStore();

  const handleResponse = (args: ArgsType) => {
    const response = args.data.createAdminUser;

    switch (response.__typename) {
      case "User": {
        usersStore.createOrUpdateUser(response);
        args.onSuccess?.();
        break;
      }
      case "InvalidEmail":
      case "InvalidPhoneNumber":
      case "MaxAdminUsersLimitExceeded":
      case "UserIsNotAdmin":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
