import { useLazyQuery } from "@apollo/client";

import { convertApolloError } from "../../utils/apiUtils";

import {
  GetCompanyQuery as Query,
  GetCompanyDocument as QueryDocument,
  GetCompanyQueryVariables as QueryVariables,
} from "./query.generatedTypes";
import { useResponseHandler } from "./responseHandler";

interface TriggerAPIArgs {
  reqObj: QueryVariables["params"];
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  triggerAPI: (args: TriggerAPIArgs) => void;
  apiError: Error | null;
  isLoading: boolean;
}

export const useGetCompany = (): ReturnType => {
  const [getCompany, { loading, error }] = useLazyQuery<Query, QueryVariables>(
    QueryDocument
  );

  const { handleResponse } = useResponseHandler();

  const triggerAPI = (args: TriggerAPIArgs): void => {
    getCompany({
      variables: {
        params: args.reqObj,
      },
      onCompleted(data) {
        handleResponse({
          data,
          onSuccess: args.onSuccess,
          onFailure: args.onFailure,
        });
      },
      onError: args.onFailure,
      fetchPolicy: "network-only",
    });
  };

  const apiError = convertApolloError(error);

  return { triggerAPI, apiError, isLoading: loading };
};
