import { useDashboardStore } from "../../contexts/DashboardStoreContext";
import { getErrorMessageFromErrorType } from "../../utils/apiUtils";
import { showFailureToast } from "../../utils/toastUtils";

import { GetCompanyDashboardDataQuery as Query } from "./query.generatedTypes";

interface ArgsType {
  data: Query;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface ReturnType {
  handleResponse: (args: ArgsType) => void;
}

export const useResponseHandler = (): ReturnType => {
  const dashboardStore = useDashboardStore();

  const handleResponse = (args: ArgsType) => {
    const response = args.data.getCompanyDashboardData;

    switch (response.__typename) {
      case "GetCompanyDashboardDataResult": {
        dashboardStore.setRentedLaptops(response.totalRentedLaptops);
        dashboardStore.setTotalTickets(response.ticketsRaised);
        dashboardStore.setPendingTickets(response.ticketsPending);
        args.onSuccess?.();
        break;
      }
      case "NotCompanyManager":
      default: {
        args.onFailure?.();

        const errorType = response.__typename;
        const errorMessage = getErrorMessageFromErrorType(errorType);
        showFailureToast(errorMessage);
      }
    }
  };

  return {
    handleResponse,
  };
};
